import { supabase } from "../js/supabase";
import { tick } from 'svelte';
import { getUserData, userDataStore } from '../stores/userInfo';

// Get the latest profile preference and options from back end
export async function getPreferences({
    allQuestions = false,
    onboardingQuestions = false,
    mealPlannerQuestions = false
} = {}) {
    let data, error;

    // Start building the query
    let query = supabase
        .from('preferences_questions')
        .select('id, type, selection, text, preferences_options(id, order, text)')
        .order('order', { ascending: true })

    // Modify the query based on the condition
    if (!allQuestions) {
        query = query.match({
            onboarding: onboardingQuestions,
            meal_planning_wizard: mealPlannerQuestions
        });
    }

    // Execute the query
    ({ data, error } = await query);

    if (error) {
        console.error(error);
        throw new Error();
    }

    return data;
}


// Get preferences that the user has set if applicable
export async function getUserPreferences(){
    // TODO: Leverage the established existing User store instead of using another Supabase call. 
    // User won't be able to get here without being logged in, and profile_preferences will have RLS to prevent unauthorized inserts/modifications
    let currentUserData = await getUserData();

    const {data, error } = await supabase.from('profile_preferences').select().eq('profile_id', currentUserData.id);

    if (error) {
        console.error(error);
        throw new Error();
    }

    return data;
}

// TODO: Deprecate this function

export async function savePreferences(preferences){
    // TODO: Leverage the established existing User store instead of using another Supabase call. 
    // User won't be able to get here without being logged in, and profile_preferences will have RLS to prevent unauthorized inserts/modifications
    const { data: { user } } = await supabase.auth.getUser();

    // Delete all previous saved preferences for a cleaner but heavier insert operation
    // TODO: Enhance this section to safely update existing preferences instead of fully deleting. If errors occur in the below code then the user preferences would be lost in the DB
    // await supabase.from('profile_preferences').delete().eq('profile_id', user.id);

    for (const preference of preferences) {

        if (preference.type === 'multiple_choices') {
            let selectedOptions = [];
            if (preference.selection === 'single') {
                selectedOptions = preference.preferences_options.filter(option => option.id === preference.selectedOption);
            }
            else {
                selectedOptions = preference.preferences_options.filter(option => option.selected);
            }

            if(selectedOptions.length > 0){
                // There are selected options for this preference, we can delete the previously added selections
                await supabase.from('profile_preferences')
                .delete()
                .eq('preferences_question_id', preference.id);
            }

            for (const selectedOption of selectedOptions) {
                await supabase.from('profile_preferences')
                .insert({
                    'profile_id': user.id,
                    'preferences_question_id': preference.id,
                    'preferences_option_id': selectedOption.id
                })
            }
        }
        else if (preference.type === 'short_answer' && !!preference.answer) {
            await supabase.from('profile_preferences')
            .insert({
                'profile_id': user.id,
                'preferences_question_id': preference.id,
                'preferences_answer': preference.answer
            });
        }
    }
}

export async function handlePreferencesChange({ preferenceType, preferenceSelection, preferenceId, preferenceValue, selectedOption }) {
    await tick(); // Wait for the DOM update and propagate all selected values

    const userData = await getUserData();
    const profile_id = userData.id;

    await deleteUserPreference(profile_id, preferenceId);

    switch (preferenceType) {
      case 'multiple_choices':
        switch (preferenceSelection) {
          case 'single':
            await insertUserPreference({
              'profile_id': profile_id,
              'preferences_question_id': preferenceId,
              'preferences_option_id': preferenceValue.selectedOption
            });
            break;
          case 'multiple':
            let selectedOptions = preferenceValue.filter(item => item.selected);
            const noneSelected = selectedOptions.filter(item => item.text.toLowerCase() === 'none').length > 0;
            // If none is one of the options check if it's the just selected option or a previously selected options
            if (noneSelected) {
                if(selectedOption.text.toLowerCase() === 'none') {
                    // if none was just selected deselect all others
                    preferenceValue.forEach( (item) => {
                        if (item.text.toLowerCase() !== 'none') {
                            item.checked = false;
                            item.selected = false;
                        }
                    })
                } else {
                    preferenceValue.forEach( (item) => {
                         // if none wasn't just selected deselect none
                        if (item.text.toLowerCase() === 'none') {
                            item.checked = false;
                            item.selected = false;
                        }
                    })
                }
            }
            selectedOptions = preferenceValue.filter(item => item.selected);
            const insertData = selectedOptions.map(option => ({
              'profile_id': profile_id,
              'preferences_question_id': preferenceId,
              'preferences_option_id': option.id
            }));
            await insertUserPreference(insertData); // Assuming batch insert is supported
            break;
          default:
            throw new Error(`Preference selection ${preferenceSelection} is not handled in handlePreferencesChange`);
        }
        break;
      case 'short_answer':
        await insertUserPreference({
          'profile_id': profile_id,
          'preferences_question_id': preferenceId,
          'preferences_answer': preferenceValue
        });
        break;
      default:
        throw new Error(`Preference type ${preferenceType} is not handled in handlePreferencesChange`);
    }

    return preferenceValue;
  }

export async function deleteUserPreference(profile_id, preferences_question_id) {
    await supabase.from('profile_preferences')
        .delete()
        .match({ profile_id, preferences_question_id });
}

export async function insertUserPreference(data) {
    await supabase.from('profile_preferences').insert(data);
}