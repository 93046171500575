<Sheet backdrop bottom swipeToClose class="new-invitation" style="
    height: auto; 
    border-radius: 16px 16px 0 0;
">
    <div class="action-sheet-container">
        <div class="sheet-close-button">
            <Link sheetClose style="">
                <i class="fa fa-xmark navbar-icon"></i>
            </Link>
        </div>
        <Block style="margin-bottom: 0;">
            {#if $newInvitation && $newInvitation.sender}
                <List dividersIos>
                    <ListItem>
                        <div class="invitation-container">
                            <div class="invitation-container-profile">
                                <p class="invitation-profile-icon-name">{$newInvitation.sender['first_name'][0] ? $newInvitation.sender['first_name'][0].toUpperCase() : ''}</p>
                            </div>
                            <div class="invitation-profile-name">{`${$newInvitation.sender['first_name']} ${$newInvitation.sender['last_name']}`}</div>
                            <div class="invitation-profile-subtitle">{`has invited you to join their meal plan "${$newInvitation.plan['name']}"`}</div>
                        </div>
                    </ListItem>
                    <ListItem>                            
                        <div class="invitation-container">
                            <div class="invitation-profile-footer">As a collaborator, you will be able to view and edit a shared meal plan and grocery list</div>
                        </div>
                    </ListItem>
                </List>
            {/if}
        </Block>
        <Block>
            <Button large fill style="margin-bottom: 20px" on:click={onClickAcceptInvitation}>Accept</Button>
            <div style="text-align: center; margin-top: 20px;">
                <Link on:click={onClickDeclineInvitation}>Decline</Link>
            </div>
        </Block>
    </div>
</Sheet>
<script>
    import { Block, Button, Link, List, ListItem, Sheet, f7 } from "framework7-svelte";
    import '../css/new-invitation-popup.css';
    import { addMemberToPlan, currentMealPlanStartDate, getPlansForUser, memberPlans, newInvitation, plan, plans, updateInvitation } from "../stores/mealPlan";
    import { getUserData } from "../stores/userInfo";
        
    async function onClickAcceptInvitation(){
        await updateInvitation($newInvitation.id, 'ACCEPTED');
        const userData = await getUserData();
        await addMemberToPlan(userData.id, $newInvitation.plan_id, 'MEMBER');
        closePopup();
    }

    async function onClickDeclineInvitation(){
        await updateInvitation($newInvitation.id, 'DECLINED');
        closePopup();
    }

    function closePopup(){
        let currentStartDate;
        currentMealPlanStartDate.subscribe(value=>{
            currentStartDate = value;
        })();
        getPlansForUser(currentStartDate)
        .then(response => {
            const { plans: userPlans, plan: userPlan, plansAlreadyMember} = response;
            plans.set(userPlans);
            memberPlans.set(plansAlreadyMember);
            
            let currentUserPlan;
            plan.subscribe(value => {
                currentUserPlan = value;
            });

            if (currentUserPlan) {
                const updatedPlan = userPlans.find(userPlan => userPlan.id === currentUserPlan.id);

                if (updatedPlan !== undefined) {
                plan.set(updatedPlan);
                }
            }
            else {
                plan.set(userPlan);
            }

            f7.popup.close('.new-invitation');
        })
        
    }
</script>