<Popup class="onboarding-popup">
    <Page>
        <!-- 
        <Navbar title="Zestyplan">
            <NavRight>
                <Link popupClose>Skip</Link>
            </NavRight>
        </Navbar>
        -->
      <swiper-container 
        id="onboarding-popup-swiper"
        pagination={true} 
        class="onboarding-popup-swiper" 
        space-between={50}
        on:swiperslidechange={onSlideChange}
      >
        <swiper-slide>
          <div class="onboarding-swiper-slide">
            <div style="font-size: 42px; margin-bottom: 20px">
              <!-- svelte-ignore a11y-missing-attribute -->
              <img src="../images/ZestyPlan_Logo.png" style="width: 250px">
            </div>
            <div class="onboarding-modal-title">
              Welcome to Zestyplan!
            </div>
            <div class="onboarding-modal-text">
              The AI powered meal planning platform that helps you save money, reduce food waste and make climate-friendly meals.
            </div>
          </div>
          <Block style="position: absolute; width: 100%; bottom: 5%;">
            <div style="text-align: -webkit-center;">
              <Button style="max-width: 200px;" large round fill on:click={() => (f7.swiper.get('.onboarding-popup-swiper').slideNext())}>Continue</Button>
              <!--<Button style="max-width: 200px;" large round fill on:click={() => {onboardingComplete()}}>Continue</Button>-->
            </div>
          </Block>
        </swiper-slide>
        <swiper-slide>
          <div class="onboarding-swiper-slide">
            <div style="font-size: 42px; margin-bottom: 20px">
              <!-- svelte-ignore a11y-missing-attribute -->
              <img src="../images/undraw_flowers_vx06.svg" style="width: 250px;"/>
            </div>
            <div class="onboarding-modal-title">
              Grocery Genius
            </div>
            <div class="onboarding-modal-text">
              With Zestyplan, make every item count. Shop sustainably, cut waste, and love your planet with every purchase.
            </div>
          </div>
          <Block style="position: absolute; width: 100%; bottom: 5%;">
            <div style="text-align: -webkit-center;">
              <Button style="max-width: 200px;" large round fill on:click={() => (f7.swiper.get('.onboarding-popup-swiper').slideNext())}>Continue</Button>
              <!--<Button style="max-width: 200px;" large round fill on:click={() => {onboardingComplete()}}>Continue</Button>-->
            </div>
          </Block>
        </swiper-slide>
        <swiper-slide>
          <div class="onboarding-swiper-slide">
            <div style="font-size: 42px; margin-bottom: 20px">
              <!-- svelte-ignore a11y-missing-attribute -->
              <img src="../images/undraw_booking_re_gw4j.svg" style="width: 250px;"/>
            </div>
            <div class="onboarding-modal-title">
              Recipes for Change
            </div>
            <div class="onboarding-modal-text">
              Zestyplan serves up delicious, planet-friendly recipes that make a difference. Enjoy meals that matter.
            </div>
          </div>
          <Block style="position: absolute; width: 100%; bottom: 5%;">
            <div style="text-align: -webkit-center;">
              <Button style="max-width: 200px;" large round fill on:click={() => {onboardingComplete()}}>Let's get started!</Button>
            </div>
          </Block>
        </swiper-slide>
      </swiper-container>     
    </Page>
</Popup>

<script>
    import { onMount, onDestroy } from 'svelte';
    import {
    f7,
    Popup,
    Page,
    Navbar,
    NavRight,
    Link,
    Block,
    Button,
    List,
    ListInput
  } from 'framework7-svelte';
  import { initUser, updateUserName } from '../js/supabase';
  import { getUserData, userDataStore, getUserFirstLastName } from '../stores/userInfo'


  let swiperEl, swiperEl2

  onMount(() => {
    // Do something on mount

  });

  onDestroy(async () => {
    localStorage.setItem('onboardingComplete', 'true')
  })

  async function onboardingComplete(){
    f7.popup.close('.onboarding-popup')

    // ensure that the user can be initialized
    f7.preloader.show();
    await initUser()
    f7.preloader.hide();
  }

  const onSlideChange = (e) => {
    const [swiper] = e.detail;
    // Do something on slide change here
  }
</script>
<style>
  .onboarding-popup-swiper {
    height: 100%;
  }

  .onboarding-popup-swiper swiper-slide {
    display: flex;
  }

  .onboarding-swiper-slide {
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 30%;
    padding: 20px;
  }

  .onboarding-modal-text {
    text-align: center;
  }
</style>