import { supabase } from "../js/supabase";

/**
 * Swaps the name of a recipe ingredient with a new name.
 * 
 * @param {Object} options - The options for swapping the ingredient.
 * @param {string} options.ingredientId - The ID of the ingredient.
 * @param {string} options.newIngredientName - The new name for the ingredient.
 * @returns {Promise<Object>} - The updated ingredient object.
 * @throws {Error} - If any required parameters are missing, if the ingredient is not found, or if there is an error updating the ingredient.
 */
export async function swapRecipeIngredient({
    ingredientId,
    newIngredientName
} = {}) {
    // Check that all required parameters are provided, throw an errof it not
    if (!ingredientId || !newIngredientName) {
        throw new Error("swapRecipeIngredient: Missing required parameters");
    }

    // Get the ingredient object from the recipe_ingredients table in supabase
    const ingredient = await supabase
        .from("recipe_ingredients")
        .select("*")
        .eq("id", ingredientId)
        .single();

    // Check if the ingredient was found, throw an error if not
    if (!ingredient && !ingredient.data) {
        throw new Error("swapRecipeIngredient: Ingredient not found");
    }

    // Update the ingredient name and save it back to the database
    ingredient.data.name = newIngredientName;

    const { data, error } = await supabase
        .from("recipe_ingredients")
        .update(ingredient.data)
        .eq("id", ingredientId)
        .select();

    // Check for an error and throw it if found
    if (error) {
        throw new Error("swapRecipeIngredient: Error updating ingredient");
    }

    // Return the updated ingredient
    return data;

}