<List strong accordionList>
    {#each preferences as preference, preferenceIndex}
    <div class="preferences-accordion">
      <ListItem accordionItem title={preference.text}>
        <AccordionContent>
          {#if preference.type === "short_answer"}
            <ListInput 
              bind:value={preferences[preferenceIndex].answer} 
              placeholder="Your answer here"
              on:change={() => {
                handlePreferencesChange({
                  preferenceType : preference.type,
                  preferenceId : preference.id,
                  preferenceValue : preferences[preferenceIndex].answer
                })
              }}
            />
          {:else}
            <List strongIos outlineIos dividersIos>
              {#if preference.selection === "single"}
                {#each preference.preferences_options as option, optionIndex}
                  <ListItem
                    radio
                    value={option.id}
                    title={option.text}
                    checked={preferences[preferenceIndex].selectedOption === option.id}
                    on:change={() => {
                      preferences[preferenceIndex].selectedOption = option.id;
                      handlePreferencesChange({
                        preferenceType : preference.type,
                        preferenceSelection : preference.selection,
                        preferenceId : preference.id,
                        preferenceValue : preferences[preferenceIndex]
                      });
                    }}>
                  </ListItem>
                {/each}
              {:else}
                {#each preference.preferences_options as option, optionIndex}
                  <ListItem
                    checkbox
                    value={option.text}
                    title={option.text}
                    bind:checked={preferences[preferenceIndex].preferences_options[optionIndex].selected}
                    on:change={async () => {
                      preferences[preferenceIndex].preferences_options = await handlePreferencesChange({
                        preferenceType : preference.type,
                        preferenceSelection : preference.selection,
                        preferenceId : preference.id,
                        preferenceValue : preferences[preferenceIndex].preferences_options,
                        selectedOption : preferences[preferenceIndex].preferences_options[optionIndex]
                      });
                    }}
                  ></ListItem>
                {/each}
              {/if}
            </List>
          {/if}
        </AccordionContent>
        {#if preference.type === "short_answer"}
            <div class="preference-answer">
                Answer: {preferences[preferenceIndex].answer || 'None'}
            </div>
        {:else}
            {#if preference.selection === "single"}
                {#each preference.preferences_options as option, optionIndex}
                    {#if preferences[preferenceIndex].selectedOption === option.id}
                        <div class="preference-answer">
                            Answer: {option.text || 'None'}
                        </div>
                    {/if}
                {/each}
            {:else}
                {#each preference.preferences_options as option, optionIndex}
                    {#if preferences[preferenceIndex].preferences_options[optionIndex].selected}
                        <div class="preference-answer">
                            Answer: {preferences[preferenceIndex].preferences_options[optionIndex].text || 'None'}
                        </div>
                    {/if}
                {/each}
            {/if}
        {/if}
      </ListItem>
    </div>
    {/each}
</List>
<script>
    import { Page, Navbar, Block, ListInput, Button, List, ListItem, AccordionContent } from 'framework7-svelte';
    import { getPreferences, getUserPreferences, handlePreferencesChange, insertUserPreference, deleteUserPreference } from '../stores/preferences';
    import { showToast } from "../js/generalHelpers"
    import { onMount } from 'svelte';

    onMount(() => {
        fetchPreferences();
    });

    // TODO: Separate out the available preferences from the server and a separate var for what the user has selected in the UI
    // TODO: Consolidate the user's selected options in the save call. Seperate var allows us to more accurately send up live updates on change to DB
    let preferences = []; // A maintained list of preferences and the user's selected options
    let userPreferences = []; // The user's selected preferences from the server

    async function fetchPreferences() {
        preferences = await getPreferences({
          onboardingQuestions : true
        });
        userPreferences = await getUserPreferences();

        for (const preference of preferences) {
        const preferenceIndex = preferences.findIndex(p => p.id === preference.id);
        const userPreferencesForPreference = userPreferences.filter(up => up.preferences_question_id === preference.id);

        if (preference.type === 'multiple_choices') {
            if (preference.selection === 'single') {

            if (userPreferencesForPreference && userPreferencesForPreference.length > 0) {
                preferences[preferenceIndex].selectedOption = userPreferencesForPreference[0].preferences_option_id;
            }
            }
            else if (preference.selection === 'multiple') {

            for (const userPreferenceForPreference of userPreferencesForPreference) {
                const preferenceOptionIndex = preferences[preferenceIndex].preferences_options.findIndex(po => po.id === userPreferenceForPreference.preferences_option_id);
                preferences[preferenceIndex].preferences_options[preferenceOptionIndex].selected = true;
            }
            }
        }
        else {
            if (userPreferencesForPreference && userPreferencesForPreference.length > 0) {
            preferences[preferenceIndex].answer = userPreferencesForPreference[0].preferences_answer;
            }
        }
        }
    }
</script>
<style>
    .preferences-accordion {
      margin: 10px;
      border-radius: 8px;
      border-style: solid;
      border-color: #cecece;
    }
    
    :global(.preferences-accordion ul) {
      background: transparent !important;
    }

    .preference-answer {
        transition: all 0.3s ease;
        pointer-events: none;
        padding: 0px 15px 10px 15px;
        color: #888888;
    }

    :global(.accordion-item-opened .preference-answer) {
        opacity: 0;
        margin-top: -20px;
    }
</style>