<script>
    import { onMount, onDestroy } from 'svelte';
    import {
        f7,
        BlockTitle,
        Sheet,
        Chip,
        Messages,
        Message,
        MessagesTitle,
    } from 'framework7-svelte';
    import {mealPlanStore, updateMealPlan} from "../stores/mealPlan"

    onMount(() => {
    });
  
    export const beforeDestroy = () => {
    };

    /* ZestyGPT Start */
    function onInputFocus() {
        // Code to run when the keyboard is displayed
        //f7.sheet.stepToggle('.demo-sheet-swipe-to-step');
        f7.$('#appToolbar').hide(true)
        bottomPadding = f7.$('.fixed-bottom').css('bottom')
        f7.$('.fixed-bottom').css('bottom', '0')
        setTimeout(function(){
        f7.sheet.open('.demo-sheet-swipe-to-step')
        }, 500)
    }

    function onInputBlur() {
        // Code to run when the keyboard is hidden
        f7.$('#appToolbar').show(true)
        f7.$('.fixed-bottom').css('bottom', bottomPadding)
        f7.sheet.close('.demo-sheet-swipe-to-step')
    }

    // Watch for changes to showSendButton and animate the send button in from the right
    $: if (showSendButton) {
        const sendButton = f7.$('.send-button');
        sendButton.addClass('animate-in');
    }
  
    let gptChat = []

    function showChatSheet(){
        f7.sheet.open('.gpt-chat-interface')
    }

    function isFirstMessage(message, index) {
        const previousMessage = gptChat[index - 1];
        if (message.isTitle) return false;
        if (
        !previousMessage ||
        previousMessage.type !== message.type ||
        previousMessage.name !== message.name
        )
        return true;
        return false;
    }
    function isLastMessage(message, index) {
        const nextMessage = gptChat[index + 1];
        if (message.isTitle) return false;
        if (!nextMessage || nextMessage.type !== message.type || nextMessage.name !== message.name)
        return true;
        return false;
    }
    function isTailMessage(message, index) {
        const nextMessage = gptChat[index + 1];
        if (message.isTitle) return false;
        if (!nextMessage || nextMessage.type !== message.type || nextMessage.name !== message.name)
        return true;
        return false;
    }

    let message = '';
    let showSendButton = false;
    let gptTyping = null;
    let bottomPadding;
    let messageInput;
    let sendButtonTimeout;

    onDestroy(() => {
        // Clean up any event listeners or timeouts when the component is destroyed
        clearTimeout(sendButtonTimeout);
    });

    function handleMessageInput(event) {
        showSendButton = message !== '';

        // resize text area
        messageInput.height = 'auto';
        messageInput.style.height = messageInput.scrollHeight + 'px';
    }

    function handleSendMessage() {
        const thisMessage = message

        // append the new message to the chat window
        gptChat = [
            ...gptChat,
            {
            type: 'sent',
            text: message,
            }
        ]
        showChatSheet()

        

        // Animate the send button in from the right
        sendButtonTimeout = setTimeout(() => {
            showSendButton = false;
        }, 1000);

        setTimeout(async function(){
            gptTyping = {
                name: 'ZestyGPT',
                avatar: 'https://upload.wikimedia.org/wikipedia/commons/5/5b/Avatar_leaf.png'
            }
            
            await modifyMealPlan({
                prompt : thisMessage
            })

            gptChat = [
                ...gptChat,
                {
                name: 'ZestyGPT',
                type: 'received',
                text: 'All done!',
                avatar: 'https://upload.wikimedia.org/wikipedia/commons/5/5b/Avatar_leaf.png',
                }
            ]

            gptTyping = null

        }, 600)

        message = '';
        showSendButton = false;
    }

    function handleSuggestionClip(event) {
        event.preventDefault();

        message += event.target.innerText

        handleMessageInput(event)
    }

    function jsonToCsv(jsonArray) {
        const csvHeaders = Object.keys(jsonArray[0]).join(',') + '\n';
        const csvRows = jsonArray.map(obj => Object.values(obj).join(',')).join('\n');

        return csvHeaders + csvRows;
    }

    async function modifyMealPlan({prompt} = {}){
        let currentMealPlan = [];
        // TODO: Get this meal plan data on the server, don't pass it in through the API
        const storedMealPlan = JSON.parse(localStorage.mealPlanData);

        const dayKeys = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"]

        for(const dayOfTheWeek of dayKeys){
            for(const meal of storedMealPlan[dayOfTheWeek]){
            // push a stripped down version of the meal into the currentMealPlan array to make it easier for ChatGPT to process
            currentMealPlan.push({
                "Day of the week" : meal["Day of the week"],
                "Type of meal" : meal["Type of meal"],
                "Meal Name" : meal[ "Meal Name"],
                "Estimated time to cook" : meal["Estimated time to cook"]
            })
            }
        }

        // Get the CSV formatted meal plan to send back to ChatGPT
        const csvMealPlan = jsonToCsv(currentMealPlan)

        // TODO: Create a modification prompt similar to the initial one to generate it to return back a CSV formatted meal plan with the consideration of the existing meal plan, and the user's previous question context
        let response
        try {
            response = await fetch("https://utsraemlnrkrouxctjnx.functions.supabase.co/zesty-gpt", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization" : "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InV0c3JhZW1sbnJrcm91eGN0am54Iiwicm9sZSI6ImFub24iLCJpYXQiOjE2NzU1NTEyNzYsImV4cCI6MTk5MTEyNzI3Nn0.RfiQTrpq7-rOerAjznGHpdevPIYuSRNJcXqj-anEnG0"
                },
                body: JSON.stringify({
                    "action":"update_meal_plan", 
                    "params" : {
                    "currentMealPlanCSV" : csvMealPlan,
                    "modificationsRequested" : prompt
                    }
                }),
            });
        } catch(e){
            console.log(e)

            alert('Error generating meal plan.')
            return
        }

        if (response.ok) {
            const result = await response.json();

            let newData = {}
            // TODO: Update updateMealPlan function to not take in a day, but to figure it out for itself, maybe use the grouping function
            // TODO: Validate that the returned data conforms to our requirements

            if(result){
            newData['Monday'] = result
            updateMealPlan({data: newData})
            } else {
            alert('No valid recipe items returned.')
            }
        } else {
            alert("Error: " + response.status);
        }
    }
</script>
<style>
    @import '@fortawesome/fontawesome-free/css/all.css';
    :root {
        --f7-message-sent-bg-color: #5CB046;
    }
    .fixed-bottom {
        position: fixed;
        bottom: calc(var(--f7-page-toolbar-bottom-offset, 0px) + var(--f7-safe-area-bottom) + var(--f7-page-content-extra-padding-bottom, 0px));
        left: 0;
        right: 0;
        padding: 10px;
        background-color: var(--f7-page-bg-color);
        border-radius: 16px 16px 0 0;
        box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.5);
        display: flex;
        justify-content: center;
        z-index: 5;
        border-style: solid;
        border-color: var(--f7-block-strong-bg-color);
        border-width: 1px 1px 0px 1px;
    }
    
    input[type="text"], button {
        font-size: 1rem;
        padding: 10px;
        border: none;
        border-radius: 5px;
    }
    
    input[type="text"] {
        flex-grow: 1;
        margin-right: 10px;
    }
    
    button {
        background-color: #5CB046;
        color: #fff;
        cursor: pointer;
    }

    .chat-input-container {
        display: flex;
        align-items: center;
        width: 100%;
    }

    .circle-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 40px;
        width: 40px;
        border-radius: 50%;
        background-color: #eee;
        margin-right: 10px;
    }

    .circle-icon i {
        font-size: 20px;
        color: #888;
    }

    .message-input {
        flex-grow: 1;
        border: none;
        outline: none;
        font-size: 16px;
        padding: 5px 10px;
        margin-right: 10px;
        resize: none;
        overflow: hidden;
        white-space: pre-wrap;
        word-wrap: break-word;
    }

    .send-button {
        border: none;
        background-color: #5CB046;
        color: #fff;
        border-radius: 5px;
        padding: 5px 10px;
        transition: all 0.3s ease;
        max-width: 58px;
    }

    .send-button:hover {
        transform: scale(1.1);
    }

    .send-button i {
        font-size: 20px;
        margin-right: 5px;
    }

    /* Animate the send button in from the right */
    .send-button.animate-in {
        margin-right: 10px;
    }

    .swipe-handler {
        height: 16px;
        position: absolute;
        left: 0;
        width: 100%;
        top: 0;
        background: var(--f7-sheet-bg-color);
        cursor: pointer;
        z-index: 10;
    }

    .swipe-handler:after {
        content: "";
        width: 36px;
        height: 6px;
        position: absolute;
        left: 50%;
        top: 50%;
        margin-left: -18px;
        margin-top: -3px;
        border-radius: 3px;
        background: #666;
    }
</style>


<div class="fixed-bottom">
<div class="panel-backdrop" id="backdropEl"></div>
<div class="chat-input-container">
    <div class="circle-icon" on:mousedown={showChatSheet}>
        <i class="fa fa-comment"></i>
    </div>
    <!-- <input type="text" bind:value={message} on:input={handleMessageInput} placeholder="Type your message here" class="message-input" id="inputBox" wrap="soft"/> -->
    <textarea 
    on:input={handleMessageInput} 
    bind:value={message} 
    bind:this={messageInput}
    on:blur={onInputBlur}
    on:focus={onInputFocus}
    placeholder="Add more protein to my meal plan" 
    class="message-input" 
    id="messageInput" rows="1"
    ></textarea>
    {#if showSendButton}
    <button on:mousedown={handleSendMessage} class="send-button">
        <i class="fa fa-paper-plane"></i>
    </button>
    {/if}
</div>
</div>
<Sheet
    class="demo-sheet-swipe-to-step"
    style="
        height: auto; 
        position: fixed; 
        z-index: 1; 
        border-radius: 16px 16px 0 0;
        box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.5);
        bottom: calc(var(--f7-page-toolbar-bottom-offset, 0px) + var(--f7-page-content-extra-padding-bottom, 0px));
    "
    swipeToClose
    swipeToStep
    containerEl="#home-page .page-content">
    <div class="swipe-handler" />
    <div class="sheet-modal-swipe-step">
        <div class="display-flex padding justify-content-space-between align-items-center">
        <div style="font-size: 18px"><b>Popular actions</b></div>
        </div>
        <div class="padding-horizontal padding-bottom" style="padding-bottom: 20px">
        <div>
            <span on:mousedown={handleSuggestionClip}><Chip text="I'm cooking for [Number]" /></span>
            <span on:mousedown={handleSuggestionClip}><Chip text="Optimize for leftovers" /></span>
            <span on:mousedown={handleSuggestionClip}><Chip text="Add recipe from [URL]" /></span>
            <span on:mousedown={handleSuggestionClip}><Chip text="Lorem here" /></span>
            <div class="margin-top text-align-center">Swipe up for more</div>
        </div>
        </div>
    </div>
    <BlockTitle medium class="margin-top">Top Actions</BlockTitle>
    <div class="padding-horizontal padding-bottom">
        <div style="padding-bottom: 20px;">
        <span on:mousedown={handleSuggestionClip}><Chip text="I'm cooking for [Number]" /></span>
        <span on:mousedown={handleSuggestionClip}><Chip text="Optimize for leftovers" /></span>
        <span on:mousedown={handleSuggestionClip}><Chip text="Re-generate week" /></span>
        <span on:mousedown={handleSuggestionClip}><Chip text="Add [Recipe]" /></span>
        <span on:mousedown={handleSuggestionClip}><Chip text="I'm cooking for [Number]" /></span>
        <span on:mousedown={handleSuggestionClip}><Chip text="Optimize for leftovers" /></span>
        <span on:mousedown={handleSuggestionClip}><Chip text="Re-generate week" /></span>
        <span on:mousedown={handleSuggestionClip}><Chip text="Add [Recipe]" /></span>
        </div>
    </div>
</Sheet>
<Sheet
    class="gpt-chat-interface"
    style=" 
        position: fixed; 
        z-index: 1; 
        border-radius: 16px 16px 0 0;
        padding-bottom: 40px;
        height: auto;
        bottom: calc(var(--f7-page-toolbar-bottom-offset, 0px) + var(--f7-page-content-extra-padding-bottom, 0px) + var(--f7-safe-area-bottom));
        box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.5);
    "
    swipeToClose
    containerEl="#home-page .page-content">
    <div class="swipe-handler" />
    <div class="padding-horizontal padding-bottom padding-top">
        <div style="padding-bottom: 20px;">
        <Messages scrollMessages={false}>
            <MessagesTitle><b>Sunday, Feb 9,</b> 12:58</MessagesTitle>
            {#each gptChat as message, index (index)}
                <Message
                    type={message.type}
                    image={message.image}
                    name={message.name}
                    avatar={message.avatar}
                    first={isFirstMessage(message, index)}
                    last={isLastMessage(message, index)}
                    tail={isTailMessage(message, index)}
                    htmlText={message.text}
                />
            {/each}
            {#if gptTyping}
                <Message
                    type="received"
                    typing={true}
                    first={true}
                    last={true}
                    tail={true}
                    header={`${gptTyping.name} is thinking`}
                    avatar={gptTyping.avatar}
                />
            {/if}
        </Messages>
        </div>
    </div>
</Sheet>
