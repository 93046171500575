import { f7 } from 'framework7-svelte';
import { writable } from 'svelte/store';
import { selectedRecipe } from '../stores/mealPlan';
import { supabase } from "../js/supabase";

const frontendURL = import.meta.env.VITE_FRONTEND_URL;
const supabaseUrl = import.meta.env.VITE_SUPABASE_URL;
const supabaseAnonKey = import.meta.env.VITE_SUPABASE_ANON_KEY;

export const aiRecipeGenerating= writable(false);

export function startGenerateAIRecipe({searchInput, ingredientsList, useStrictIngredients, extraTextData, useUserPreferences, imageContext} = {}){
    aiRecipeGenerating.set({
        searchInput          : searchInput,
        imageContext         : imageContext,
        extraContext         : extraTextData,
        ingredientsList      : ingredientsList,
        useStrictIngredients : useStrictIngredients,
        useUserPreferences   : useUserPreferences
    })

    // Ensure selected recipe is cleared so there is nothing in there to conflict with
    // the new incoming data
    selectedRecipe.set(null);

    // This popup will then trigger generateAIRecipe once rendered
    f7.popup.open('.recipe-editor');
}

function delay(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

export async function generateAIRecipe(input){
    let response;
    try {
        response = await supabase.functions.invoke(`generateRecipe`, {
            body: input
        });

        if(response.error){
            throw new Error(response.error)
        }
    } catch (error) {
        console.log(error);
        throw new Error(error)
    }

    if(response.error){
        throw new Error(response.error)
    }

    const result = response.data

    return result
}

export async function generateAIRecipeImage(input){
    let response;
    try {
        response = await supabase.functions.invoke(`generateImage`, {
            body: input
        });

        if(response.error){
            throw new Error(response.error);
        }
    } catch (error) {
        console.log(error);
        throw new Error(error);
    }

    if(response.error){
        throw new Error(response.error);
    }

    const result = response.data.url;

    return result;
}

export async function generateSearchTermResults(searchTerm){

    const response = await supabase.functions.invoke(`ai-recipe-search-term-suggestions?q=${searchTerm}`, {
        method: "GET",
        headers: {
            'Content-Type': 'application/json',
        }
    });

    if(response.error){
        throw new Error(response.error)
    }

    const result = response.data

    return result.title_suggestions

}
