import { writable } from 'svelte/store';

const localStorageKey = 'featureToggles'

export const featureTogglesStore = writable(JSON.parse(localStorage.getItem(localStorageKey)) || []);

const getCurrentToggles = function(){
    let currentToggles;
    featureTogglesStore.subscribe(value=>{
        currentToggles = value;
    })();

    return currentToggles
}

export const updateToggle = function({toggleName, enabled = true} = {}){
    let currentToggles = getCurrentToggles()

    if(enabled && !currentToggles.includes(toggleName)){
        currentToggles.push(toggleName)

        featureTogglesStore.set(currentToggles)

        localStorage.setItem(localStorageKey, JSON.stringify(currentToggles))
    } else {
        currentToggles = currentToggles.filter(name => name !== toggleName);

        featureTogglesStore.set(currentToggles)

        localStorage.setItem(localStorageKey, JSON.stringify(currentToggles))
    }
}