
import HomePage from '../pages/home.svelte';
import AboutPage from '../pages/about.svelte';
import FormPage from '../pages/form.svelte';
import GroceriesPage from '../pages/groceries.svelte';
import RecipesPage from '../pages/recipes.svelte';
import DiscoverPage from '../pages/discover.svelte';
import ProductPage from '../pages/product.svelte';
import PasswordResetPage from '../pages/password-reset.svelte';
import RecipeDetailsPage from '../pages/recipe-details.svelte';
import ZestyplanAssistantChatWindow from '../components/ZestyplanAssistantChatWindow.svelte';
import ProfilePage from '../pages/profile.svelte';
import DynamicRoutePage from '../pages/dynamic-route.svelte';
import RequestAndLoad from '../pages/request-and-load.svelte';
import NotFoundPage from '../pages/404.svelte';
import OnboardingPopup from '../components/OnboardingPopup.svelte';
import UserPreferenceQuestions from '../components/UserPreferenceQuestions.svelte';
import UserPreference from '../pages/user-preferences.svelte';
import RecipeCollection from '../pages/recipe-collection.svelte';
import MealPlanningWizard from '../components/MealPlanningWizard.svelte';
import UpdateProfileName from '../pages/update-profile-name.svelte';
import UpdateProfileEmail from '../pages/update-profile-email.svelte';
import PlannerDuplicate from '../pages/planner-duplicate.svelte';

var routes = [
  {
    path: '/',
    component: HomePage,
  },
  {
    path: '/about/',
    component: AboutPage,
  },
  {
    path: '/form/',
    component: FormPage,
  },
  {
    path: '/groceries/',
    component: GroceriesPage,
  },
  {
    path: '/planner-duplicate/',
    component: PlannerDuplicate,
  },
  {
    path: '/recipes/',
    component: RecipesPage,
  },
  {
    path: '/discover/',
    component: DiscoverPage,
  },
  {
    path: '/password-reset/',
    component: PasswordResetPage,
  },
  {
    path: '/product/:id/',
    component: ProductPage,
  },
  {
    path: '/recipe/',
    popup : {
      component: RecipeDetailsPage,
    }
  },
  {
    path: '/zestyplan-assistant-chat-window/',
    popup : {
      component: ZestyplanAssistantChatWindow,
    }
  },
  {
    path: '/profile/',
    component: ProfilePage,
  },
  {
    path: '/onboarding/',
    popup : {
      component : OnboardingPopup,
    }
  },
  {
    path: '/update-profile-email/',
    component: UpdateProfileEmail,
  },
  {
    path: '/update-profile-name/',
    component: UpdateProfileName,
  },
  {
    path: '/user-preference-questions/',
    popup : {
      component : UserPreferenceQuestions,
    }
  },
  {
    path: '/user-preferences/',
    component: UserPreference,
  },
  {
    path: '/recipe-collection/:collectionType',
    component: RecipeCollection,
  },
  {
    path: '/meal-planning-wizard/',
    popup : {
      component : MealPlanningWizard,
    }
  },
  {
    path: '/dynamic-route/blog/:blogId/post/:postId/',
    component: DynamicRoutePage,
  },
  {
    path: '/request-and-load/user/:userId/',
    async: function ({ router, to, resolve }) {
      // App instance
      var app = router.app;

      // Show Preloader
      app.preloader.show();

      // User ID from request
      var userId = to.params.userId;

      // Simulate Ajax Request
      setTimeout(function () {
        // We got user data from request
        var user = {
          firstName: 'Vladimir',
          lastName: 'Kharlampidi',
          about: 'Hello, i am creator of Framework7! Hope you like it!',
          links: [
            {
              title: 'Framework7 Website',
              url: 'http://framework7.io',
            },
            {
              title: 'Framework7 Forum',
              url: 'http://forum.framework7.io',
            },
          ]
        };
        // Hide Preloader
        app.preloader.hide();

        // Resolve route to load page
        resolve(
          {
            component: RequestAndLoad,
          },
          {
            props: {
              user: user,
            }
          }
        );
      }, 1000);
    },
  },
  {
    path: '(.*)',
    component: NotFoundPage,
  },
];

export default routes;
