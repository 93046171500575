import { supabase } from "../js/supabase";
import { tick } from 'svelte';
import { getUserData } from '../stores/userInfo';
import { writable } from 'svelte/store';
import { data } from "dom7";

export const userRecipeFavourites = writable();

async function initRecipeFavourites(){
    const {data, error} = await supabase
        .from('recipe_favourites')
        .select()
        .order(
            'created_at',
            {
                ascending: false
            }
        );

    if(error){
        throw new Error('Error getting favourites')
    }

    if(data){
        userRecipeFavourites.set(data)
    }
}


export const getUserFavourites = async function(){
    await initRecipeFavourites();

    let currentUserFavourites;
    userRecipeFavourites.subscribe(value => {
        currentUserFavourites = value;
    });

    return currentUserFavourites;
}

export const addNewFavourite = async function({
    recipeId,
    recipeUri
} = {}){
    if(!recipeId && !recipeUri){
        throw new Error('No Recipe ID or URI provided.')
    }

    let recipeFavouritePayload;
    if(recipeId){
        // Dealing with a user-generated recipe
        recipeFavouritePayload = {
            recipe_id : recipeId
        }
    } else if(recipeUri){
        // Dealing with an edamam recipe
        recipeFavouritePayload = {
            recipe_uri : recipeUri
        }
    }

    const userFavouritesUpdated = await supabase.from('recipe_favourites').insert(recipeFavouritePayload)
    .select();

    if(userFavouritesUpdated.error){
        throw new Error('New favourite insert failed.')
    }

    userRecipeFavourites.set(userFavouritesUpdated.data)
}

export const removeFavourite = async function({
    recipeId,
    recipeUri
} = {}){
    if(!recipeId && !recipeUri){
        throw new Error('No Recipe ID or URI provided.')
    }

    if(recipeId){
        // Dealing with a user-generated recipe
        const {data, error} = await supabase
            .from('recipe_favourites')
            .delete()
            .eq('recipe_id', recipeId)

        if(error){
            throw new Error('Error deleting recipe favourite')
        }
    } else if(recipeUri){
        // Dealing with an edamam recipe
        const {data, error} = await supabase
            .from('recipe_favourites')
            .delete()
            .eq('recipe_uri', recipeUri)

        if(error){
            throw new Error('Error deleting recipe favourite')
        }
    }

    // Refresh favourites data
    await initRecipeFavourites();
}

initRecipeFavourites()
