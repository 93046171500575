<Popup class="user-preference-questions" onPopupOpened={userPreferencesPopupOpened} onPopupClosed={userPreferenceClose}>
    <Page class="user-onboarding-preferences">
        <!-- 
        <Navbar title="Zestyplan">
            <NavRight>
                <Link popupClose>Skip</Link>
            </NavRight>
        </Navbar>
        -->
      <Navbar transparent sliding={false} hidden={true}>
        <NavLeft>
          <Link on:click={() => (f7.swiper.get('.onboarding-popup-swiper').slidePrev())}>
            <i class="fa fa-chevron-left" style="margin-right: 5px;"/> 
            Back
          </Link>
        </NavLeft>
        <NavRight>
          <Link popupClose>
            Skip
          </Link>
        </NavRight>
      </Navbar>
      <swiper-container 
        pagination-enabled={false} 
        allow-touch-move="false" 
        class="onboarding-popup-swiper" 
        space-between={50}
        on:swiperslidechange={onSlideChange}
      >
        <swiper-slide>
          <div class="onboarding-swiper-slide">
            <div style="font-size: 42px; margin-bottom: 20px; text-align: center;">
                <!-- svelte-ignore a11y-missing-attribute -->
                <img src="../images/undraw_environmental_study_re_q4q8.svg" style="width: 75%;"/>
            </div>
            <div class="onboarding-modal-title">
              Let's get personal!
            </div>
            <div class="onboarding-modal-text">
              Answer a few short questions to help Zestyplan craft your perfect meal plan!
            </div>
          </div>
          <Block style="position: absolute; width: 100%; bottom: 5%;">
            <div style="text-align: -webkit-center;">
              <Button 
                style="max-width: 200px;" 
                large round fill 
                on:click={() => {
                  if(capturedName){
                    f7.swiper.get('.onboarding-popup-swiper').slideNext();
                    const swiperInstance = f7.swiper.get('.onboarding-popup-swiper');
                    swiperInstance.pagination.enable();
                    f7.navbar.show(); // Show the navbar now
                  } else {
                    f7.swiper.get('.onboarding-popup-swiper').slideNext();
                  }
                }}>
                Okay!
            </Button>
            </div>
            <div style="text-align: center; margin-top: 20px;">
              {#if capturedName}
                <Link popupClose>Skip</Link>
              {/if}
            </div>
          </Block>
        </swiper-slide>
        {#if !capturedName}
        <swiper-slide class="name-collection-slide">
          <div class="onboarding-swiper-slide">
            <div class="onboarding-modal-title">
              What shall we call you?
            </div>
            <div class="onboarding-modal-text">
              We need your name so we can personalize Zestyplan.
            </div>
            <List style="width: 100%; margin-top: 0;">
              <ListInput outline label="First name" floatingLabel type="text" placeholder="Your first name" clearButton bind:value={firstName} id="firstNameInput">
              </ListInput>
              <ListInput outline label="Last name" floatingLabel type="text" placeholder="Your last namer" clearButton bind:value={lastName}>
              </ListInput>
            </List>
          </div>
          <Block style="position: absolute; width: 100%; bottom: 5%;">
            <div style="text-align: -webkit-center;">
              <Button 
                style="max-width: 200px;" 
                large 
                round 
                fill 
                class={!firstName || !lastName ? 'disabled' : ''} 
                on:click={() => {
                  updateUserName(firstName, lastName)

                  f7.swiper.get('.onboarding-popup-swiper').slideNext();
                  const swiperInstance = f7.swiper.get('.onboarding-popup-swiper');
                  swiperInstance.pagination.enable();
                  f7.navbar.show(); // Show the navbar now
                }}>
                  Let's get started!
                </Button>
            </div>
          </Block>
        </swiper-slide>
        {/if}
        {#each preferences as preference, preferenceIndex}
            <swiper-slide>
                <Block style="margin-top: 20px;">
                    <ListItem title={preference.text}>
                        {#if preference.type === "short_answer"}
                            <ListInput 
                                bind:value={preferences[preferenceIndex].answer} 
                                placeholder="Your answer here"
                                on:change={() => {
                                    handlePreferencesChange({
                                      preferenceType : preference.type,
                                      preferenceId : preference.id,
                                      preferenceValue : preferences[preferenceIndex].answer
                                    })
                                }}
                                style="
                                    padding: 25px;
                                    border-style: solid;
                                    margin-top: 20px;
                                    border-radius: 8px;
                                    font-size: 16px;
                                "
                            />
                        {:else}
                            <List inset strongIos outlineIos dividersIos style="margin: initial; margin-top: 20px;">
                                {#if preference.selection === "single"}
                                    {#each preference.preferences_options as option, optionIndex}
                                        <ListItem
                                            radio
                                            value={option.id}
                                            title={option.text}
                                            checked={preferences[preferenceIndex].selectedOption === option.id}
                                            on:change={() => {
                                            preferences[preferenceIndex].selectedOption = option.id;
                                            handlePreferencesChange({
                                              preferenceType : preference.type,
                                              preferenceSelection : preference.selection,
                                              preferenceId : preference.id,
                                              preferenceValue : preferences[preferenceIndex]
                                            });
                                            }}>
                                        </ListItem>
                                    {/each}
                                {:else}
                                    {#each preference.preferences_options as option, optionIndex}
                                        <ListItem
                                            checkbox
                                            value={option.text}
                                            title={option.text}
                                            bind:checked={preferences[preferenceIndex].preferences_options[optionIndex].selected}
                                            on:change={async () => {
                                              preferences[preferenceIndex].preferences_options = await handlePreferencesChange({
                                                preferenceType : preference.type,
                                                preferenceSelection : preference.selection,
                                                preferenceId : preference.id,
                                                preferenceValue : preferences[preferenceIndex].preferences_options,
                                                selectedOption : preferences[preferenceIndex].preferences_options[optionIndex]
                                              });
                                            }}
                                        ></ListItem>
                                    {/each}
                                {/if}
                            </List>
                        {/if}
                    </ListItem>
                </Block>
                <Block style="position: absolute; width: 100%; bottom: 5%;">
                    <div style="text-align: -webkit-center;">
                        <Button style="max-width: 200px;" large round fill on:click={() => (f7.swiper.get('.onboarding-popup-swiper').slideNext())}>Next</Button>
                    </div>
                </Block>
            </swiper-slide>
        {/each}
          <swiper-slide>
            <div class="onboarding-swiper-slide">
              <div style="font-size: 42px; margin-bottom: 20px; text-align: center;">
                <!-- svelte-ignore a11y-missing-attribute -->
                <img src="../images/undraw_flowers_vx06.svg" style="width: 75%;"/>
              </div>
              <div class="onboarding-modal-title">
                Great work!
              </div>
              <div class="onboarding-modal-text">
                Zestyplan will use these preferences when generating your meal plans.
              </div>
            </div>
            <Block style="position: absolute; width: 100%; bottom: 5%;">
              <div style="text-align: -webkit-center;">
                <Button style="max-width: 200px;" large round fill popupClose>Done</Button>
              </div>
            </Block>
          </swiper-slide>
      </swiper-container>     
    </Page>
</Popup>

<script>
    import {
        f7,
        Popup,
        Page,
        Navbar,
        NavRight,
        Link,
        Block,
        Button,
        ListItem,
        List,
        ListInput,
        NavLeft
    } from 'framework7-svelte';
    import { getPreferences, handlePreferencesChange } from '../stores/preferences';
    import { getUserData, userDataStore, getUserFirstLastName } from '../stores/userInfo'
    import { tick, onMount, onDestroy } from 'svelte';
    import { updateUserName } from '../js/supabase';

    let preferences = [];
    let preferencesSyncDelay;
    let firstName, lastName;
    let capturedName = false;

    onMount(() => {
      // Capture the user's first and last name
      firstName = getUserFirstLastName($userDataStore).firstName;
      lastName =  getUserFirstLastName($userDataStore).lastName;

      if(firstName && lastName){
        capturedName = true;
      }

    });

    const onSlideChange = (e) => {
      const [swiper] = e.detail;

      // Check if we are on the name collection slide
      if(f7.$(swiper.slides[swiper.activeIndex]).hasClass('name-collection-slide')){
        setTimeout(function(){
          f7.$('#firstNameInput').find('input').focus()
        }, 500)
      }

      if(swiper.activeIndex == 0){
        // If we go back to the first slide, hide the pagination and navbar again
        swiper.pagination.disable();
        f7.navbar.hide(
          f7.navbar.getElByPage('.user-onboarding-preferences')
        )
      }
    }

    async function userPreferencesPopupOpened(){
        // Grab the current personalization questions to ask the user
        preferences = await getPreferences({
          onboardingQuestions : true
        });

        await tick(); // Wait for the DOM update

        const swiperInstance = f7.swiper.get('.onboarding-popup-swiper');
        
        swiperInstance.update(); // Update after manual slide insertion
    }

    async function userPreferenceClose(){
    }
</script>
<style>
  .onboarding-popup-swiper {
    height: 100%;
  }

  .onboarding-swiper-slide {
    margin-top: 40px;
    height: -webkit-fill-available;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 30%;
    padding: 20px;
  }

  .onboarding-modal-text {
    text-align: center;
    font-size: 18px;
  }
</style>