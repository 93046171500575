<Page name="Update Profile Name">
    <Navbar title="Update Name" backLink="Back" />
    <Block strong inset>
      <List form>
        <ListInput
        outline
        label="First Name"
        floatingLabel
        type="text"
        placeholder='First Name'
        clearButton
        name="first-name"
        bind:value={firstName}/>
        <ListInput
        outline
        label="Last Name"
        floatingLabel
        type="text"
        placeholder='Last Name'
        clearButton
        name="last-name"
        bind:value={lastName}/>
    </List>
    </Block>
    <Block>
      <Button large fill onClick={() => {
        updateUserName(firstName, lastName);
        f7.views.current.router.back();
      }}>Update Name</Button>
    </Block>
  </Page>
  <script>
    import { Page, Navbar, Block, ListInput, Button, List } from 'framework7-svelte';
    import { updateUserName } from '../js/supabase'
    import { f7 } from 'framework7-svelte';
    let firstName = '';
    let lastName = '';
  </script>
  