<script>
  import {
    f7,
    Page,
    Navbar,
    Block,
    NavLeft,
    NavTitle,
    Link,
    Button,
    Popup,
    View,
  } from "framework7-svelte";
  import { supabase } from "../js/supabase";
  import { getRecipeDetails, getUserRecipes, userRecipes, selectedRecipe } from "../stores/mealPlan";

  let recipeURL;

  function isValidURL(url) {
    try {
      new URL(url);
      return true;
    } catch (error) {
      return false;
    }
  }

  async function loadUserRecipes(){
    const newRecipes = await getUserRecipes();
    userRecipes.set(newRecipes);
  }

  async function handleImportRecipe() {
    if (!isValidURL(recipeURL)) {
      f7.dialog.alert("The URL provided is not valid.");
      return;
    }

    f7.dialog.preloader("Importing recipe...");

    try {
      const { data, error } = await supabase.functions.invoke("import-url", {
        body: { url: recipeURL },
      });

      if (error) {
        throw new Error(error);
      }

      // This happens when a recipe isn't created cause of a DB error
      if(!data.recipe_id) {
        throw new Error(data.message);
      }

      recipeURL = "";
      f7.dialog.close();
      handleClose();

      f7.views.current.router.navigate(`/recipe/`, {
        props: {
          addToMealPlan: true,
          reloadRecipes: loadUserRecipes
        }
      });

      const recipeData = await getRecipeDetails(data.recipe_id);
      selectedRecipe.set(recipeData);
    } catch (error) {
      f7.dialog.close();
      f7.dialog.alert(`There was an error importing the recipe: ${error}`);
    }
  }

  function handleClose() {
    f7.popup.close(".recipe-import");
  }
</script>

<Popup push class="recipe-import">
  <View>
    <Page>
      <Navbar sliding={false}>
        <NavLeft>
          <Link on:click={handleClose}>Cancel</Link>
        </NavLeft>
        <NavTitle sliding>Import recipe</NavTitle>
      </Navbar>
      <div style="padding-top: 20px;">
        <Block style="margin: 0; padding-bottom: 140px">
          <div class="recipe-import-input">
            <div class="input-header">
              Paste the web link of the recipe you want to add here
            </div>
            <div class="list" style="margin: 0;">
              <div class="input-field outline">
                <textarea
                  id="recipe-url"
                  class="recipe-url"
                  bind:value={recipeURL}
                  placeholder="https://"
                />
              </div>
              <Button large fill on:click={handleImportRecipe}>Import</Button>
            </div>
          </div>
        </Block>
      </div>
    </Page>
  </View>
</Popup>

<style>
  .recipe-import-input {
    width: 100%;
    border-bottom-style: solid;
    border-bottom-color: var(--f7-input-info-text-color);
    border-width: 1px;
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .outline {
    border-radius: 8px;
    border-style: solid;
    border-color: var(--f7-input-info-text-color);
    border-width: 1px;
    padding: 5px;
  }

  .input-header {
    font-weight: 700;
    font-size: 18px;
  }

  .input-field {
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 10px;
    box-sizing: border-box;
    width: 100%;
  }

  .input-field textarea {
    width: 100%;
    overflow-y: hidden;
    resize: none;
    height: 1.2em;
    min-height: 1.2em;
    padding: initial;
  }
</style>
