<App { ...f7params }>
  <!-- Views/Tabs container -->
  <Views tabs class="safe-areas">
    <!-- Tabbar for switching views-tabs -->
    <Toolbar tabbar icons bottom id="appToolbar">
      <div style="width: 100%; position: absolute; top: -4px; left: 0px; display: none;" id="main-progress-bar">
        <Progressbar infinite />
      </div>
      <Link tabLink="#view-discover" text="Discover" default>
        <div style="line-height: normal;">
          <CompassIcon size="28"/>
        </div>
      </Link>
      <Link tabLink="#view-recipes" text="Recipes" >
        <div style="line-height: normal;">
          <BookIcon size="28"/>
        </div>
      </Link>
      <Link tabLink="#view-home" tabLinkActive text="Planner">
        <div style="line-height: normal;">
          <CalendarIcon size="28"/>
        </div>
      </Link>
      <Link tabLink="#view-groceries" text="Groceries">
        <div style="line-height: normal;">
          <ShoppingCartIcon size="28"/>
        </div>
      </Link>
      <Link tabLink="#view-profile" text="Profile">
        <div style="line-height: normal;">
          <UserIcon size="28"/>
        </div>
      </Link>
    </Toolbar>

    <View id="view-discover" name="discover" tab url="/discover/" animated={true} />

    <View id="view-recipes" name="recipes" tab url="/recipes/" animated={true} />

    <!-- Your main view/tab, should have "view-main" class. It also has "tabActive" prop -->
    <View id="view-home" main tab tabActive url="/" animated={true} />

    <!-- Groceries View -->
    <View id="view-groceries" name="groceries" tab url="/groceries/" animated={true} />

    <!-- Profile View -->
    <View id="view-profile" name="profile" tab url="/profile/" animated={true} />

  </Views>
  <LoginScreen id="my-login-screen">
    <View>
        <Page loginScreen>
          <!-- svelte-ignore a11y-missing-attribute -->
          <div style="text-align: center;">
            <img src="../images/ZestyPlan_Logo.png" style="width: 250px">
          </div>
          <!--
          <div style="padding: 15px; text-align: center;">
            If you've received an invitaion to join ZestyPlan, please login with your email address and password. <br>
            <a href="https://zestyplan.com">Signup for our waitlist.</a>
          </div>
          -->
          
          <List form>
            {#if currentPage !== pages.verifyEmail}
              {#if currentPage === pages.signUp}
                <ListInput 
                  name="firstName" 
                  outline 
                  label="First Name" 
                  floatingLabel 
                  type="text" 
                  placeholder="Your first name" 
                  clearButton 
                  bind:value={firstName}
                />
                <ListInput 
                  name="lastName" 
                  outline 
                  label="Last Name" 
                  floatingLabel 
                  type="text" 
                  placeholder="Your last name" 
                  clearButton 
                  bind:value={lastName}
                />
              {/if}            
              <ListInput
                outline
                label="Email"
                floatingLabel
                type="email"
                clearButton
                name="email"
                placeholder="Your email"
                bind:value={email}
              />
            {/if}
            {#if currentPage === pages.verifyEmail}
              <div>
                <p class="verify-email">{email}</p>
                <p class="verify-text">Please verify your email using the verification link we have just sent to your email address.</p>
              </div>
            {/if}
            {#if currentPage !== pages.forgotPassword && currentPage !== pages.verifyEmail}
            <ListInput
              outline
              label="Password"
              floatingLabel
              type="password"
              placeholder={currentPage === pages.login ? 'Your password' : 'Your password, minimum 6 characters'}
              clearButton
              name="password"
              bind:value={password}
            />
            {/if}
            {#if currentPage === pages.login}
              <div style="text-align: right; margin-right: 15px;">
                <Link onClick={() => setPage(pages.forgotPassword) }>Forgot Password?</Link>
              </div>
            {/if}
          </List>
          {#if currentPage === pages.login}
            <Block>
              <Button large fill class="{(email && password ? '' : 'disabled')}" onClick={handleOnClickLogin}>Sign In</Button>
              <div style="text-align: center; margin-top: 20px;">
                Don't have an account? <Link onClick={() => setPage(pages.signUp) }>Register</Link>
              </div>
              <div class="social-login">
                <div class="line-with-text">
                  <span class="line-text">OR</span>
                </div>
                <Button large fill class="sign-in-google-button" style="background: #4285F4; color: #fff; margin-bottom: 10px;" on:click={() => google()}>
                  <i class="fa-brands fa-google" style="margin-right: 5px; font-size: 20px;"/>
                  Sign in with Google
                </Button>
                <!-- 
                Commenting out Facebook for now as it doesn't work
                <button class="social-signin" on:click={() => facebook()}>
                  <img class="social-image" src="../images/facebook_signin.png" alt="Sign in with Facebook" />
                </button>
               -->

               <!-- 
                Apple seems to not work with localhost urls
                -->
                {#if window.cordova && window.cordova.platformId == 'ios'}
                <Button large fill class="sign-in-apple-button" style="background: #000; color: #fff;" on:click={() => apple()}>
                  <i class="fa-brands fa-apple" style="margin-right: 5px; font-size: 20px;"/>
                  Sign in with Apple
                </Button>
                {:else if !window.cordova}
                <Button large fill class="sign-in-apple-button" style="background: #000; color: #fff;" on:click={() => apple()}>
                  <i class="fa-brands fa-apple" style="margin-right: 5px; font-size: 20px;"/>
                  Sign in with Apple
                </Button>
                {/if}
              </div>
            </Block>
            
          {:else if currentPage === pages.signUp}
            <Block>
              <Button large fill onClick={handleRegisterUser} class="{(email && password && firstName && lastName ? '' : 'disabled')}">Sign Up</Button>
              <div style="text-align: center; margin-top: 20px;">
                Already have an account? <Link onClick={() => setPage(pages.login) }>Login</Link>
              </div>
              <div class="social-login">
                <div class="line-with-text">
                  <span class="line-text">OR</span>
                </div>

                <!-- Google auth is not working on  -->
                <!-- 
                <Button large fill class="sign-in-google-button" style="background: #4285F4; color: #fff; margin-bottom: 10px;" on:click={() => google()}>
                  <i class="fa-brands fa-google" style="margin-right: 5px; font-size: 20px;"/>
                  Sign up with Google
                </Button>
                -->
                <!-- 
                Commenting out Facebook for now as it doesn't work
                <button class="social-signin" on:click={() => facebook()}>
                  <img class="social-image" src="../images/facebook_signin.png" alt="Sign in with Facebook" />
                </button>
               -->

               <!-- 
                Apple seems to not work with localhost urls
                -->
                {#if window.cordova && window.cordova.platformId == 'ios'}
                  <Button large fill class="sign-in-apple-button" style="background: #000; color: #fff;" on:click={() => apple()}>
                    <i class="fa-brands fa-apple" style="margin-right: 5px; font-size: 20px;"/>
                    Sign up with Apple
                  </Button>
                {:else if !window.cordova}
                  <Button large fill class="sign-in-apple-button" style="background: #000; color: #fff;" on:click={() => apple()}>
                    <i class="fa-brands fa-apple" style="margin-right: 5px; font-size: 20px;"/>
                    Sign up with Apple
                  </Button>
                {/if}
                
              </div>
            </Block>
          {:else if currentPage === pages.forgotPassword}
            <Block>
              <Button large fill onClick={() => resetPassword({
                email : email
              })}>Reset Password</Button>
              <div style="text-align: center; margin-top: 20px;">
                <Link onClick={() => setPage(pages.login) }>Back</Link>
              </div>
            </Block>
          {:else if currentPage === pages.verifyEmail}
            <Block>
              <Button large fill onClick={() => handleVerifyUser()}>I've verified!</Button>
            </Block>
          {/if}
          <!--
          <BlockFooter>
            By using Zestyplan you agree to our <a href="https://zestyplan.com">terms of service.</a>
          </BlockFooter>
          -->
        </Page>
    </View>
  </LoginScreen>
  <NewInvitationPopup/>
  <!-- Implementing a rudamentary version update popup modal for now, in the future this should be driven by the database, and should transform into a swipe step sheet if there are multiple updates for the user to see -->
  <Sheet 
    class="version-updates-sheet" 
    style="height: auto; border-radius: 10px 10px 0 0; overflow: hidden; padding-bottom: 20px;" 
    swipeToClose 
    push 
    backdrop
    on:sheetClosed={() => setMilestone(`v${appVersion}`)}
  >
    <div class="swipe-handler" />

    <PageContent>
      <BlockTitle large>Update v.{appVersion}</BlockTitle>
      <Block>
        We're always trying to improve Zestyplan, here are some of the latest updates.
      </Block>
      <BlockTitle medium>What's new</BlockTitle>
      <List dividers mediaList>
        <ListItem
          title="Edit recipes using Zestyplan Assistant!"
          text="Just find a recipe, click on the edit button, and use the assistant to make changes."
        >
        <div slot="media">
          <ZapIcon size="32"/>
        </div>
        </ListItem>
        <ListItem
          title="Improved CO2 emission calculations for recipes"
          text="Enjoy more accurate data on the environmental impact of your meals."
        >
          <div slot="media">
            <RefreshCwIcon size="32"/>
          </div>
        </ListItem>
      </List>
      <Block style="margin-top: -15px;">
        <Button large external style="margin-bottom: 15px; font-size: 1em;" on:click={() => {
          const TARGET = process.env.TARGET || "web"; // Default to Web
          if(TARGET.toLocaleLowerCase() === 'cordova') {
            window.cordova.InAppBrowser.open('https://forms.gle/KvmSMi3TbiabWfG99', '_system'); // Open app in System's default browser rather than in app
          } else {
            window.open('https://forms.gle/KvmSMi3TbiabWfG99' , '_blank');
          }
        }}>Provide feedback</Button>
        <Button sheetClose large fill>Continue</Button>
      </Block>
    </PageContent>
  </Sheet>
  
</App>
<script>
  import * as Sentry from "@sentry/svelte";

  Sentry.init({
    dsn: "https://84de99fe462ea1c07691dfad8cdb0032@o4505675922800640.ingest.sentry.io/4505675925356544",
    integrations: [
      new Sentry.BrowserTracing({
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: ["localhost", "https://staging.zestyplan.com", "https://app.zestyplan.com"],
      }),
      new Sentry.Replay(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    enabled: process.env.NODE_ENV === 'production'
  });

  import { onMount } from 'svelte';
  import { getDevice }  from 'framework7/lite-bundle';
  import {
    f7,
    f7ready,
    App,
    Views,
    View,
    Page,
    Toolbar,
    Link,
    LoginScreen,
    List,
    ListItem,
    ListInput,
    Block,
    Button,
    Progressbar,
    Sheet,
    BlockTitle,
    PageContent
  } from 'framework7-svelte';
  import { createUser, initBucket, loginUser, resetPassword, supabase, createProfile } from '../js/supabase'
  import cordovaApp from '../js/cordova-app';
  import routes from '../js/routes';
  import store from '../js/store';
  import mealPlanStore, { getUserInvitations, initPlanForUser, newInvitation, pendingInvitations, plan, fetchUserPlan, getRecipeDetailsFromId, selectedRecipe } from "../stores/mealPlan"
  import { initUser, googleLogin, facebookLogin, appleLogin } from '../js/supabase';
  import { CompassIcon, BookIcon, CalendarIcon, ShoppingCartIcon, UserIcon, CameraIcon, StarIcon, ZapIcon, SearchIcon, RefreshCwIcon } from 'svelte-feather-icons'
  import { getUserData, userDataStore, hasMilestone, setMilestone } from '../stores/userInfo'
  import NewInvitationPopup from './NewInvitationPopup.svelte';
  import { tick } from 'svelte';
  import { appProgressBar } from '../js/generalHelpers'
  import { handleDeepLink } from '../js/generalHelpers'

  // Login Screen data

  const pages = {
    login: 'login',
    signUp: 'sign-up',
    forgotPassword: 'forgot-password',
    passwordReset: 'password-reset',
    verifyEmail: 'verify-email'
  }

  // TODO: Abstract this into a globally accessible variable
  const appVersion = '1.0.18';

  let email = '';
  let password = '';
  let firstName = '';
  let lastName = '';
  let currentPage = pages.login;

  $:onMealPlanStoreChange($mealPlanStore)
  function onMealPlanStoreChange(value) {}

  const device = getDevice();
  // Framework7 Parameters
  let f7params = {
    name: 'Zestyplan', // App name
    theme: 'auto', // Automatic theme detection
    colors: {
      primary: '#5cb046',
    },

    id: 'com.zestyplan.zestyplan', // App bundle ID
    // App store
    store: store,
    // App routes
    routes: routes,

    // Register service worker (only on production build)
    serviceWorker: process.env.NODE_ENV ==='production' ? {
      path: '/service-worker.js',
    } : {},

    // Input settings
    input: {
      scrollIntoViewOnFocus: device.cordova,
      scrollIntoViewCentered: device.cordova,
    },
    // Cordova Statusbar settings
    statusbar: {
      iosOverlaysWebView: true,
      androidOverlaysWebView: false,
    },
    darkMode : 'auto',
    navbar:{
      snapPageScrollToLargeTitle:false
    }
  };

  let realTimeEventListeners;
  onMount(async () => {
    let authenticatedUser = await getUserData();
    document.addEventListener('deviceready', function(){
      // Check if cordova is available
      if(!window.cordova){
        return
      }

      // Wait for the Cordova APIs to become ready
      try {
          window.universalLinks.subscribe('openZestyplanLink', function(eventData){
            // Clean any HTML characters present and pass it along to be parsed
            handleDeepLink(eventData.url.replace(/&amp;/g, '&')
            .replace(/&lt;/g, '<')
            .replace(/&gt;/g, '>')
            .replace(/&quot;/g, '"')
            .replace(/&#39;/g, "'"))
          });
        } catch (error){
          console.log('Error listening for zestyplan link', error)
        }

        try {
          console.log('Starting UXCam');
          UXCam.optIntoSchematicRecordings() /* To enabled session video recording on iOS */
          UXCam.startWithKey("4qb7sefcaws81ge")
          console.log('UXCam started')
        } catch(error){
            console.log('Error starting UXCam', error)
        }
    }, false);

    f7ready(async () => {
      // Init cordova APIs (see cordova-app.js)
      if (f7.device.cordova) {
        cordovaApp.init(f7);

        window.addEventListener('keyboardWillShow', (event) => {
          // Describe your logic which will be run each time when keyboard is about to be shown.
          Framework7ComponentsApp.f7.toolbar.hide('.views .toolbar', false);

          Framework7ComponentsApp.f7.$('.fab').css('display', 'none')
        });

        window.addEventListener('keyboardDidHide', () => {
          // Describe your logic which will be run each time keyboard is closed.
          Framework7ComponentsApp.f7.toolbar.show('.views .toolbar');

          Framework7ComponentsApp.f7.$('.fab').css('display', 'block')
        });
      }

      // Call F7 APIs here
      await initUser();

      if(!f7.device.cordova){
        // If not a cordova application

        // Check for current user
        let currentUserData;
        userDataStore.subscribe(value=>{
          currentUserData = value;
        })();

        if(Object.keys(currentUserData).length != 0){
          handleDeepLink();
        }
      }

      // Add FALSE to below IF statement to disable screen
      if(localStorage.userData){
        // Check to see if we need to show version updates
        const hasVersionMilestone = await hasMilestone(`v${appVersion}`);
        if(!hasVersionMilestone){
          f7.sheet.open('.version-updates-sheet');
        }
      }
      
    });

    if (f7.device.cordova) {
        console.log('Initializing keyboard event listeners')
        window.addEventListener('keyboardWillShow', (event) => {
          console.log('Will Show')
          // Describe your logic which will be run each time when keyboard is about to be shown.
          Framework7ComponentsApp.f7.toolbar.hide('.views .toolbar', false);

          Framework7ComponentsApp.f7.$('html').addClass('virtual-keyboard-open')
        });

        window.addEventListener('keyboardDidHide', () => {
          console.log('Did hide')
          // Describe your logic which will be run each time keyboard is closed.
          Framework7ComponentsApp.f7.toolbar.show('.views .toolbar');

          Framework7ComponentsApp.f7.$('html').removeClass('virtual-keyboard-open')
        }); 
    }

    const redirects = getPageRedirect();
      
    // Redirect if it has a specific page from an external site
    if (redirects.length > 0) {
      const redirectPath = redirects[0];
      switch(redirectPath) {
        case pages.passwordReset:
          f7.views.current.router.navigate(`/${pages.passwordReset}/`);
          break;
        default:
          // fallback in case user enters a route we don't support
          f7.views.current.router.navigate('/');
      }
    }

    if(localStorage.onboardingComplete !== 'true' && redirects.length === 0 && !localStorage.userData) {
      // User has not gone through onboarding and not redirected to another page
      f7.views.current.router.navigate('/onboarding/')
    }

    if(authenticatedUser && authenticatedUser.id){
      await initializeRealTimeEventListeners();
    }
  })

  supabase.auth.onAuthStateChange(async function(event, session){
    // HACKY: Currently required to wait a bit before subscribing to realtime events
    // TODO: Clean this up to poperly listen for and trigger realtime once it's ready
    if (event == 'SIGNED_IN') {
      appProgressBar.show();
      try {
        // Check if already logged in, if so ignore initializing user 
        const isAlreadyLoggedin = $userDataStore.id !== undefined;
        if (isAlreadyLoggedin) {
          appProgressBar.hide();
          return;
        }
        await initUser();

        let user = await getUserData();

        if (user) {
          await initPlanForUser();
          await initBucket();
          await fetchUserPlan(new Date());
        }
        appProgressBar.hide();
      } catch(error) {
        appProgressBar.hide();
        throw new Error(error);
      }

      // Timeout for realtime listeners
      setTimeout(function(){
        if(!realTimeEventListeners){
          initializeRealTimeEventListeners();
        }
      }, 3000)
    }
  })

  async function initializeRealTimeEventListeners(){
    if(realTimeEventListeners){
      console.log('realTimeEventListeners already initialized')
      return
    }

    await tick();
    // Invitation event listener
    supabase
    .channel('any')
    .on('postgres_changes', { event: '*', schema: 'public' }, async (payload) => {
      await tick();
      
      if(payload.table == 'plan_invitation'){
        let currentUser = await getUserData();
        if(payload.eventType == 'INSERT'){
          // New invitation
          if(payload.new.invited_by == currentUser.id){
            // TODO: Remove this console log after testing
            console.log('TO REMOVE: The current update is for an invitation that the current user sent')
            return;
          } else if(
            payload.new.invitee_user_id && 
            payload.new.invitee_user_id == currentUser.id &&
            payload.new.status == "PENDING"
          ){
            // New invitation is pending and intended for the current user
            fetchUserInvitations();
          }
        } else if(payload.eventType == 'UPDATE'){
          // TODO
          console.log('TODO: Handle invitation update')
        } else if(payload.eventType == 'DELETE'){
          // TODO
          console.log('TODO: Handle invitation delete')
        }
      } else if(payload.table == 'plan_recipe'){
        // Get current plan information
        let currentPlan;
        plan.subscribe(value=>{
          currentPlan = value;
        })();

        // Stop execution if the update doesn't pertain to the current plan
        if(payload.eventType == 'DELETE'){
          // NOTE: Delete only returns the ID of the deleted record. So we will need to see if that ID is present in the current plan recipes
          let foundRecipe = false;
          for(const planRecipe of currentPlan.plan_recipe){
            if(planRecipe.id == payload.old.id){
              foundRecipe = true
            }
          }

          if(!foundRecipe){
            // This recipe doesn't pertain to the current plan recipes, therefore is not relevant
            return
          }
        }

        // Dispatch an update to know that we should update the current recipes for user
        let event = new Event('planRecipeUpdate');
        document.dispatchEvent(event);
      } else if(payload.table == 'grocery_item'){
        let event = new CustomEvent('groceriesUpdate', {
          detail : {
            payload : payload
          }
        });
        document.dispatchEvent(event);
      }
    })
    .subscribe();

    realTimeEventListeners = true;
  }

  function showToast(message){
      let toastBottom = f7.toast.create({
          text: message,
          destroyOnClose: true,
          closeTimeout: 2000
      });

      // Open it
      toastBottom.open();
  }

  function setPage(page) {
    currentPage = page;
  }

  function getPageRedirect() {
    const params = new URLSearchParams(window.location.search);
    const redirectParam = params.get('redirect');

    // If redirect parameter is found in the URL, return it, else return an empty array
    return redirectParam ? [redirectParam] : [];
  }

  async function handleOnClickLogin(){
    await loginUser({
      email : email,
      password : password
    });
  }

  async function handleRegisterUser(){
    try {
      localStorage.setItem('first_name', firstName);
      localStorage.setItem('last_name', lastName);
      await createUser({email, password, firstName, lastName});
      currentPage = pages.verifyEmail;
    } catch(e){
      f7.dialog.alert('There was an issue registering for an account.')
    }
  }

  async function handleVerifyUser(){
    let userData, userDataSupabase, sessionData
    try {
      userData = await getUserData();
      userDataSupabase = await supabase.auth.getUser();
      const sessionResponse = await supabase.auth.getSession();
      sessionData = sessionResponse.data.session.user;
    } catch(e){
      console.error(e);
    }

    if(sessionData){
      // We are working in the local environment, or email verification has been disabled
      await createProfile(); // Profile creation must be triggered manually

      // Seeing as we already have a session present, we can continue with the init of the app
      const { data: { user } } = await supabase.auth.getUser();
      if (user) {
        await initPlanForUser();
        await initBucket();
      }

    } else {
      // We are working in production with email verification requirements
      // Switch back to login screen and attempt login again
      
      currentPage = pages.login

      await loginUser({
        email : email,
        password : password
      })
    }
  }

  // Listen for changes on the newInvitation writable
  newInvitation.subscribe(value => {
    if(!value){
      return
    }

    f7.popup.open('.new-invitation');
  })

  async function fetchUserInvitations(){
    const invitations = await getUserInvitations();
    if ( invitations && invitations.length > 0) {
      newInvitation.set(invitations[0]);
      f7.popup.open('.new-invitation');
    }
  }

  function google() {
    googleLogin();
  }

  function facebook() {
    facebookLogin();
  }

  function apple() {
    appleLogin();
  }

</script>
<style>
  :root {
    --f7-button-large-text-transform: none;
    --f7-fab-size: 65px;
    --f7-page-navbar-offset: 0px;
  }
  
  :global(.navbar-bg) {
    /* Not required at the moment, but might be needed in the future */
    /* background-color: var(--f7-page-bg-color) !important; */
  }

  :global(.onboarding-modal-media) {
    font-size: 8em;
    text-align: center;
  }

  :global(.ios) {
    --f7-page-bg-color : #fff;
  }

  :global(.popup-close-button) {
    position: fixed;
    font-size: 24px;
    left: 20px;
    top: 15px;
    z-index: 50;
  }

  :global(.dark .sign-in-apple-button) {
    background: #fff !important;
    color: #000 !important;
  }

  :global(.dark .sign-in-google-button) {
    background: #fff !important;
    color: #4285F4 !important;
  }

  .line-with-text {
    position: relative;
    text-align: center;
    overflow: hidden;
    margin-top: 20px;
    margin-bottom: 20px;
    color: #c9c9c9
  }

  .line-with-text::before,
  .line-with-text::after {
    content: "";
    position: absolute;
    top: 50%;
    width: 50%;
    border-bottom: 1px solid #c9c9c9;
  }

  .line-with-text::before {
    left: 0;
  }

  .line-with-text::after {
    right: 0;
    z-index: -1;
  }

  .line-text {
    position: relative;
    display: inline-block;
    padding: 0 10px;
    background-color: var(--f7-page-bg-color); /* Same as the background color of the page */
  }

  :global(.fab) {
    font-family: inherit !important;
  }

  :global(.sheet-close-button) {
    position: absolute;
    font-size: 24px;
    right: 20px;
    top: 15px;
    z-index: 5;
  }

  :global(.action-sheet-icon) {
    color: var(--f7-theme-color);
    font-size: 20px;
    width: 20px;
  }

  :global(.navbar-icon-1) {
    padding-right: 10px;
    font-size: 24px;
    transition: color 0.3s ease;
  }

  :global(.static-floating-footer) {
    background: var(--f7-page-bg-color);
    position: fixed;
    bottom: 0;
    z-index: 1;
    width: 100%;
    padding: 20px;
    -webkit-box-sizing: border-box;
    /* Safari/Chrome, other WebKit */
    -moz-box-sizing: border-box;
    /* Firefox, other Gecko */
    box-sizing: border-box;
    /* Opera/IE 8+ */
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.5);
    border-radius: 16px 16px 0px 0px;
  }

  :global(.onboarding-modal-title) {
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 15px;
    text-align: center;
  }

  :global(.onboarding-modal-text) {
    margin-bottom: 20px;
  }

  :global(.swipe-handler){
    height: 16px;
    position: absolute;
    left: 0;
    width: 100%;
    top: 0;
    background: #fff;
    cursor: pointer;
    z-index: 10;
    background: none;
  }

  :global(.swipe-handler:after){
    content: "";
    width: 36px;
    height: 6px;
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: -18px;
    margin-top: -3px;
    border-radius: 3px;
    background: #666;
  }
</style>