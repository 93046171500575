<Page name="home" id="home-page" on:pageTabShow={pageView}>
  <!-- Top Navbar -->
  <!-- 
  <Navbar sliding={false}>
    <NavTitle sliding>{$plan ? $plan.name || 'Planner' : 'Planner'}</NavTitle>
  </Navbar>
  -->
  <Navbar large transparent sliding={false} mdCenterTitle>
    <NavLeft>
      <Link panelOpen="#panel-plans" iconIos="f7:chevron_left" iconMd="material:menu" text="Plans {$memberPlans.length > 1 ? '(' + $memberPlans.length + ')' : ''}"/>
    </NavLeft>
    <NavTitle sliding>{$plan ? $plan.name || 'Planner' : 'Planner'}</NavTitle>
    <NavRight style="margin-left: 0;">
      <NavBarButtons placeholder={true}/>
    </NavRight>
    
    <div slot="title-large" style="
      display: flex;
      align-items: flex-end;
    ">
      <div style="
      font-size: 32px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    ">
        {$plan ? $plan.name || 'Planner' : 'Planner'}
      </div>
      
      <NavBarButtons placeholder={true}/>
    </div>
    <Subnavbar>
      <NavBarButtons page='home'/>
      <WeekSelector
        currentStartDate={$currentMealPlanStartDate}
        onClickPreviousWeek={onClickPreviousWeek}
        onClickNextWeek={onClickNextWeek}
      />
    </Subnavbar>
  </Navbar>
  <Panel left floating swipe={true} swipeOnlyClose={true} id="panel-plans">
    <Page>
      <div style="text-align: right; padding: 20px 20px 0px 0px;">
        <Link panelClose iconF7="xmark" />
      </div>
      <BlockTitle style="margin-top: 10px;">Plans</BlockTitle>
      <List dividers outline strong>
        {#each $memberPlans as userPlan}
          <ListItem 
            link="#"
            panelClose
            on:click="{() => {
              // This complex workaround is required due to an issue where changing the plan before the panel closes
              // Causes the closing process to crash, and therefore not properly un-render the backslash
              const handlePanelClosed = function(plan) {
                  return function() {
                      changePlan(plan);
                      
                      // Remove the event listener after the inner code runs
                      f7.off('panelClosed', handlePanelClosedWithPlan);
                  };
              };

              // Use the closure to capture userPlan
              const handlePanelClosedWithPlan = handlePanelClosed(userPlan);

              // Set the event listener
              f7.on('panelClosed', handlePanelClosedWithPlan);
            }}"
            title={userPlan.name} 
          >
            <div slot="after">
              {#if $plan.id == userPlan.id}
                <Icon f7={"checkmark_alt_circle_fill"} style="color: var(--f7-theme-color);" />
              {/if}
            </div>
          </ListItem>
        {/each}
      </List>
    </Page>
  </Panel>
  <Popover class="popover-menu-plans">
    <List>
      {#each $memberPlans as userPlan}
        <ListItem class="popover-close" on:click="{() => changePlan(userPlan)}" title={userPlan.name} />
      {/each}
    </List>
  </Popover>
  <RecipeImport></RecipeImport>
  <QuickAdd></QuickAdd>
  <!-- Page content -->
  <!-- 
  <div class="page-title" style="display: flex; justify-content: space-between;">
        <Link style="display: flex; flex-direction: row; align-items: center; justify-content: flex-start; flex: 1; max-width: 70%; font-size: 22px" on:click={onClickPlanName} popoverOpen=".popover-menu-plans">
          <span class="span-plan-name">{$plan ? $plan.name || 'Planner' : 'Planner'}</span>
          <Icon style="margin-left: 10px" f7="chevron_down" />
        </Link>      
    <Popover class="popover-menu-plans">
      <List>
        {#each $memberPlans as userPlan}
          <ListItem class="popover-close" on:click="{() => changePlan(userPlan)}" title={userPlan.name} />
        {/each}
      </List>
    </Popover>
    <div style="float: right;">
      <Link style="margin-right: 10px" on:click={onClickUsers}>
        <UsersIcon size="24"/> 
      </Link>
      <Link iconIos="f7:ellipsis_circle" iconMd="material:more_vert" popoverOpen=".popover-menu"/>
    </div>
  </div>
  -->
  <swiper-container pagination={true} class="tips-tricks-swiper" space-between={50}>
    {#if planNotices.length > 0}
      {#each planNotices as notice }
        <swiper-slide>
          <div class="zesty-tip">
            <div style="display: flex; width: 100%; align-items: center;">
              <div class="zesty-tip-icon">
                <i class="fa fa-leaf" style="font-size: 24px;"></i>
              </div>
              <div>
                <div class="zesty-tip-title">
                  {notice.title}
                </div>
                <div class="zesty-tip-body-text">
                  {@html notice.body}
                </div>
              </div>
            </div>
          </div>
        </swiper-slide>
      {/each}
    {/if}
  </swiper-container>
    {#if $planRecipes && $planRecipes.filter(planRecipe => {
      const planRecipeDate = new Date(new Date(planRecipe.date).toISOString().slice(0, -1));

      let weekStart = new Date($currentMealPlanStartDate.getFullYear(), $currentMealPlanStartDate.getMonth(), $currentMealPlanStartDate.getDate());

      const weekEndDate = getWeekRange(weekStart).weekEnd;
      const weekEnd = new Date(weekEndDate.getFullYear(), weekEndDate.getMonth(), weekEndDate.getDate(), 23, 59, 59);

      return planRecipeDate.getTime() >= weekStart.getTime() && planRecipeDate.getTime() <= weekEnd.getTime()
    }).length == 0}
   <!--
   <Block>
      <div style="
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-style: solid;
        border-color: #dadada;
        border-radius: 16px;
        padding: 20px;
      ">
        <img src="../images/undraw_booking_re_gw4j.svg" style="width: 50%;"/>
        <div style="
          padding: 20px;
          font-size: 20px;
          font-weight: 700;
          text-align: center;
        ">
          It's going to be a great week!
        </div>
        <div>
          <Button large fill round style="font-weight: 700; padding: 20px;" on:click={() => (
              // Disabling meal planning wizard until complete
              // f7.views.current.router.navigate('/meal-planning-wizard/')
              onClickAddButton()
            )}>
            Start planning
          </Button>
        </div>
      </div>
    </Block>
    -->
    <!-- TODO: Add something here if hte meal plan is blank, or remove the logic above -->
  {:else}
    {#if $featureTogglesStore.includes('ModifyUsingAI')}
    <List strong outlineIos dividersIos accordionList inset>
      <ListItem accordionItem title="Modify using AI">
        <i class="fa fa-bolt" slot="media"/>
        <AccordionContent>
          <Block style="margin-bottom: 10px;">
            <div style="padding: 5px; margin-bottom: 5px;">
              Enter in your modifications below, and let the Zestyplan AI re-build your meal plan!
            </div>
            <!--
            <div class="filters-container">
              <div class="filters">
                  <div class="filter">Cuisine</div>
                  <div class="filter">Diet</div>
                  <div class="filter">Health Label</div>
                  <div class="filter">Meal Type</div>
                  <div class="filter">Calories</div>
                  <div class="filter">Time</div>
              </div>
            </div>
            -->
            <div style="border-style: solid; border-radius: 16px; border-color: #dadada;">
              <textarea placeholder="Your modifications here" style="width: 100%; padding: 10px;" bind:value={aiModificationInput}></textarea>
            </div>
            <div style="text-align: right; margin-top: 5px;">
              <Link href="/user-preferences/">
                Update your preferences <i class="fa fa-chevron-right" style="margin-left: 5px;"/>
              </Link>
            </div>
            <Button large fill style="margin-top: 10px;" disabled={aiModificationInput.length === 0}>Modify</Button>
          </Block>
        </AccordionContent>
      </ListItem>
    </List>
    {/if}
  {/if}
  <div style="margin-top: 0; margin-bottom: 100px;">
    {#each daysOfTheWeek as dayOfWeek}
      <!-- svelte-ignore a11y-click-events-have-key-events -->
      <div class="block-title meal-plan-dow-title" on:click={onClickAddButton}>{dayOfWeek}</div>
      <div class="slider-container">
        <div bind:this={sortableItems[dayOfWeek]} id={dayOfWeek} style="
          padding-left: 15px;
          padding-right: 15px;
          display: flex;
          gap: 10px;
          width: 100%;">
      {#if mealPlanLoading || !$planRecipes}
        <swiper-slide class="recipe-item skeleton-text skeleton-effect-wave">
          <div class="recipe-media" style="background: #ccc;">
            <div class="more-btn"><Icon f7="ellipsis_circle_fill" color="white"/></div>
            <div class="rating">
              <span class="stars"><i class="fas fa-star"></i></span>
              <span class="rating-num">4.5</span>
            </div>
            <div class="time"><i class="far fa-calendar-alt"></i>30 min</div>
          </div>
          <div class="recipe-title" style="padding-top: 5px;">
            Lorem ipsum dolar emet lkj alkjsd fghfghfghhfgwe
          </div>
        </swiper-slide>
        <swiper-slide class="recipe-item skeleton-text skeleton-effect-wave">
          <div class="recipe-media" style="background: #ccc;">
            <div class="more-btn"><Icon f7="ellipsis_circle_fill" color="white"/></div>
            <div class="rating">
              <span class="stars"><i class="fas fa-star"></i></span>
              <span class="rating-num">4.5</span>
            </div>
            <div class="time"><i class="far fa-calendar-alt"></i>30 min</div>
          </div>
          <div class="recipe-title" style="padding-top: 5px;">
            Lorem ipsum dolar emet lkj alkjsd jlsakdj
          </div>
        </swiper-slide>
        <swiper-slide class="recipe-item skeleton-text skeleton-effect-wave">
          <div class="recipe-media" style="background: #ccc;">
            <div class="more-btn"><Icon f7="ellipsis_circle_fill" color="white"/></div>
            <div class="rating">
              <span class="stars"><i class="fas fa-star"></i></span>
              <span class="rating-num">4.5</span>
            </div>
            <div class="time"><i class="far fa-calendar-alt"></i>30 min</div>
          </div>
          <div class="recipe-title" style="padding-top: 5px;">
            Lorem ipsum dolar emet lkj alkjsd jlsakdj
          </div>
        </swiper-slide>
      {/if}
      {#if $planRecipes && $planRecipes.length > 0}
        {@const planRecipesForDay = $planRecipes.filter(planRecipe => {
            const planRecipeDate = new Date(new Date(planRecipe.date).toISOString().slice(0, -1));

            let weekStart = new Date($currentMealPlanStartDate.getFullYear(), $currentMealPlanStartDate.getMonth(), $currentMealPlanStartDate.getDate());

            const weekEndDate = getWeekRange(weekStart).weekEnd;
            const weekEnd = new Date(weekEndDate.getFullYear(), weekEndDate.getMonth(), weekEndDate.getDate(), 23, 59, 59);

            if ((planRecipeDate.getTime()) >= (weekStart.getTime()) && (planRecipeDate.getTime()) <= (weekEnd.getTime())) {
              const planRecipeDay = planRecipeDate.getDay();
              return dayToDateMap[planRecipeDay] === dayOfWeek;
            }
            return false;
          })
        }
        {#each planRecipesForDay as item, index (item.id)}
          {@const 
            background = item['recipe_image'] 
            ? 
            "background-image: url('" + item['recipe_image'] + "'); background-color: var(--f7-theme-color);" 
            : 
            "border-style: solid; border-width: 3px; border-color: var(--f7-theme-color); box-sizing: border-box;"
          }
          {@const onclick = item['recipe_type'] !== "quick_add" ? () => onClickRecipeItem(item, dayOfWeek) : null}
          <!-- svelte-ignore a11y-click-events-have-key-events -->
          <swiper-slide id={index} class="recipe-item no-select" on:click={onclick} planRecipeId={item.id} recipeId={item["recipe_type"] === "external" ? item['recipe_uri'].split('#')[1] : item["id"]}>
            <!-- Disabling gradient background for ratings and time estimate for now 
            <div class="recipe-media" style="background-image: linear-gradient(to top, rgba(0, 0, 0, 0.7),rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0)), url('{item['recipe_image']}');">
            -->
            <div class="recipe-media" style={background}>
              <div class="more-btn" on:click={(event) => handleRecipeMoreButton(event, item)}>
                <Icon f7="ellipsis_circle_fill" style="{item['quick_add_emoji'] ? 'color: var(--f7-theme-color);' : 'color: #fff; text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.5);'}"/>
              </div>
              {#if item['quick_add_emoji']}
                <div class="quick-add-emoji-container">
                  {item['quick_add_emoji']}
                </div>
              {:else if !item['recipe_image']}
                <div style="width: 100%; height: -webkit-fill-available; display: flex; align-items: end; font-size: 1.2em; overflow-wrap: anywhere; background: var(--f7-theme-color); color: #fff">
                  <div style="padding: 5px;">
                    {item['recipe_name']}
                  </div>
                </div>
              {/if}
              <!-- Disabling rating and time estimate for now
                TODO: Re-enable once this data is available
              <div class="rating">
                <span class="stars"><i class="fas fa-star"></i></span>
                <span class="rating-num">4.5</span>
              </div>
              <div class="time"><i class="far fa-calendar-alt"></i>30 min</div>
              -->
            </div>
            <div class="recipe-title">
              {item['recipe_name'] && (item['quick_add_emoji'] || item['recipe_image']) ? item['recipe_name'] : ''}
            </div>
          </swiper-slide>
        {/each}
      {/if}
        </div>
      </div>
      <div class="horizontal-rule"></div>
    {/each}
  </div>
  {#if $featureTogglesStore.includes('ZestyGPT')}
    <ZestyGpt></ZestyGpt>
  {/if}
  <Popover class="home-popover-menu">
    <List>
      <ListItem class="popover-close" on:click="{() => 
        (
          changePlannerName()
        )}" title="Change Planner Name" />
        <ListItem class="popover-close" on:click="{() => duplicateWeek() }" title="Duplicate this week" />
        <ListItem class="popover-close" on:click="{() => clearWeek()}" title="Clear entire week" />
        <ListItem class="popover-close" on:click={() => {
          const TARGET = process.env.TARGET || "web"; // Default to Web
          if(TARGET.toLocaleLowerCase() === 'cordova') {
            window.cordova.InAppBrowser.open('https://forms.gle/KvmSMi3TbiabWfG99', '_system'); // Open app in System's default browser rather than in app
          } else {
            window.open('https://forms.gle/KvmSMi3TbiabWfG99' , '_blank');
          }
        }} title="Leave feedback" />
    </List>
  </Popover>
  <Sheet backdrop swipeToClose class="action-sheet" style="
    height: auto; 
    border-radius: 16px 16px 0 0;">
    <div class="action-sheet-container">
      <div class="sheet-close-button">
        <Link sheetClose style="">
          <i class="fa fa-xmark navbar-icon"></i>
        </Link>
      </div>
      <Block style="margin-top: 40px;">
        <List>
          <ListItem title="Quick add" class="sheet-close" on:click={() => (f7.popup.open('.quick-add'))}>
            <i slot="media" class="fas fa-bolt action-sheet-icon"></i>
          </ListItem>
          <ListItem title="Find recipe" class="sheet-close" on:click={onClickFindRecipe}>
            <i slot="media" class="fas fa-magnifying-glass action-sheet-icon"></i>
          </ListItem>
          <ListItem title="Import recipe from web link" class="sheet-close" on:click={() => (f7.popup.open('.recipe-import'))}>
            <i slot="media" class="fas fa-link action-sheet-icon"></i>
          </ListItem>
          <ListItem title="Create recipe" class="sheet-close" on:click={onClickCreateRecipe}>
            <i slot="media" class="fas fa-plus action-sheet-icon"></i>
          </ListItem>
        </List>
      </Block>
    </div>
  </Sheet>
  <Sheet backdrop swipeToClose class="onboarding-modal-add-recipe" style="
    height: auto; 
    border-radius: 16px 16px 0 0;">
    <div class="sheet-close-button">
      <Link sheetClose style="">
        <i class="fa fa-xmark navbar-icon"></i>
      </Link>
    </div>
    <swiper-container pagination={true} class="onboarding-modal-swiper" space-between={50}>
      <swiper-slide>
        <div style="
          position: fixed;
          top: -50px;
          left: 0;
          height: 100vh;
          width: 100vw;
          display: flex;
          justify-content: center;
          overflow: hidden;
          pointer-events: none;">
          <Confetti x={[-5, 5]} y={[0, 0.1]} delay={[500, 2000]} infinite duration=5000 amount=200 fallDistance="100vh" />
        </div>
        <Block style="margin-top: 40px;">
          <div class="onboarding-modal-media">
            🎉
          </div>
          <div class="onboarding-modal-title">
            Congratulations on Adding Your First Meal!
          </div>
          <div class="onboarding-modal-text">
            You're one step closer to a personalized meal plan.<br><br>
          </div>
          <div style="text-align: -webkit-center;">
            <Button large round fill on:click={() => (f7.swiper.get('.onboarding-modal-swiper').slideNext())}>Continue</Button>
          </div>
        </Block>
      </swiper-slide>
      <swiper-slide>
        <Block style="margin-top: 40px;">
          <div class="onboarding-modal-media">
            🌳
          </div>
          <div class="onboarding-modal-title">
            Join Our Sustainable Meal Planning Journey
          </div>
          <div class="onboarding-modal-text">
            We're committed to helping you make environmentally-friendly choices.
          </div>
          <div style="text-align: -webkit-center;">
            <Button large round fill on:click={() => (f7.swiper.get('.onboarding-modal-swiper').slideNext())}>Continue</Button>
          </div>
        </Block>
      </swiper-slide>
      <swiper-slide>
        <Block style="margin-top: 40px;">
          <div class="onboarding-modal-media">
            💚
          </div>
          <div class="onboarding-modal-title">
            Introducing Your Sustainability Score
          </div>
          <div class="onboarding-modal-text">
            Earn points for choosing sustainable meals and making a difference.
          </div>
          <div style="text-align: -webkit-center;">
            <Button large round fill sheetClose>Awesome!</Button>
          </div>
        </Block>
      </swiper-slide>
    </swiper-container>
  </Sheet>
  <Sheet backdrop swipeToClose class="action-sheet-recipe" style="
    height: auto; 
    border-radius: 16px 16px 0 0;">
    <div class="action-sheet-container">
      <div class="sheet-close-button">
        <Link sheetClose style="">
          <i class="fa fa-xmark navbar-icon"></i>
        </Link>
      </div>
      <Block style="margin-top: 40px;">
        <List>
          <ListItem title="Change day" class="sheet-close" on:click={() => (f7.sheet.open('#move-item-date-selector'))}>
            <i slot="media" class="fa-solid fa-arrow-right action-sheet-icon"></i>
          </ListItem>
          <ListItem title="Remove from meal plan" class="sheet-close sheet-delete" on:click={onClickDeleteRecipe}>
            <i slot="media" class="fas fa-trash action-sheet-icon"></i>
          </ListItem>
        </List>
      </Block>
    </div>
  </Sheet>
  <DateSelectSheetModal id="move-item-date-selector" callbackOnSave={handleMoveRecipe}/>
  <Popup push swipeToClose class="smart-actions-popup">
    <div class="popup-close-button">
      <a class="link popup-close" href="#" style=""> 
          <i class="fa fa-xmark navbar-icon"></i> 
      </a>
    </div>
    <Page>
      <Navbar transparent sliding={true} class="no-outline">
        <NavTitle sliding>Smart Actions</NavTitle>
      </Navbar>
      <div class="zesty-ai-icon" style="background: var(--f7-theme-color);">
        <i class="fa fa-leaf" style="font-size: 24px;"></i>
      </div>
      <div class="page-title">
        Smart Actions
        <!--
        <div style="float: right;">
          <Link iconIos="f7:ellipsis_circle" iconMd="material:more_vert" popoverOpen=".popover-menu"/>
        </div>
        -->
      </div>
      <table class="smart-actions-container">
        <tr>
          <td class="smart-action">
            <div class="square">
              <div class="action-content">
                <div class="action-icon">
                  <i class="fa fa-lemon"/>
                </div>
                <div class="action-details">
                  <div class="action-title">Zest up my day</div>
                  <div class="action-description">The “Easy button” for generating a day full of meals!</div>
                </div>
              </div>
            </div>
          </td>
          <td class="smart-action">
            <div class="square">
              <div class="action-content">
                <div class="action-icon">
                  <i class="fa fa-trash"/>
                </div>
                <div class="action-details">
                  <div class="action-title">Reduce waste</div>
                  <div class="action-description">Optimizes your recipes to use leftovers across meals</div>
                </div>
              </div>
            </div>
          </td>
        </tr>
        <tr>
          <td class="smart-action">
            <div class="square">
              <div class="action-content">
                <div class="action-icon">
                  <i class="fa fa-scale-balanced"/>
                </div>
                <div class="action-details">
                  <div class="action-title">Balance Plan</div>
                  <div class="action-description">Analyzes meal plan to ensure it meets your nutrition goals</div>
                </div>
              </div>
            </div>
          </td>
          <td class="smart-action">
            <div class="square">
              <div class="action-content">
                <div class="action-icon">
                  <i class="fa fa-chart-simple"/>
                </div>
                <div class="action-details">
                  <div class="action-title">Diet analysis</div>
                  <div class="action-description">Get feedback on your recent meal plans</div>
                </div>
              </div>
            </div>
          </td>
        </tr>
        <tr>
          <td class="smart-action">
            <div class="square">
              <div class="action-content">
                <div class="action-icon">
                  <i class="fa fa-earth-americas"/>
                </div>
                <div class="action-details">
                  <div class="action-title">Taste Explorer</div>
                  <div class="action-description">Discover new and exciting meals</div>
                </div>
              </div>
            </div>
          </td>
          <td class="smart-action">
            <div class="square">
              <div class="action-content">
                <div class="action-icon">
                  <i class="fa fa-seedling"/>
                </div>
                <div class="action-details">
                  <div class="action-title">Reduce emissions</div>
                  <div class="action-description">Reduce your meal plan's CO2 emissions</div>
                </div>
              </div>
            </div>
          </td>
        </tr>
        <!-- Add as many rows and columns as needed -->
      </table>
    </Page>
  </Popup>
</Page>
<RecipeEditor />
{#if currentWeekRecipes.length == 0}
  <div class="fab fab-extended fab-right-bottom" style="text-align: -webkit-right; pointer-events: none;">
    <!-- svelte-ignore a11y-missing-attribute -->
    <!-- svelte-ignore a11y-click-events-have-key-events -->
    <!-- 
    <a on:click={onClickSmartActionsButton} class="ai-suggestion-fab {$featureTogglesStore.includes('DisableAIFAB') ? 'hidden' : ''}">
      <i class="fa fa-leaf" style="font-size: 24px;"></i>
    </a>
    -->
    <!-- svelte-ignore a11y-missing-attribute -->
    <!-- svelte-ignore a11y-click-events-have-key-events -->
    <a on:click={onClickAddButton} class="fab-plus">
      <i class="icon f7-icons">plus</i>
      <div class="fab-text">Start Planning</div>
    </a>
  </div>
{:else}
  <div class="fab fab-right-bottom" style="text-align: -webkit-right; pointer-events: none;">
    <!-- svelte-ignore a11y-missing-attribute -->
    <!-- svelte-ignore a11y-click-events-have-key-events -->
    <!-- 
    <a on:click={onClickSmartActionsButton} class="ai-suggestion-fab {$featureTogglesStore.includes('DisableAIFAB') ? 'hidden' : ''}">
      <i class="fa fa-leaf" style="font-size: 24px;"></i>
    </a>
    -->
    <!-- svelte-ignore a11y-missing-attribute -->
    <!-- svelte-ignore a11y-click-events-have-key-events -->
    <a on:click={onClickAddButton} class="fab-plus">
      <i class="icon f7-icons">plus</i>
    </a>
  </div>
{/if}
<script>
  import {
    f7,
    Page,
    Navbar,
    NavTitle,
    Link,
    List,
    ListItem,
    Icon,
    Popover,
    Sheet,
    Block,
    Button,
    Popup,
    f7ready,
    AccordionContent,
    NavLeft,
    NavRight,
    NavTitleLarge,
    Searchbar,
    Subnavbar,
    Panel,
    BlockTitle
  } from 'framework7-svelte';
  import { onMount } from 'svelte';

  import {
    updateMealPlan,
    currentMealPlanStartDate,
    getPlansForUser,
    daysOfTheWeek,
    addPlanRecipeToMealPlan,
    removePlanRecipeFromPlan,
    plan,
    clearRecipesForWeek,
    plans,
    memberPlans,
    selectedRecipe,
    getUserRecipes,
    planRecipes,
    dayToDateMap,
    userRecipes,
    updateRecipeDateAndOrder,
    fetchUserPlan,
    getRecipeDetailsFromId
  } from "../stores/mealPlan";
  import {
    getWeekRange
  } from '../js/mealPlanHelpers';
  import ZestyGpt from '../components/ZestyGPT.svelte';
  import { generateMealPlan } from "../js/gptHelpers"
  import RecipeEditor from '../components/RecipeEditor.svelte';
  import RecipeImport from '../components/RecipeImport.svelte';
  import QuickAdd from '../components/QuickAdd.svelte';
  import { featureTogglesStore } from "../stores/featureToggles"
  import { Confetti } from "svelte-confetti"
  import { UsersIcon } from 'svelte-feather-icons'
  import DateSelectSheetModal from '../components/DateSelectSheetModal.svelte';
  import { convertDayToDateInWeek, getNextWeek, getPreviousWeek, removeTimezone, showToast } from '../js/generalHelpers';
  import WeekSelector from '../components/WeekSelector.svelte';
  import { writable } from 'svelte/store';
  import '../css/home.css';
  import Sortable from 'sortablejs';
  import { tick } from 'svelte';
  import { supabase } from '../js/supabase';
  import NavBarButtons from '../components/NavBarButtons.svelte';

  function pageView() {
      if(window.TapticEngine){
          window.TapticEngine.impact({
              style: "light" // light | medium | heavy | rigid (iOS 13+) | soft (iOS 13+)
          });
      }
  }


  let aiModificationInput = "";
  let planToChangeTo;
  let sortableItems = {
    "Monday": undefined,
    "Tuesday": undefined,
    "Wednesday": undefined,
    "Thursday": undefined,
    "Friday": undefined,
    "Saturday": undefined,
    "Sunday": undefined
  };



  let planNotices = [];
  async function getPlanNotices(){
    // get plan notices from supabase
    const { data, error } = await supabase
      .from('notices_v1')
      .select('*')
      .eq('active', true)
      .order('created_at', { ascending: false })

    // Handle if error
    if (error) {
      showToast('Error fetching notices.')
      throw new Error(error)
    }

    // Get 3 random notices to show to the user
    planNotices = [...data].sort(() => 0.5 - Math.random()).slice(0, 3);
  }

  let currentWeekRecipes = [];
  $:onPlanRecipeChange($planRecipes)
  function onPlanRecipeChange(value) {
    if($planRecipes){
      currentWeekRecipes = $planRecipes.filter(planRecipe => {
        const planRecipeDate = new Date(new Date(planRecipe.date).toISOString().slice(0, -1));

        let weekStart = new Date($currentMealPlanStartDate.getFullYear(), $currentMealPlanStartDate.getMonth(), $currentMealPlanStartDate.getDate());

        const weekEndDate = getWeekRange(weekStart).weekEnd;
        const weekEnd = new Date(weekEndDate.getFullYear(), weekEndDate.getMonth(), weekEndDate.getDate(), 23, 59, 59);

        return planRecipeDate.getTime() >= weekStart.getTime() && planRecipeDate.getTime() <= weekEnd.getTime()
      })
    }
  }

  async function refreshUserPlan() {
    let currentStartDate;
    currentMealPlanStartDate.subscribe(value=>{
        currentStartDate = value;
    })();
    mealPlanLoading = true
    await fetchUserPlan(currentStartDate);
    mealPlanLoading = false
    createSortables();
  }

  function changePlannerName(){
    f7.dialog.prompt('New plan name', function (name) {
      updateMealPlan({ name }).then(() => {
        // Set the new plan name against the local writable store
        let currentPlanData;
        plan.subscribe(value=>{
          currentPlanData = value;
        })();

        currentPlanData.name = name;

        plan.set(currentPlanData)
      })
    });
  }

  function onClickAddButton() {
    let currentStartDate;
    currentMealPlanStartDate.subscribe(value=>{
      currentStartDate = value;
    })();

    localStorage.setItem('selected_week_start', getWeekRange(currentStartDate).weekStart.toString());
    localStorage.setItem('selected_week_end', getWeekRange(currentStartDate).weekEnd.toString());
    f7.popup.open('.action-sheet');
  }

  function onClickSmartActionsButton() {
    localStorage.setItem('selected_week_start', getWeekRange().weekStart.toString());
    localStorage.setItem('selected_week_end', getWeekRange().weekEnd.toString());
    f7.popup.open('.smart-actions-popup');
  }

  function onClickFindRecipe(){
    f7.tab.show('#view-recipes')
    f7.$('.zesty-search').find('input').focus()
  }

  let mealPlanLoading = true;
  let selectedItem;

  async function handleMoveRecipe(moveRecipeSelectedDays){
    let currentStartDate;
    currentMealPlanStartDate.subscribe(value=>{
        currentStartDate = value;
    })();

    if (currentStartDate) {
      let index = 0;
      for(const moveRecipeSelectedDay of moveRecipeSelectedDays){
        if(!daysOfTheWeek.includes(moveRecipeSelectedDay)){
            continue // Break one iteration if this meal plan day is not a valid day of the week
        }

        let planRecipeDate = convertDayToDateInWeek(moveRecipeSelectedDay, currentStartDate);

        // Update the first one and make new entries for later, since updating would just keep overwriting
        if (index === 0) {
          await updateRecipeDateAndOrder(selectedItem, planRecipeDate, 0);
        } else {
          await addPlanRecipeToMealPlan({
            day : moveRecipeSelectedDay,
            planRecipe : selectedItem,
            planRecipeDate
          });
        }
        index++;
      }
    }
  }

  function onClickDeleteRecipe(){
    f7.dialog.confirm(`Remove ${selectedItem.recipe_name} from your meal plan?`, function () {
      // This will auto update using pub/sub
      removePlanRecipeFromPlan(selectedItem);
    });
  }

  function handleRecipeMoreButton(event, item){
    event.stopPropagation();
    selectedItem = item;
    f7.popup.open('.action-sheet-recipe');
  }

  function getRecipeIdFromItem(item) {
    
    if (item["recipe_type"] === "internal") {
      return item.recipe_id;
    } 

    const recipeUriId = item['recipe_uri'].split('#')[1];

    if (recipeUriId) {
      return recipeUriId.split('recipe_')[1];
    }

    return null;
  }

  async function loadUserRecipes(){
    const newRecipes = await getUserRecipes();
    userRecipes.set(newRecipes);
  }

  async function onClickRecipeItem(planRecipeItem) {
    const recipeId = getRecipeIdFromItem(planRecipeItem);

    if (recipeId) {
      f7.views.current.router.navigate(`/recipe/`, {
        props: {
          addToMealPlan: true,
          reloadRecipes: loadUserRecipes
        }
      });

      let recipeData = await getRecipeDetailsFromId({
        recipeId,
        isExternal : planRecipeItem.recipe_type === "external" ? true : false,
        planRecipeDate : planRecipeItem.date, 
        planRecipeId : planRecipeItem.id
      })

      selectedRecipe.set(recipeData);
    }
  }

  function onClickPreviousWeek() {
    const { startDate, endDate } = getPreviousWeek(currentMealPlanStartDate);

    mealPlanLoading = true

    getPlansForUser(startDate, endDate)
    .then(data => {
      const { plans: userPlans, plan: userPlan} = data;
      mealPlanLoading = false
      let currentUserPlan;
      plan.subscribe(value => {
        currentUserPlan = value;
      });

      if (currentUserPlan) {
        const updatedPlan = userPlans.find(userPlan => userPlan.id === currentUserPlan.id);

        if (updatedPlan !== undefined) {
          plan.set(updatedPlan);
        }
      }
      else {
        plan.set(userPlan);
      }

      createSortables();
    });
  }

  function onClickNextWeek() {
    const { startDate, endDate } = getNextWeek(currentMealPlanStartDate);
    mealPlanLoading = true
    getPlansForUser(startDate, endDate)
    .then(data => {
      const { plans: userPlans, plan: userPlan } = data;
      mealPlanLoading = false
      let currentUserPlan;
      plan.subscribe(value => {
        currentUserPlan = value;
      });

      if (currentUserPlan) {
        const updatedPlan = userPlans.find(userPlan => userPlan.id === currentUserPlan.id);

        if (updatedPlan !== undefined) {
          plan.set(updatedPlan);
        }
      }
      else {
        plan.set(userPlan);
      }

      plan.subscribe(value => {
        currentUserPlan = value;
      });

      createSortables();
    });
  }

  async function changePlan(newPlan) {
    let currentStartDate;
    currentMealPlanStartDate.subscribe(value=>{
        currentStartDate = value;
    })();
    
    const data = await getPlansForUser(currentStartDate)
    const { plans: userPlans } = data;
    const updatedPlan = userPlans.find(userPlan => userPlan.id === newPlan.id);
    plan.set(updatedPlan);
    localStorage.setItem('selected_plan_id', updatedPlan.id);

    mealPlanLoading = true
    await fetchUserPlan(currentStartDate);
    mealPlanLoading = false
    createSortables();
  }

  function onClickCreateRecipe(){
    selectedRecipe.set(null);
    f7.popup.open('.recipe-editor');
  }
    

  function handleModalButtonClick() {
    f7.swiper.get('.onboarding-modal-swiper').slideNext()
  }

  async function clearWeek() {
    const didClear = await clearRecipesForWeek($currentMealPlanStartDate);
    if (!didClear) {
        f7.dialog.alert("There was an error clearing your plan");
        return;
    }
    showToast('Plan successfully cleared');
  }

  function duplicateWeek() {
    f7.views.current.router.navigate('/planner-duplicate/');
  }

  function onDragRecipeStart(event){
    if(window.TapticEngine){
      window.TapticEngine.impact({
        style: "heavy" // light | medium | heavy | rigid (iOS 13+) | soft (iOS 13+)
      });
    }
  }

  async function onDragRecipeEnd(event){
    f7.$(event.item).css('transform', '')
    const planRecipeId = event.item.getAttribute('planRecipeId');
    const newDay = event.to.getAttribute('id');
    const recipeNewOrder = event.newDraggableIndex + 1;

    selectedItem = $planRecipes.find(planRecipe => planRecipe.id === planRecipeId);

    let currentStartDate;
    currentMealPlanStartDate.subscribe(value=>{
        currentStartDate = value;
    })();

    if (currentStartDate) {
      const planRecipeDate = convertDayToDateInWeek(newDay, currentStartDate);

      await updateRecipeDateAndOrder(selectedItem, planRecipeDate, recipeNewOrder);
    }
  }

  function createSortable(dayOfTheWeek) {
    Sortable.create(sortableItems[dayOfTheWeek], {
        group: {
          name: dayOfTheWeek,
          put: true
        },
        animation: 200,
        delay: 300,
        delayOnTouchOnly: true,
        onChoose: onDragRecipeStart,
        onEnd: onDragRecipeEnd
      });
  }

  function createSortables(){
    for (const dayOfTheWeek of daysOfTheWeek) {
      createSortable(dayOfTheWeek);
    }
  }

  onMount(async () => {
    f7ready(async () => {
      let currentStartDate;
      currentMealPlanStartDate.subscribe(value=>{
          currentStartDate = value;
      })();
      mealPlanLoading = true
      await fetchUserPlan(currentStartDate);
      mealPlanLoading = false
      createSortables();

      getPlanNotices();
    });

    const handlePlanRecipeUpdate = () => {
      refreshUserPlan();
    };

    document.addEventListener('planRecipeUpdate', handlePlanRecipeUpdate);
  })
</script>