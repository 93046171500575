<Page name="discover" on:pageTabShow={initDiscoveryContent}>
    <!-- Top Navbar -->
    <Navbar transparent sliding={false}>
      <NavTitle sliding>Discover</NavTitle>
    </Navbar>
    <div class="page-title">
      Discover
      <!-- 
      <div style="font-size: 20px; font-weight: 500;">
        <Link popoverOpen=".location-selector">
          Ontario <i class="fa fa-chevron-down" style="font-size: 18px;"></i>
        </Link>
      </div>
      -->
      
      <!-- 
      <div style="float: right;">
        <Link iconIos="f7:ellipsis_xcircle" iconMd="material:more_vert" popoverOpen=".popover-menu"/>
      </div>
      -->
    </div>
    {#each discoveryContent as section}
      <BlockTitle style="margin-top: 15px;">{section.title}</BlockTitle>
      <div class="swiper-container no-scroll-bar">
      {#each section.discovery_v1_items as item}
        {#if item.item_type == "banner_image"}      
            <div class="swiper-slide swiper-slide-banner-image">
              <!-- svelte-ignore a11y-invalid-attribute -->
              <a class="external-link" href="#" on:click={() => { clickLink(item.target_url)}}>
                <!-- svelte-ignore a11y-missing-attribute -->
                <img src="{item.image_url}">
              </a>
            </div>   
        {:else if item.item_type == "image_circle_small"}
          <div class="swiper-slide swiper-slide-circle-small">
            <!-- svelte-ignore a11y-invalid-attribute -->
            <a class="external-link" href="#" on:click={() => { clickLink(item.target_url)}}>
              <!-- svelte-ignore a11y-missing-attribute -->
              <img src="{item.image_url}">
            </a>
          </div>  
        {:else if item.item_type == "news_article"}
          <div class="swiper-slide swiper-slide-news-article">
            <!-- svelte-ignore a11y-invalid-attribute -->
            <a class="external-link" href="#" on:click={() => { clickLink(item.target_url)}}>
              <div class="article-image" style="background-image: url({item.image_url});"></div>
              <div class="article-title">{item.item_metadata.title}</div>
              <div class="article-subtitle">{item.item_metadata.subtitle}</div>
            </a>
          </div>  
        {/if}
      {/each}
      </div>  
    {/each}

    <Popover class="location-selector">
      <List>
        <ListItem popoverClose title="More locations coming soon!"/>
      </List>
    </Popover>
  </Page>
  <script>
    import {
      f7,
      Page,
      Navbar,
      List,
      ListInput,
      ListItem,
      Toggle,
      BlockTitle,
      Button,
      Range,
      Block,
      NavTitle,
      NavRight,
      Link,
      NavTitleLarge,
      AccordionContent,
      Popover
    } from 'framework7-svelte';
    import { supabase } from "../js/supabase";
    import '../css/discovery.css';

    const TARGET = process.env.TARGET || "web"; // Default to Web
    let discoveryContent = []

    async function getDiscoverySectionsAndContent(){
      let thisData = await supabase
        .from('discovery_v1_sections')
        .select(`
          *,
          discovery_v1_items(*)
        `)
        .order('order', { ascending: true })
        .order('order', { foreignTable : 'discovery_v1_items', ascending: true })

        return thisData.data
    }

    async function initDiscoveryContent(){
      if(window.TapticEngine){
        window.TapticEngine.impact({
          style: "light" // light | medium | heavy | rigid (iOS 13+) | soft (iOS 13+)
        });
      }

      if(discoveryContent.length == 0){
        discoveryContent = await getDiscoverySectionsAndContent();
      }
    }

    function clickLink(target_url) {
      if(TARGET.toLocaleLowerCase() === 'cordova') {
        cordova.InAppBrowser.open(target_url, '_system'); // Open app in System's default browser rather than in app
      } else {
        window.open(target_url , '_blank');
      }
    }

  </script>

  <style>
    .swiper-container {
      display: flex;
      overflow-x: auto;
      padding: 10px;
    }

    .swiper-slide {
        flex: 0 0 auto;
        margin-right: 20px;
        background-color: #f5f5f5;
        border-radius: 8px;
        box-sizing: border-box;
        display: flex;
        overflow: hidden;
    }

    .swiper-slide img {
      width: 100%;
      display: block;
    }

    .swiper-slide a {
      width: 100%;
    }

    .swiper-slide-banner-image {
      /* height: 160px; */
    }

    .swiper-slide-circle-small {
      width: 100px;
      height: 100px;
      border-radius: 50%;
    }

    .swiper-slide-news-article {
      width: 80%;
      background-color: transparent; 
      border-style: solid;
      padding: 8px;
      border-color: #e2e2e2;
      border-width: 1px;
      height: auto;
    }

    .swiper-slide-news-article div {
      color: var(--f7-block-title-text-color) !important;
    }

    .article-title {
      font-weight: 700;
      margin-top: 10px;
      margin-bottom: 5px;
      padding-left: 5px;
      padding-right: 5px;
    }

    .article-subtitle {
      padding-left: 5px;
      padding-right: 5px;
    }

    .article-image {
      height: 100px;
      width: 100%;
      background-position: center;
      background-size: cover;
      border-radius: 8px;
    }

    /* Remove margin for the last slide */
    .swiper-slide:last-child {
        margin-right: 0;
    }

    .slide-content {
      background: red;
      width: 60%;
      padding: 15px;
    }

    .slide-media {
      background: blue;
      width: 40%;
      background-position: center;
      background-size: cover;
    }

    .slide-main-text {
      font-size: 19px;
      font-weight: 700;
      margin-bottom: 5px;
    }

    .slide-sub-copy {
      font-size: 12px;
      margin-bottom: 10px;
    }

    .no-scroll-bar::-webkit-scrollbar {
      display: none; /* Chrome, Safari and Opera */
    }

  </style>
  