import { writable } from 'svelte/store';
import { supabase } from '../js/supabase';
import * as Sentry from "@sentry/svelte";

const LOCAL_DATA_STORE_KEY = 'userData';

const defaultUserData = {
    first_name : null,
    last_name : null,
    email : null,
    avatar_url: null
} 

export const userData = JSON.parse(localStorage.getItem(LOCAL_DATA_STORE_KEY)) || defaultUserData;
export const userDataStore= writable(userData);

export const getUserData = async function(){
    let currentValue;
    userDataStore.subscribe(value=>{
        currentValue = value;
    })();

    if(!currentValue.id){
        const {data, error} = await supabase.auth.getUser();

        if(error){
            return
        }

        // const { data: { user } } = await supabase.auth.getUser();

        updateUserData({
            data : data.user
        })

        userDataStore.subscribe(value=>{
            currentValue = value;
        })();
    }

    return currentValue;
}

export const getUserFirstLastName = function(currentProfileData){
    if(currentProfileData && currentProfileData.first_name){
        return {
            firstName: currentProfileData.first_name,
            lastName: currentProfileData.last_name ? currentProfileData.last_name : null
        };
    } else if(currentProfileData && currentProfileData.user_metadata && currentProfileData.user_metadata.first_name){
        return {
            firstName: currentProfileData.user_metadata.first_name,
            lastName: currentProfileData.user_metadata.last_name ? currentProfileData.user_metadata.last_name : null
        };
    } else if(currentProfileData && currentProfileData.user_metadata && currentProfileData.user_metadata.full_name){
        const names = currentProfileData.user_metadata && currentProfileData.user_metadata.full_name.trim().split(" ");
        const firstName = names[0];
        const lastName = names.slice(1) ? names.slice(1).join(" ") : null;

        return {
            firstName,
            lastName
        };
    } else if(currentProfileData && currentProfileData.user_metadata && currentProfileData.user_metadata.name){
        const names = currentProfileData.user_metadata.name.trim().split(" ");
        const firstName = names[0];
        const lastName = names.slice(1) ? names.slice(1).join(" ") : null;

        return {
            firstName,
            lastName
        };
    } else {
        return {
            firstName: null,
            lastName : null
        };
    }
}

export const updateUserData = function ({data} = {}){
    let currentProfileData;
    userDataStore.subscribe(value=>{
        currentProfileData = value;
    })();

    const updatedValue = {...currentProfileData, ...data}

    userDataStore.set(updatedValue)

    localStorage.setItem(LOCAL_DATA_STORE_KEY, JSON.stringify(updatedValue))

    Sentry.configureScope(scope => {
        scope.setUser({
          id: updatedValue.id,
          email: updatedValue.email,
          first_name : updatedValue.first_name,
          last_name : updatedValue.last_name
        });
    });
}

export const hasMilestone = async function(milestoneName){
    const currentUserData = await getUserData();

    if(!currentUserData.milestones){
        throw new Error('User milestones do not exist');
    }

    if(currentUserData.milestones.length == 0){
        return false;
    }

    for(let userMilestone of currentUserData.milestones){
        if(userMilestone.milestone_name == milestoneName){
            return true
        }
    }

    return false
}

export const setMilestone = async function(milestoneName){
    const currentUserData = await getUserData();

    if(!currentUserData.milestones){
        throw new Error('User milestones do not exist');
    }

    let userHasMilestone = await hasMilestone(milestoneName);
    if(!userHasMilestone){
        const { data, error } = await supabase
        .from('milestones')
        .insert({
            milestone_name : milestoneName
        })
        .select();

        // Update the currently stored user data with the new milestone info
        currentUserData.milestones = data;
        userDataStore.set(currentUserData);

        if(error) {
            throw new Error(JSON.stringify(error));
        }
    }
}

export default userDataStore;