<script>
    import {
        Block,
        Popup,
        View,
        Page,
        f7,
        f7ready,
        Navbar,
        Messages,
        MessagesTitle,
        Message,
        Messagebar,
        Icon,
        MessagebarAttachments,
        MessagebarAttachment,
        MessagebarSheet,
        MessagebarSheetImage,
        Card,
        CardContent,
        CardFooter,
        List,
        ListItem,
        NavRight,
        NavLeft,
        Link,
    } from "framework7-svelte";
    import { onMount, onDestroy } from "svelte";
    import ZestyplanAssistantInput from "../components/ZestyplanAssistantInput.svelte";
    import { supabase } from "../js/supabase";
    import { selectedRecipe } from '../stores/mealPlan';
    import { tick } from 'svelte';

    export let initialMessage;
    export let recipeContext;

    let zestyplanTyping = false;

    onMount(() => {
        console.log("mounted");
        f7ready(() => {
            if (initialMessage) {
                sendMessage(initialMessage, {recipeContext});
            }
        });
    });

    function isFirstMessage(message, index) {
        const previousMessage = messagesData[index - 1];
        if (message.isTitle) return false;
        if (
            !previousMessage ||
            previousMessage.type !== message.type ||
            previousMessage.name !== message.name
        )
            return true;
        return false;
    }
    function isLastMessage(message, index) {
        const nextMessage = messagesData[index + 1];
        if (message.isTitle) return false;
        if (
            !nextMessage ||
            nextMessage.type !== message.type ||
            nextMessage.name !== message.name
        )
            return true;
        return false;
    }
    function isTailMessage(message, index) {
        const nextMessage = messagesData[index + 1];
        if (message.isTitle) return false;
        if (
            !nextMessage ||
            nextMessage.type !== message.type ||
            nextMessage.name !== message.name
        )
            return true;
        return false;
    }

    function convertMessagesToOpenAIMessageFormat(frontendMessages) {
        return {
            messages: frontendMessages.map(message => {
                // Append extraMetadata if it exists in sent messages
                let content = message.text;
                if (message.type === "sent" && message.extraMetadata) {
                    content += `<!--metadata: ${JSON.stringify(message.extraMetadata)}-->`;
                }
                return {
                    role: message.type === "sent" ? "user" : "assistant",
                    content: content
                };
            })
        };
    }


    function convertOpenAIMessageToMessagesFormat(openAIMessages) {
        return openAIMessages.map(message => {
            if (message.role === "user") {
                // Extract extraMetadata from content if present
                let text = message.content;
                let extraMetadata;
                const metadataIndex = message.content.indexOf('<!--metadata:');
                if (metadataIndex !== -1) {
                    text = message.content.substring(0, metadataIndex);
                    const metadataString = message.content.substring(metadataIndex + 13, message.content.length - 3);
                    try {
                        extraMetadata = JSON.parse(metadataString);
                    } catch (error) {
                        console.error("Error parsing metadata:", error);
                    }
                }
                return {
                    type: "sent",
                    text: text,
                    ...(extraMetadata && {extraMetadata: extraMetadata}) // Add extraMetadata if parsed
                };
            } else {
                return {
                    name: "Zestyplan Assistant",
                    type: "received",
                    text: message.content,
                    avatar: "https://i0.wp.com/zestyplan.com/wp-content/uploads/2022/10/cropped-original-logo-symbol.png?fit=192%2C192&ssl=1"
                };
            }
        });
    }


    let messageExamples = [
        {
            type: "sent",
            text: "Hi, Kate",
        },
        {
            name: "Kate",
            type: "received",
            text: "Hi, I am good!",
            avatar: "https://cdn.framework7.io/placeholder/people-100x100-9.jpg",
        },
        {
            type: "sent",
            image: "https://cdn.framework7.io/placeholder/cats-200x260-4.jpg",
        },
        {
            name: "Kate",
            type: "received",
            text: "Nice!",
            avatar: "https://cdn.framework7.io/placeholder/people-100x100-9.jpg",
        },
        {
            name: "Kate",
            type: "received",
            text: "Like it very much!",
            avatar: "https://cdn.framework7.io/placeholder/people-100x100-9.jpg",
        },
    ];

    let messagesData = [];

    function deepMergeWithId(target, updates) {
        Object.keys(updates).forEach(key => {
            if (updates[key] === null) {
                // Delete the key from the target if the update value is explicitly null
                delete target[key];
            } else if (Array.isArray(updates[key])) {
                // Special handling for arrays where items should have an 'id'
                if (!target[key]) {
                    target[key] = [];  // Initialize the array if it doesn't exist
                }

                // Prepare a map of updates for easier access
                const updatesMap = new Map(updates[key].map(item => [item.id, item]));

                // Filter and update the target array
                target[key] = target[key].filter(item => {
                    if (!updatesMap.has(item.id)) {
                        return true;  // Keep items that are not mentioned in the updates
                    }
                    const updateItem = updatesMap.get(item.id);
                    if (Object.keys(updateItem).length === 1) {
                        // If update item only has 'id', remove this item
                        return false;
                    } else {
                        // Else, update the item properties
                        Object.assign(item, updateItem);
                        return true;
                    }
                });

                // Add new items that are not in the target
                updates[key].forEach(updateItem => {
                    if (!target[key].some(item => item.id === updateItem.id)) {
                        if (Object.keys(updateItem).length > 1) {
                            target[key].push(updateItem);
                        }
                    }
                });
            } else if (typeof updates[key] === 'object' && updates[key] !== null) {
                // Recursively handle nested objects
                if (!target[key]) {
                    target[key] = {};  // Initialize the object if it doesn't exist
                }
                deepMergeWithId(target[key], updates[key]);
            } else {
                // Directly set the value for primitives and other properties
                target[key] = updates[key];
            }
        });

        return target;
    }


    function simpleMarkdownToHTML(markdown) {

        if (!markdown) return '';

        // Convert strong tags
        let html = markdown.replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>');

        // Convert markdown headers (### Header)
        html = html.replace(/^### (.*)$/gm, '<h3>$1</h3>');

        // Handle ordered and unordered lists
        let lines = html.split(/\n/);
        let result = [];
        let listBuffer = []; // Buffer to hold list items to check if we need <ul> or <ol>
        let listType = null; // 'ol' for ordered, 'ul' for unordered

        lines.forEach(line => {
            const orderedMatch = line.match(/^(\d+)\. (.*)$/);
            const unorderedMatch = line.match(/^[-*] (.*)$/);

            if (orderedMatch) {
                if (listType !== 'ol') {
                    if (listBuffer.length > 0) {
                        result.push(`<${listType}>${listBuffer.join('')}</${listType}>`);
                        listBuffer = [];
                    }
                    listType = 'ol';
                }
                listBuffer.push(`<li>${orderedMatch[2]}</li>`);
            } else if (unorderedMatch) {
                if (listType !== 'ul') {
                    if (listBuffer.length > 0) {
                        result.push(`<${listType}>${listBuffer.join('')}</${listType}>`);
                        listBuffer = [];
                    }
                    listType = 'ul';
                }
                listBuffer.push(`<li>${unorderedMatch[1]}</li>`);
            } else {
                if (listBuffer.length > 0) {
                    result.push(`<${listType}>${listBuffer.join('')}</${listType}>`);
                    listBuffer = [];
                    listType = null;
                }
                result.push(line);
            }
        });

        if (listBuffer.length > 0) {
            result.push(`<${listType}>${listBuffer.join('')}</${listType}>`);
        }

        // Convert newlines to <br> for remaining non-list content
        html = result.join('<br>').replace(/<br><(ul|ol)>/g, '<$1>');

        return html;
    }



    async function update_recipe(recipeChanges){
        // Parsed recipe data will contain only the recipe data that is changing from the original recipe
        let parsedRecipeChanges

        try {
            parsedRecipeChanges = JSON.parse(recipeChanges);
        } catch (error) {
            console.error("Error parsing recipe data:", error);
            f7.dialog.alert("Error parsing recipe data");
            return;
        }

        // Current recipe
        let currentRecipe;
        selectedRecipe.subscribe(value=>{
            currentRecipe = value;
        })();
   
        let newRecipe = applyRecipeChanges(currentRecipe, parsedRecipeChanges)

        selectedRecipe.set(newRecipe);
        await tick();

        f7.popup.close('.zestyplan-assistant-chat-window');
        f7.popup.close('.recipe-editor', false); // Close without animate
        await tick();
        f7.popup.open('.recipe-editor', false); // Open without animate

        f7.dialog.alert("Recipe updated successfully");
    }

    function applyRecipeChanges(recipe, changes) {
    // Apply modifications
    if (changes.modifications) {
        if (changes.modifications.name) {
            recipe.label = changes.modifications.name;
        }
        if (changes.modifications.description) {
            recipe.description = changes.modifications.description;
        }
        if (changes.modifications.image) {
            recipe.backgroundImage = changes.modifications.image;
        }
        if (changes.modifications.serving_size) {
            recipe.yield = changes.modifications.serving_size;
        }
        if (changes.modifications.prep_time) {
            recipe.prepTime = changes.modifications.prep_time;
        }
        if (changes.modifications.cook_time) {
            recipe.cookTime = changes.modifications.cook_time;
        }
        if (changes.modifications.ingredients) {
            changes.modifications.ingredients.forEach(modifiedIngredient => {
                const index = recipe.ingredients.findIndex(ingredient => ingredient.id === modifiedIngredient.id);
                if (index !== -1) {
                    Object.assign(recipe.ingredients[index], modifiedIngredient);
                }
            });
        }
        if (changes.modifications.instructions) {
            changes.modifications.instructions.forEach(modifiedStep => {
                const index = recipe.instructions.findIndex(step => step.id === modifiedStep.id);
                if (index !== -1) {
                    Object.assign(recipe.instructions[index], modifiedStep);
                }
            });
        }
    }

    // Apply removals
    if (changes.removals) {
        if (changes.removals.ingredients) {
            changes.removals.ingredients.forEach(ingredientId => {
                recipe.ingredients = recipe.ingredients.filter(ingredient => ingredient.id !== ingredientId);
            });
        }
        if (changes.removals.instructions) {
            changes.removals.instructions.forEach(stepId => {
                recipe.instructions = recipe.instructions.filter(step => step.id !== stepId);
            });
        }
    }

    // Apply additions
    if (changes.additions) {
        if (changes.additions.ingredients) {
            changes.additions.ingredients.forEach(newIngredient => {
                recipe.ingredients.push(newIngredient);
            });
        }
        if (changes.additions.instructions) {
            changes.additions.instructions.forEach(newStep => {
                recipe.instructions.push(newStep);
            });
        }
    }

    // Optionally, sort ingredients and steps by order if needed
    recipe.ingredients.sort((a, b) => a.order - b.order);
    recipe.instructions.sort((a, b) => a.order - b.order);

    return recipe;
}

    // Map the functions to string keys
    const functionMap = {
        update_recipe
    };

    // Function to call a function based on a passed string
    function callFunctionByName(functionName, ...args) {
        const func = functionMap[functionName];
        if (func) {
            func(...args);
        } else {
            console.log("Function does not exist.");
        }
    }

    async function sendMessage(messageText, extraMetadata = {}) {
        if (!messageText) {
            return;
        }

        const text = messageText.replace(/\n/g, "<br>").trim();
        const messagesToSend = [];
        /* Disabling attachments 
        attachments.forEach((attachment) => {
            messagesToSend.push({
                type: "sent",
                image: attachment,
            });
        });
        */

        if (text.length) {

            let message = {
                text,
                type: "sent",
            };

            if(extraMetadata.recipeContext){
                message.extraMetadata = {
                    recipeContext: extraMetadata.recipeContext
                }
            }

            messagesToSend.push(message);
        }

        if (messagesToSend.length === 0) {
            return;
        }

        // Reset attachments
        // attachments = [];
        // Hide sheet
        // sheetVisible = false;
        // Send message
        messagesData = [...messagesData, ...messagesToSend];
        let messagesInstance = Framework7ComponentsApp.f7.messages.get('#zesty-assistant-messages-instance');

        await tick();

        messagesInstance.addMessage({
            type : messagesData[messagesData.length - 1].type,
            image : messagesData[messagesData.length - 1].image,
            name : messagesData[messagesData.length - 1].name,
            avatar : messagesData[messagesData.length - 1].avatar,
            text : simpleMarkdownToHTML(messagesData[messagesData.length - 1].text),
        }, 'append', false)

        await tick();
        setTimeout(() => {
            messagesInstance.scroll();
        }, 100);

        /* potential UI glitch fix?
        await tick();
        // refresh view in case scroll glitch on iOS
        Framework7ComponentsApp.f7.$('.zestyplan-assistant-chat-window').css('display', 'none')
        await tick();
        Framework7ComponentsApp.f7.$('.zestyplan-assistant-chat-window').css('display', 'block')
        */


        let openAIConvertedMessages = convertMessagesToOpenAIMessageFormat(messagesData)

        messagesInstance.showTyping({
            type : "received", 
            header : 'Zestyplan assistant is typing',
            avatar : "https://i0.wp.com/zestyplan.com/wp-content/uploads/2022/10/cropped-original-logo-symbol.png?fit=192%2C192&ssl=1"
        })

        let response;
        try {
            response = await supabase.functions.invoke(`zestyplan-assistant-v2`, {
                body: openAIConvertedMessages
            });

            if(response.error){
                throw new Error(response.error)
            }
        } catch (error) {
            messagesInstance.hideTyping()
            f7.dialog.alert("Error sending message: " + error.message);
            throw new Error(error)
        }

        const result = response.data
        messagesInstance.hideTyping()

        if (result) {
            if (result[result.length - 1].tool_calls && result[result.length - 1].tool_calls.length > 0) {

                result[result.length - 1].tool_calls.forEach((tool_call) => {
                    callFunctionByName(tool_call.function.name, tool_call.function.arguments);
                });

            } 

            const messages = convertOpenAIMessageToMessagesFormat(result);
            messagesData = messages;
            
            await tick();

            messagesInstance.addMessage({
                type : messagesData[messagesData.length - 1].type,
                image : messagesData[messagesData.length - 1].image,
                name : messagesData[messagesData.length - 1].name,
                avatar : messagesData[messagesData.length - 1].avatar,
                text : simpleMarkdownToHTML(messagesData[messagesData.length - 1].text),
            }, 'append', false)

            await tick();
            setTimeout(() => {
                messagesInstance.scroll();
            }, 100);

            /*
            await tick();
            // refresh view in case scroll glitch on iOS
            Framework7ComponentsApp.f7.$('.zestyplan-assistant-chat-window').css('display', 'none')
            await tick();
            Framework7ComponentsApp.f7.$('.zestyplan-assistant-chat-window').css('display', 'block')
            */
        }
    }
</script>

<Popup push class="zestyplan-assistant-chat-window">
    <View>
        <Page name="zestyplan-assistant-chat">
            <Navbar 
                title="Zestyplan Assistant" 
                subtitle="Beta"
            >
                <Link slot="left" popupClose>End chat</Link>
            </Navbar>
            <ZestyplanAssistantInput
                onSendMessage={sendMessage}
            />
            <Messages animate={false} id="zesty-assistant-messages-instance">
                {#if recipeContext}
                <Card outline title="Edit recipe:">
                    <CardContent padding={false}>
                      <List mediaList>
                        <ListItem title={recipeContext.label} subtitle={recipeContext.description}>
                          <!-- svelte-ignore a11y-missing-attribute -->
                          <img
                            slot="media"
                            src={recipeContext.backgroundImage}
                            width="44"
                          />
                        </ListItem>
                      </List>
                    </CardContent>
                    <CardFooter>
                      <span>{recipeContext.ingredients ? recipeContext.ingredients.length : 0} ingredients</span>
                      <span>{recipeContext.instructions ? recipeContext.instructions.length : 0} steps</span>
                    </CardFooter>
                  </Card>
                {/if}
                <!-- 
                <MessagesTitle>Editing recipes using Zestyplan Assistant is currently in <b>Beta</b>.</MessagesTitle>
                -->
                <!-- 
                {#each messagesData as message, index (index)}
                    <Message
                        type={message.type}
                        image={message.image}
                        name={message.name}
                        avatar={message.avatar}
                        first={isFirstMessage(message, index)}
                        last={isLastMessage(message, index)}
                        tail={isTailMessage(message, index)}
                        htmlText={simpleMarkdownToHTML(message.text)}
                    />
                {/each}
                -->
                <!-- 
                {#if zestyplanTyping}
                  <Message
                    type="received"
                    typing={true}
                    first={true}
                    last={true}
                    tail={true}
                    header='Zestyplan assistant is typing'
                    avatar="https://i0.wp.com/zestyplan.com/wp-content/uploads/2022/10/cropped-original-logo-symbol.png?fit=192%2C192&ssl=1"
                  />
                {/if}
                -->
            </Messages>
        </Page>
    </View>
</Popup>

<style>
    :global(.zestyplan-assistant-chat-window .page-content) {
        padding-bottom: 100px !important;
    }

    :global(.zestyplan-assistant-chat-window .message-received ){
        max-width: 90%;
        margin-right: calc(10px + var(--f7-safe-area-right));
    }
</style>