<Sheet swipeToClose backdrop bottom class="collaborators" style="
    height: auto; 
    border-radius: 16px 16px 0 0;
">
    <div class="action-sheet-container">
        <div class="collaborator-sheet-title">Collaborators</div>
        <List style="margin: 0;">
            <ListInput outline type="select" value="{$plan && $plan.id ? $plan.id : null}" placeholder="Please choose..." on:change={(event) => {onChangePlanDropdown(event)}}>
                <div slot="media">
                    <i class="icon fa fa-list"/>
                </div>
                {#each $memberPlans as userPlan}
                    <option value="{userPlan.id}">{userPlan.name}</option>
                {/each}
              </ListInput>
        </List>
        
        <div class="sheet-close-button">
            <Link sheetClose>
                <i class="fa fa-xmark navbar-icon-1"></i>
            </Link>
        </div>
        <div class="collaborator-sheet-subtitle">Collaborators will be able to see and edit your meal plan and grocery list.</div>
        <Block>
            <List class="collaborator-list">
                {#if ($plan && $plan.members && planCollaboratorList.length > 0) || ($plan && $plan.pendingInvitations && $plan.pendingInvitations.length > 0) || ($plan && $plan.declinedInvitations && $plan.declinedInvitations.length > 0)}
                    {#each planCollaboratorList as collaborator}
                        
                            <ListItem>
                                <div class="collaborator-row">
                                    <div class="collaborator-row-container-icon-title-subtitle">
                                        <div class="collaborator-container-profile">
                                            <p class="collaborator-profile-icon-name">{collaborator.profiles['first_name'][0] ? collaborator.profiles['first_name'][0].toUpperCase() : ''}</p>
                                        </div>
                                        <div class="collaborator-row-container-title-subtitle">
                                            <p class="collaborator-row-title">{collaborator.profiles['first_name'] || ''} {collaborator.profiles['last_name'] || ''}</p>
                                            <p class="collaborator-row-subtitle">{collaborator.profiles['email'] || ''}</p>
                                        </div>
                                    </div>
                                    {#if $plan.isAdmin}
                                        <Link class="collaborator-row-delete-container" on:click={() => onClickRemoveCollaborator(collaborator)}>
                                            <Icon class="icon-trash" f7="trash"/> 
                                    </Link>
                                    {/if}
                                    
                                </div>
                            </ListItem>
                    {/each}
                    {#each  $plan.pendingInvitations as pendingInvitation}
                        <ListItem>
                            <div class="collaborator-row">
                                <div class="collaborator-row-container-icon-title-subtitle">
                                    <div class="invitation-container-icon">
                                        <Icon class="invitation-icon" f7="envelope"/>
                                    </div>
                                    <div class="collaborator-row-container-title-subtitle">
                                        <p class="collaborator-row-title">{pendingInvitation.email}</p>
                                        <p class="collaborator-row-subtitle">Invitation pending</p>

                                    </div>
                                </div>
                                {#if $plan.isAdmin}
                                    <Link class="collaborator-row-delete-container" on:click={() => onClickRemoveInvitation(pendingInvitation)}>
                                        <Icon class="icon-trash" f7="trash"/> 
                                  </Link>
                                {/if}
                            </div>
                        </ListItem>
                    {/each}
                    {#each  $plan.declinedInvitations as declinedInvitation}
                        <ListItem>
                            <div class="collaborator-row">
                                <div class="collaborator-row-container-icon-title-subtitle">
                                    <div class="invitation-declined-container-icon">
                                        <Icon class="invitation-declined-icon" f7="xmark"/>
                                    </div>
                                    <div class="collaborator-row-container-title-subtitle">
                                        <p class="collaborator-row-title">{declinedInvitation.email}</p>
                                        <p class="collaborator-row-subtitle">Invitation declined</p>
                                    </div>
                                </div>
                                {#if $plan.isAdmin}
                                    <Link class="collaborator-row-delete-container" on:click={() => onClickRemoveInvitation(declinedInvitation)}>
                                        <Icon class="icon-trash" f7="trash"/> 
                                  </Link>
                                {/if}
                            </div>
                        </ListItem>
                    {/each}
                {:else}
                    <div class="collaborator-sheet-list-empty-content">No collaborators yet</div>
                {/if}

            </List>
        </Block>
        {#if $plan && $plan.isAdmin}
            <div class="collaborator-sheet-footer-title">Add a collaborator by email</div>
            <div class="collaborator-sheet-footer-subtitle">They will receive a notification via email and in the Zestyplan app.</div>
            <Block>
                <List form>
                    <ListInput
                        name="email" 
                        outline 
                        label="Email address" 
                        floatingLabel 
                        type="text"
                        bind:value={collaboratorEmail}
                    />
                </List>
                <Button large fill class="{collaboratorEmail && collaboratorEmail !== '' ? '' : 'disabled'}" on:click={onClickAddCollaborator}>Add</Button>
            </Block>
        {:else if $plan && $plan.name}
            <div class="collaborator-sheet-footer-title">You are not an admin</div>
            <div class="collaborator-sheet-footer-subtitle">To add more collaborators, ask the admin of <b>{$plan.name}</b> to invite them.</div>
        {/if}
    </div>
</Sheet>
<script>
    import { Block, Button, Icon, Link, List, ListInput, ListItem, Sheet, f7 } from "framework7-svelte";
    import '../css/collaborators-sheet.css';
    import { currentMealPlanStartDate, getPlansForUser, inviteToPlan, memberPlans, adminPlans, plan, plans, removeInvitation, removeMemberFromPlan, changePlan } from "../stores/mealPlan";
    import { userData, getUserData } from "../stores/userInfo";
    import { id } from "framework7/shared/utils";

    let collaboratorEmail;
    let selectedPlanner;

    let planCollaboratorList = [];
    $:onPlanChange($plan)
    async function onPlanChange(value) {
        if(!$plan || !$plan.members){
            return;
        }

        // Gather the collaborators for this plan

        const currentUserData = await getUserData()

        // Reset planCollaboratorList
        planCollaboratorList = [];

        for(const collaborator of $plan.members){
            // If collaborator is not the current user, and has a first name...
            if(collaborator.profile_id !== currentUserData.id && collaborator.profiles['first_name']){
                let currentCollaborators = planCollaboratorList;
                currentCollaborators.push(collaborator);
                planCollaboratorList = currentCollaborators;
            }
        }
    }

    async function onClickAddCollaborator(){
        try {
            const response = await inviteToPlan(collaboratorEmail, $plan.id);

            if (response.error) {
                f7.dialog.alert(`An error has occured when sending the invitation to ${collaboratorEmail}. Please try again.  ${error.message ? error.message : ''}`);
            }
            else {
                f7.dialog.alert(`Collaborator ${collaboratorEmail} has been invited to your meal plan`, () => {
                    closeCollaboratorsPopup();
                });
            }
        }
        catch(error) {
            f7.dialog.alert(`There was an error adding ${collaboratorEmail} to your meal plan. ${error.message ? error.message : ''}`);
        }
        
    }

    async function onClickRemoveCollaborator(collaborator){
        const name = `${collaborator.profiles['first_name']} ${collaborator.profiles['last_name']}`;  
        f7.dialog.confirm(`Are you sure you want to remove ${name} from the plan?`, () => {
            removeMemberFromPlan(collaborator.id).then(() => {
                closeCollaboratorsPopup();
            })
        }, () => {});
    }

    async function onClickRemoveInvitation(invitation){
        f7.dialog.confirm(`Are you sure you want to remove the invitation for ${invitation.email} to the plan?`, () => {
            removeInvitation(invitation.id).then(() => {
                closeCollaboratorsPopup();
            })
        }, () => {});
    }

    function closeCollaboratorsPopup(){
        // TODO: WM: I have a feeling that this logic doesn't need to be run, especially if we are going to be 
        // using realtime data...
        collaboratorEmail = null;
        let currentStartDate;
        currentMealPlanStartDate.subscribe(value=>{
            currentStartDate = value;
        })();
        getPlansForUser(currentStartDate)
        .then(response => {
            const { plans: userPlans, plan: userPlan, plansAlreadyMember} = response;
            plans.set(userPlans);
            memberPlans.set(plansAlreadyMember);
            
            let currentUserPlan;
            plan.subscribe(value => {
                currentUserPlan = value;
            });

            if (currentUserPlan) {
                const updatedPlan = userPlans.find(userPlan => userPlan.id === currentUserPlan.id);

                if (updatedPlan !== undefined) {
                plan.set(updatedPlan);
                }
            }
            else {
                plan.set(userPlan);
            }

            f7.popup.close('.collaborators');
        })
    }

    async function onChangePlanDropdown(event){
        const newPlanId = event.detail[0].currentTarget.value

        await changePlan({
            planId : newPlanId
        })
    }
    
</script>