<Page name="Planner Duplicate" {onPageInit} {onPageBeforeRemove}>
    <Navbar title="Planner Duplicate" backLink="Back" />
    <Block>
        <p>Select the week to duplicate your planner from {formatWeekRange(getWeekRange($currentMealPlanStartDate))} into:</p>
        <Block>
            <div id="demo-calendar-inline-container" />
        </Block>
        <Block>
            <Button large fill onClick={() => duplicateWeek()}>Duplicate Planner</Button>
        </Block>
    </Block>
    
</Page>
  <script>
    import { f7, Page, Navbar, Block, Button } from 'framework7-svelte';
    import {
        currentMealPlanStartDate,
        duplicateRecipesForWeek,
        planRecipes
    } from "../stores/mealPlan";
    import {
        getWeekRange,
        formatWeekRange
    } from '../js/mealPlanHelpers';
    import { startOfWeek, showToast } from '../js/generalHelpers';
    import { tick } from 'svelte';

    let calendarInline;

    const monthNames = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];
 
    function onPageInit() {
        let dateArray = getDates($currentMealPlanStartDate, ($currentMealPlanStartDate).addDays(6));
        
        calendarInline = f7.calendar.create({
        containerEl: '#demo-calendar-inline-container',
        value: dateArray,
        firstDay: 1, // Default to Monday, in future this can be user preference driven
        renderToolbar() {
            return `
            <div class="toolbar calendar-custom-toolbar">
                <div class="toolbar-inner">
                <div class="left">
                    <a  class="link icon-only"><i class="icon icon-back"></i></a>
                </div>
                <div class="center"></div>
                <div class="right">
                    <a  class="link icon-only"><i class="icon icon-forward"></i></a>
                </div>
                </div>
            </div>
            `.trim();
        },
        on: {
            init(c) {
            f7.$('.calendar-custom-toolbar .center').text(
                `${monthNames[c.currentMonth]}, ${c.currentYear}`,
            );
            f7.$('.calendar-custom-toolbar .left .link').on('click', () => {
                calendarInline.prevMonth();
            });
            f7.$('.calendar-custom-toolbar .right .link').on('click', () => {
                calendarInline.nextMonth();
            });
            },
            monthYearChangeStart(c) {
            f7.$('.calendar-custom-toolbar .center').text(
                `${monthNames[c.currentMonth]}, ${c.currentYear}`,
            );
            },
            async dayClick(cal, el, year, month, day) {
                const dateClicked = new Date(year, month, day);
                const newStartDate = startOfWeek(dateClicked, 1); // This can be user preference driven later

                const newDates = getDates(newStartDate, (newStartDate).addDays(6));
                
                await tick();
                calendarInline.setValue(newDates);
            }
        },
        });
    }

    function onPageBeforeRemove() {
        calendarInline.destroy();
    }

    Date.prototype.addDays = function(days) {
       let dat = new Date(this.valueOf())
       dat.setDate(dat.getDate() + days);
       return dat;
    }

    function getDates(startDate, stopDate) {
      let dateArray = new Array();
      let currentDate = startDate;
      while (currentDate <= stopDate) {
        dateArray.push(currentDate)
        currentDate = currentDate.addDays(1);
      }
      return dateArray;
    }

    async function duplicateWeek() {
        if ($currentMealPlanStartDate.toLocaleDateString() === calendarInline.value[0].toLocaleDateString()){
            f7.dialog.alert("Cannot copy to the same week");
            return;
        } 

        let currentPlanRecipes;
        planRecipes.subscribe(value => {
            currentPlanRecipes = value;
        })

        if(currentPlanRecipes.length == 0){
            f7.dialog.alert("Cannot copy a blank week");
            return;
        }

        const didDuplicate = await duplicateRecipesForWeek(calendarInline.value[0]);
        if (!didDuplicate) {
            f7.dialog.alert("There was an error duplicating your plan");
            return;
        }
        showToast('Plan successfully duplicated');
        f7.views.current.router.back();
    }
  </script>
  