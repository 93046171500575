<Sheet backdrop bottom swipeToClose class="date-selector" {id} on:sheetClosed={sheetClosed} style="
    height: auto; 
    border-radius: 16px 16px 0 0;
">
    <div class="action-sheet-container">
        <div class="sheet-close-button">
            <Link sheetClose style="">
                <i class="fa fa-xmark navbar-icon"></i>
            </Link>
        </div>
        <WeekSelector
            currentStartDate={$currentMealPlanStartDate}
            onClickPreviousWeek={onClickPreviousWeek}
            onClickNextWeek={onClickNextWeek}/>
        <div class="this-week-display" style="{isViewingCurrentWeek($currentMealPlanStartDate) ? 'opacity: 1;' : 'opacity: 0;'}">
            This week
        </div>
        <Block style="margin-top: -20px;">
            <List>
                {#each daysOfTheWeek as dayOfWeek}
                    <ListItem 
                        checkbox 
                        title={dayOfWeek} 
                        value={dayOfWeek} 
                        checked={daysSelected.includes(dayOfWeek)} 
                        name="weekday-select" 
                        onChange={handleDateSelectionChange}
                        class="{isViewingCurrentWeek($currentMealPlanStartDate) && dayOfWeek === new Date().toLocaleDateString('en-US', { weekday: 'long' }) ? 'today-list-item' : ''}"
                        footer={
                            isViewingCurrentWeek($currentMealPlanStartDate) && dayOfWeek === new Date().toLocaleDateString('en-US', { weekday: 'long' }) ? 'Today' : ''
                        }
                    />
                {/each}
            </List>
            <Button large fill sheetClose popupClose on:click={() => callbackOnSave(daysSelected)} class="{daysSelected.length > 0 ? '' : 'disabled'}">Save</Button>
        </Block>
    </div>
</Sheet>
<script>
    import {
        Block, 
        Sheet,
        Link, 
        List, 
        ListItem, 
        Button
    } from 'framework7-svelte';
    import {
        currentMealPlanStartDate,
        daysOfTheWeek
    } from "../stores/mealPlan"
  import WeekSelector from './WeekSelector.svelte';
  import { getNextWeek, getPreviousWeek } from '../js/generalHelpers';

    export let id;
    export let callbackOnSave;
    
    let daysSelected = [];

    function sheetClosed(){
        daysSelected = []; // Reset after every open
    }

    function handleDateSelectionChange(event){
        const dayOfWeek = event.currentTarget.value;

        if(event.currentTarget.checked){
            // Append to the array
            daysSelected = [...daysSelected, dayOfWeek];
        } else {
            // Remove from the array
            daysSelected = daysSelected.filter(item => item !== dayOfWeek);
        }
    }

    function onClickPreviousWeek(){
        getPreviousWeek(currentMealPlanStartDate);
    }

    function onClickNextWeek(){
        getNextWeek(currentMealPlanStartDate);
    }

    function isViewingCurrentWeek(currentMealPlanStartDate) {
        // Get the current date
        const currentDate = new Date();
        
        // Set the time of currentDate to 00:00:00 to avoid hour differences
        currentDate.setHours(0, 0, 0, 0);
        
        // Calculate the start and end of the current week
        const startOfWeek = new Date(currentDate);
        startOfWeek.setDate(currentDate.getDate() - currentDate.getDay() + (currentDate.getDay() === 0 ? -6 : 1)); // Adjust for Sunday

        const endOfWeek = new Date(startOfWeek);
        endOfWeek.setDate(startOfWeek.getDate() + 6);

        // Check if currentMealPlanStartDate is within the current week
        return currentMealPlanStartDate >= startOfWeek && currentMealPlanStartDate <= endOfWeek;
    }

    
</script>
<style>
    .this-week-display {
        text-align: center;
        font-size: 14px;
        color: #8e8e93;
        margin-top: -15px;
        margin-bottom: 10px;
    }

    :global(.today-list-item .item-title){
        font-weight: bold;
    }

    :global(.today-list-item .item-footer){
        font-weight: bold;
    }
</style>
