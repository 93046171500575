import Fuse from 'fuse.js'

const ingredientCO2Data = [
    {
        name: "Apples",
        aliases: ["Apple", "Red Apples", "Green Apples"],
        co2_impact_kg: 0.43,
        co2_explanation: "Apples have a low environmental impact with minimal CO2 emissions during cultivation."
    },
    {
        name: "Bananas",
        aliases: ["Banana", "Yellow Bananas"],
        co2_impact_kg: 0.86,
        co2_explanation: "Bananas have a relatively low environmental impact with moderate CO2 emissions during cultivation."
    },
    {
        name: "Barley",
        aliases: ["Barley Grains"],
        co2_impact_kg: 1.18,
        co2_explanation: "Barley is a more sustainable grain with a lower carbon footprint."
    },
    {
        name: "Beef",
        aliases: ["Beef", "Steak", "Ground Beef", "Beef Meat", "Dairy Beef", "Dairy Cattle", "Cow Meat", "Cow Beef"],
        co2_impact_kg: 99.48,
        co2_explanation: "Beef production has a significant environmental impact due to high methane emissions from cattle and extensive land use for grazing and feed crops.",
        replacements: [
            {
                name: "Chicken",
                explanation: "Chicken has a lower CO2 footprint due to more efficient feed conversion ratios and less methane emissions compared to beef.",
                co2_impact_kg: 9.87
            },
            {
                name: "Lentils",
                explanation: "A great plant-based option, especially for ground beef recipes like tacos, Bolognese sauce, or burgers. Lentils require less land and water to grow, and do not produce methane emissions.",
                co2_impact_kg: 0.9
            },
            {
                name: "Mushrooms",
                explanation: "With their meaty texture, mushrooms like portobello can be great in burgers or stews. Finely chopped mushrooms can also mimic ground beef.",
                co2_impact_kg: 2.0
            }
        ]
    },
    {
        name: "Milk",
        aliases: ["Dairy Milk", "Cow Milk", "Moo Juice", "Fresh Milk", "Whole Milk"],
        co2_impact_kg: 33.3,
        co2_explanation: "Milk production requires significant land and water resources, and dairy cows produce methane, a potent greenhouse gas."
    },
    {
        name: "Beet Sugar",
        aliases: ["Sugar", "Sugar Beet"],
        co2_impact_kg: 1.81,
        co2_explanation: "Beet sugar production has a moderate environmental impact with moderate CO2 emissions during cultivation and processing."
    },
    {
        name: "Berries & Grapes",
        aliases: ["Berries", "Grapes", "Strawberries", "Blueberries", "Raspberries", "Blackberries", "Grapefruit", "Cranberries", "Cherries"],
        co2_impact_kg: 1.53,
        co2_explanation: "Berries and grapes have a relatively low environmental impact with moderate CO2 emissions during cultivation."
    },
    {
        name: "Brassicas",
        aliases: ["Cabbage", "Broccoli"],
        co2_impact_kg: 0.51,
        co2_explanation: "Brassicas have a low environmental impact with minimal CO2 emissions during cultivation."
    },
    {
        name: "Cane Sugar",
        aliases: ["Sugar", "Sugar Cane"],
        co2_impact_kg: 3.2,
        co2_explanation: "Cane sugar production has a moderate environmental impact with moderate CO2 emissions during cultivation and processing."
    },
    {
        name: "Cassava",
        aliases: ["Cassava Root"],
        co2_impact_kg: 1.32,
        co2_explanation: "Cassava has a relatively low environmental impact with moderate CO2 emissions during cultivation."
    },
    {
        name: "Cheese",
        aliases: ["Cheddar", "Mozzarella", "Parmesan", "Gouda", "Swiss Cheese", "American Cheese", "Provolone", "Brie"],
        co2_impact_kg: 23.88,
        co2_explanation: "Cheese production involves high dairy milk requirements, leading to significant methane emissions from dairy cows and high water usage.",
        replacements: [
            {
                name: "Nutritional Yeast",
                explanation: "Nutritional yeast can mimic the cheesy flavor with much lower environmental impact, as it is cultivated using a fraction of resources."
            },
            {
                name: "Plant-based Cheese",
                explanation: "Made from nuts or soy, plant-based cheeses have a smaller carbon footprint due to lower land and water usage.",
                co2_impact_kg: 2.0
            }
        ]
    },
    {
        name: "Citrus Fruit",
        aliases: ["Citrus", "Oranges", "Lemons", "Grapefruits", "Tangerines", "Clementines", "Mandarins"],
        co2_impact_kg: 0.39,
        co2_explanation: "Citrus fruits have a low environmental impact with minimal CO2 emissions during cultivation."
    },
    {
        name: "Coffee",
        aliases: ["Coffee Beans"],
        co2_impact_kg: 28.53,
        co2_explanation: "Coffee production has a moderate environmental impact with significant CO2 emissions during cultivation and processing."
    },
    {
        name: "Milk Chocolate",
        aliases: ["Chocolate", "Milk Chocolate"],
        co2_impact_kg: 47.12,
        co2_explanation: "Milk chocolate production has a high environmental impact due to the emissions associated with cocoa cultivation, processing, and dairy milk production."
    },
    {
        name: "Dark Chocolate",
        aliases: ["Dark Chocolate", "Cacao", "Cocoa", "Cacao Beans", "Cocoa Beans", "Cacao Powder", "Cocoa Powder", "Cacao Nibs", "Cocoa Nibs"],
        co2_impact_kg: 46.65,
        co2_explanation: "Dark chocolate production has a high environmental impact due to the emissions associated with cocoa cultivation and processing."
    },
    {
        name: "Eggs",
        aliases: ["Chicken Eggs", "Egg"],
        co2_impact_kg: 4.67,
        co2_explanation: "Egg production has a moderate environmental impact due to the emissions associated with poultry farming and feed production."
    },
    {
        name: "Fish (farmed)",
        aliases: ["Farmed Fish", "Salmon", "Tilapia"],
        co2_impact_kg: 13.63,
        co2_explanation: "Farmed fish production has a moderate environmental impact due to the emissions associated with fish farming and feed production."
    },
    {
        name: "Groundnuts",
        aliases: ["Peanuts"],
        co2_impact_kg: 3.23,
        co2_explanation: "Groundnut production has a moderate environmental impact due to the emissions associated with cultivation and processing."
    },
    {
        name: "Lamb & Mutton",
        aliases: ["Lamb", "Mutton", "Sheep Meat"],
        co2_impact_kg: 39.72,
        co2_explanation: "Lamb and mutton production has a high environmental impact due to the emissions associated with sheep farming and grazing."
    },
    {
        name: "Maize",
        aliases: ["Corn"],
        co2_impact_kg: 1.7,
        co2_explanation: "Maize production has a moderate environmental impact due to the emissions associated with cultivation and processing."
    },
    {
        name: "Milk",
        aliases: ["Dairy Milk", "Cow Milk", "Whole Milk"],
        co2_impact_kg: 3.15,
        co2_explanation: "Milk production requires significant land and water resources, and dairy cows produce methane, a potent greenhouse gas."
    },
    {
        name: "Nuts",
        aliases: ["Almonds", "Walnuts"],
        co2_impact_kg: 0.43,
        co2_explanation: "Nut production has a low environmental impact with minimal CO2 emissions during cultivation."
    },
    {
        name: "Oatmeal",
        aliases: ["Oats"],
        co2_impact_kg: 2.48,
        co2_explanation: "Oatmeal production has a moderate environmental impact due to the emissions associated with cultivation and processing."
    },
    {
        name: "Onions & Leeks",
        aliases: ["Onions", "Leeks", "Red Onions"],
        co2_impact_kg: 0.5,
        co2_explanation: "Onion and leek production has a low environmental impact with minimal CO2 emissions during cultivation."
    },
    {
        name: "Other Fruit",
        aliases: ["Pears", "Peaches", "Plums", "Apricots", "Nectarines", "Avocados", "Mangoes", "Pineapples", "Melons", "Watermelons", "Grapes", "Strawberries", "Blueberries", "Raspberries", "Blackberries", "Grapefruit", "Cranberries", "Cherries"],
        co2_impact_kg: 1.05,
        co2_explanation: "Other fruits have a moderate environmental impact with moderate CO2 emissions during cultivation."
    },
    {
        name: "Other Pulses",
        aliases: ["Lentils", "Chickpeas"],
        co2_impact_kg: 1.79,
        co2_explanation: "Other pulses production has a moderate environmental impact with moderate CO2 emissions during cultivation."
    },
    {
        name: "Other Vegetables",
        aliases: ["Carrots", "Cucumbers"],
        co2_impact_kg: 0.53,
        co2_explanation: "Other vegetables have a low environmental impact with minimal CO2 emissions during cultivation."
    },
    {
        name: "Peas",
        aliases: ["Green Peas"],
        co2_impact_kg: 0.98,
        co2_explanation: "Pea production has a low environmental impact with minimal CO2 emissions during cultivation."
    },
    {
        name: "Pork",
        aliases: ["Bacon", "Pig", "Pork Meat", "Pork Belly", "Pork Chops", "Pork Loin", "Pork Shoulder", "Pork Tenderloin"],
        co2_impact_kg: 12.31,
        co2_explanation: "Pig meat production has a moderate environmental impact due to the emissions associated with pig farming and feed production.",
        replacements: [
            {
                name: "Chicken",
                explanation: "Chicken has a lower CO2 footprint due to more efficient feed conversion ratios and less methane emissions compared to pork.",
                co2_impact_kg: 9.87
            },
            {
                name: "Tofu",
                explanation: "Tofu is made from soybeans and has a significantly lower carbon footprint, using less land and water resources.",
                co2_impact_kg: 3.16
            }
        ]
    },
    {
        name: "Potatoes",
        aliases: ["Sweet Potatoes", "White Potatoes", "Red Potatoes", "Russet Potatoes", "Yukon Gold Potatoes", "Purple Potatoes", "Fingerling Potatoes", "Baby Potatoes", "Potato"],
        co2_impact_kg: 0.46,
        co2_explanation: "Potato production has a low environmental impact with minimal CO2 emissions during cultivation."
    },
    {
        name: "Poultry Meat",
        aliases: ["Chicken", "Turkey"],
        co2_impact_kg: 9.87,
        co2_explanation: "Poultry meat production has a moderate environmental impact due to the emissions associated with poultry farming and feed production."
    },
    {
        name: "Prawns (farmed)",
        aliases: ["Farmed Prawns", "Shrimp", "Farmed Shrimp", "Shrimps",],
        co2_impact_kg: 26.87,
        co2_explanation: "Farmed prawn production has a high environmental impact due to the emissions associated with prawn farming and feed production."
    },
    {
        name: "Rice",
        aliases: ["White Rice", "Brown Rice", "Basmati Rice"],
        co2_impact_kg: 4.45,
        co2_explanation: "Rice production has a moderate environmental impact due to the emissions associated with cultivation and processing."
    },
    {
        name: "Root Vegetables",
        aliases: ["Carrots", "Potatoes"],
        co2_impact_kg: 0.43,
        co2_explanation: "Root vegetable production has a low environmental impact with minimal CO2 emissions during cultivation."
    },
    {
        name: "Soy milk",
        aliases: ["Soy Milk", "Plant-based Milk", "Soybean Milk"],
        co2_impact_kg: 0.98,
        co2_explanation: "Soy milk production has a low environmental impact with minimal CO2 emissions during cultivation and processing."
    },
    {
        name: "Tofu",
        aliases: ["Soybean Curd", "Bean Curd", "Soybean Cake", "Soybean Cheese", "Soybean Tofu"],
        co2_impact_kg: 3.16,
        co2_explanation: "Tofu production has a moderate environmental impact due to the emissions associated with soybean cultivation and processing."
    },
    {
        name: "Tomatoes",
        aliases: ["Cherry Tomatoes", "Roma Tomatoes", "Beefsteak Tomatoes", "Tomato"],
        co2_impact_kg: 2.09,
        co2_explanation: "Tomato production has a moderate environmental impact due to the emissions associated with cultivation and processing."
    },
    {
        name: "Wheat & Rye",
        aliases: ["Wheat", "Rye", "Whole Wheat"],
        co2_impact_kg: 1.57,
        co2_explanation: "Wheat and rye production has a moderate environmental impact due to the emissions associated with cultivation and processing."
    },
    {
        name: "Wine",
        aliases: ["Red Wine", "White Wine", "Rose Wine", "Sparkling Wine", "Champagne"],
        co2_impact_kg: 1.79,
        co2_explanation: "Wine production has a moderate environmental impact due to the emissions associated with grape cultivation and fermentation."
    }
];

/**
 * Finds a matching ingredient based on the provided input.
 * @param {string} ingredient - The ingredient to search for.
 * @returns {object|null} - The matching ingredient object if found, or null if no match is found.
 */
export const findMatchingIngredient = function (ingredient) {
    try {
        const options = {
            keys: ['name', 'aliases'],
            threshold: 0.1, // Adjust the threshold for fuzzy matching,
            includeScore: true,
        };

        const fuse = new Fuse(ingredientCO2Data, options);
        const result = fuse.search(ingredient);

        if (result.length > 0) {
            for(const item of result){
                if(item.score < 0.00002){
                    return item;
                }
            }
        }

        return null; // Return null if no matching object is found
    } catch {
        // return null if error
        return null;
    }
}

export const findMatchingIngredient_flexible = function (searchTerm) {
    const options = {
        keys: [
            {
                name: 'name',
                weight: 0.7, // Higher weight for name
            },
            {
                name: 'aliases',
                weight: 0.3, // Lower weight for aliases
            },
        ],
        threshold: 0.2,
        includeScore: true,
        shouldSort: true,
    };

    const fuse = new Fuse(ingredientCO2Data, options);

    // Preprocess the search term: split and filter out common conjunctions/prepositions
    const searchTerms = searchTerm.toLowerCase().split(/\s+/).filter(word => !['and', 'or', 'of', 'the'].includes(word));

    let bestMatch = null;
    let lowestScore = Infinity;

    // Perform a search for each word
    searchTerms.forEach(term => {
        const results = fuse.search(term);
        if (results.length > 0 && results[0].score < lowestScore) {
            bestMatch = results[0];
            lowestScore = results[0].score;
        }
    });

    // Log and return the best match
    if (bestMatch && parseFloat(bestMatch['score']) < 0.0002) {
        console.log('Search Term:', searchTerm);
        console.log('Best Match:', bestMatch.item);
        console.log(bestMatch['score'])
        return bestMatch;
    }

    return null; // Return null if no matching object is found
};
