import { offsetTimezone, startOfWeek } from './generalHelpers';
import { getUserData, getUserFirstLastName, userDataStore } from '../stores/userInfo';

export function formatWeekRange({ weekStart, weekEnd }) {
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    let startStr = `${monthNames[weekStart.getMonth()]} ${weekStart.getDate()}`;
    let endStr = `${monthNames[weekEnd.getMonth()]} ${weekEnd.getDate()}`;
  
    return `${startStr} - ${endStr}`;
}

export function formatDate(date) {
    let month = '' + (date.getMonth() + 1),
        day = '' + date.getDate(),
        year = date.getFullYear();
  
    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;
  
    return [year, month, day].join('-');
}

// Returns text 7-day date range in the format of "Jan 15 - Jan 21" for UI purposes
export function getWeekRange(date) {  
    if(!date){
        date = new Date();
        date = offsetTimezone(date, true);
    }
    let weekStart = startOfWeek(date, 'Monday');
    let weekEnd = new Date(weekStart);
    weekEnd.setDate(weekEnd.getDate() + 6);

    return {
        weekStart,
        weekEnd
    }
}


export function formatExternalRecipe(recipe, planRecipeDate, planRecipeId){

    const ingredients = recipe['ingredients'].map(ingredient => {
        if(ingredient['measure'] && ingredient['measure'].toLowerCase() === '<unit>'){
            ingredient['measure'] = 'Item(s)';
        }
        return {
            id: ingredient['id'],
            name: ingredient['food'],
            image: ingredient['image'],
            quantity: ingredient['quantity'],
            unit: ingredient['measure'],
            foodId: ingredient['foodId']
        }
    })

    const formattedRecipe = {
        id: recipe.id,
        label: recipe['label'],
        source: recipe['source'],
        totalTime: recipe['totalTime'],
        cookTime: recipe['cook_time'],
        prepTime: recipe['prep_time'],
        yield: recipe['yield'],
        calories: Math.round(recipe['calories'] / recipe['yield']),
        ingredients: ingredients,
        url: recipe['url'],
        backgroundImage: recipe['images']['REGULAR']['url'],
        type: 'external',
        uri: recipe['uri'],
        planRecipeDate : planRecipeDate ? planRecipeDate : undefined,
        planRecipeId : planRecipeId ? planRecipeId : undefined,
    }

    return formattedRecipe
}

export async function formatUserRecipe(recipe, planRecipeDate, planRecipeId){
    const userData = await getUserData();
    let userName;
    if( getUserFirstLastName(userDataStore).firstName && getUserFirstLastName(userDataStore).lastName){
        userName = `${getUserFirstLastName(userDataStore).firstName} ${getUserFirstLastName(userDataStore).lastName}`;
    } else if(getUserFirstLastName(userDataStore).firstName){
        userName = getUserFirstLastName(userDataStore).firstName;
    }
 
    const difficultyRating = `${recipe["recipe_ratings"][0]["difficulty_rating"]}/5`;
    const personalRating = `${recipe["recipe_ratings"][0]["personal_rating"]}/5`;
    const instructions = recipe["recipe_steps"].sort((step1, step2) => step1.order > step2.order);

    let recipeSource;
    if(recipe.author && recipe.author.first_name){
        recipeSource = recipe.author.first_name
    } else if(recipe['publisher'] && recipe['publisher'] !== '' && recipe['publisher'] != ' '){
        recipeSource = recipe['publisher']
    } else if(userName && userName !== '' && userName != ' ') {
        recipeSource = userName
    } else {
        recipeSource = 'Unknown'
    }

    const formattedRecipe = {
        id: recipe.id,
        label: recipe['name'],
        description: recipe['description'],
        source: recipeSource,
        url: recipe['url'],
        totalTime: recipe['cook_time'] + recipe['prep_time'],
        cookTime: recipe['cook_time'],
        prepTime: recipe['prep_time'],
        yield: recipe['serving_size'],
        ingredients: recipe['recipe_ingredients'],
        instructions,
        backgroundImage: recipe['image'],
        difficultyRating,
        personalRating,
        type: 'internal',
        planRecipeDate,
        planRecipeId,
        createdBy: recipe['created_by'] || undefined
    }

    return formattedRecipe;
}