import { writable } from 'svelte/store';
import { supabase } from '../js/supabase';
import { getUserData } from './userInfo';
import { currentMealPlanStartDate, daysOfTheWeek, jsDateDayMap, plan } from './mealPlan';
import { getWeekRange } from '../js/mealPlanHelpers';
import { startOfWeek, getBeginningOfWeek, getEndOfWeek } from '../js/generalHelpers';

const defaultGroceryData = []
const localStorageKey = 'groceryListData' 

export const groceryList = writable();
export const groceryLists = writable();

export const groceryListStore = writable(JSON.parse(localStorage.getItem(localStorageKey)) || defaultGroceryData);

const getCurrentGroceriesData = function(){
    let currentGroceries;
    groceryListStore.subscribe(value=>{
        currentGroceries = value;
    })(); 
    return currentGroceries;
}

export const addToCart = function(ingredientId){
    let currentGroceries = getCurrentGroceriesData()
    
    if(!currentGroceries.includes(ingredientId)){
        currentGroceries.push(ingredientId)
    }

    groceryListStore.set(currentGroceries)
}

export const removeFromCart = function(ingredientId){
    let currentGroceries = getCurrentGroceriesData();

    if(currentGroceries.includes(ingredientId)){
        currentGroceries = currentGroceries.filter(id => id !== ingredientId);
    }

    groceryListStore.set(currentGroceries);
}

export const isItemInGroceryList = function(groceryId, store){
    let currentGroceries = getCurrentGroceriesData()

    return currentGroceries.includes(groceryId)
}

export const initGroceryList = async function(){
    const userData = await getUserData();
    if(!userData || Object.keys(userData).length == 0){
        // No user data yet
        return
    }
    
    const userId = userData.id;
    const planResponse = await supabase.from('plan').select('id');

    if(!planResponse.data || planResponse.data.length == 0){
        return
    }

    const planId = planResponse.data[0].id;
    const groceryListResponse = await supabase.from('grocery_list').insert({
        plan_id: planId,
        created_by: userId
    })
    .select();
    return groceryListResponse;
}

export const addIngredientToGroceryList = async function(ingredient, date, planRecipeId) {
    const userData = await getUserData();
    const userId = userData.id;

    let currentPlan;
    plan.subscribe(value => {
        currentPlan = value;
    })

    let groceryListResponse = await supabase.from('grocery_list').select().eq('plan_id', currentPlan.id);

    if (!groceryListResponse.data || groceryListResponse.data.length === 0) {
        groceryListResponse = await initGroceryList();
    }

    if (groceryListResponse.data && groceryListResponse.data.length > 0) {
        const groceryListId = groceryListResponse.data[0].id;
        const { quantity, unit, name } = ingredient;
        let ingredientResponse = await supabase.from('grocery_item')
        .select()
        .eq('grocery_list_id', groceryListId)
        .eq('name', name)
        .eq('plan_recipe_id', planRecipeId)
        .eq('created_by', userId);

        if (ingredientResponse.data && ingredientResponse.data.length > 0) {
            ingredientResponse = await supabase.from('grocery_item').update({
                name,
                quantity : quantity ? quantity : null,
                unit : unit ? unit : null,
                date
            })
            .eq('grocery_list_id', groceryListId)
            .eq('name', name)
            .eq('plan_recipe_id', planRecipeId)
            .select();
        }
        else {
            ingredientResponse = await supabase.from('grocery_item').insert({
                name,
                quantity : quantity ? quantity : null,
                unit : unit ? unit : null,
                date,
                created_by: userId,
                grocery_list_id: groceryListId,
                plan_recipe_id: planRecipeId
            })
            .select();
        }

        return ingredientResponse.data[0];
    }
}

export const removeIngredientFromGroceryList = async function(groceryItemID){
    const response = await supabase.from('grocery_item').delete()
    .eq('id', groceryItemID)
}

export const getGroceryItemsForWeek = async function(startDate){
    if (!startDate) {
        let currentStartDate;
        currentMealPlanStartDate.subscribe(value=>{
            currentStartDate = value;
        })();
    
        startDate = new Date(currentStartDate.toISOString());
    }

    let endDate = getWeekRange(startDate).weekEnd;

    // Clean dates
    startDate = getBeginningOfWeek(startDate);
    endDate = getEndOfWeek(endDate)

    let groceryListResponse = await supabase.from('grocery_item')
    .select(`
        *, 
        plan_recipe(recipe_name),
        grocery_list(
            id,
            plan(
                id,
                name
            )
        )
    `)
    .gte('date', startDate)
    .lte('date', endDate)
    .order('name', { descending: true });

    return groceryListResponse.data;
}

export const getGroceryListForUser = async function(startDate){
    let groceryListResponse = await supabase
    .from('grocery_list')
    .select(`
        *,
        plan(
            id,
            name
        )
    `);

    if (!groceryListResponse.data || groceryListResponse.data.length == 0) {
        groceryListResponse = await initGroceryList();
    }

    groceryLists.set(groceryListResponse.data)

    const groceryItems = await getGroceryItemsForWeek(startDate);

    // Listen for database changes to groceries
    /*
    supabase
    .channel('any')
    .on('postgres_changes', { event: '*', schema: 'public', table: 'grocery_item' }, async (payload) => {
        console.log('Grocery change')
        const data = await getGroceryListForUser(startDate)
        groceryList.set(data);
    })
    .subscribe();
    */
    
    return { groceryItems };
}

export const addNewGroceryItem = async function({groceryListId, groceryItem} = {}) {

    if(!groceryListId){
        throw new Error('groceryListId is required for addNewGroceryItem')
    }

    if(!groceryItem){
        throw new Error('groceryItem is required for addNewGroceryItem')
    }

    const userData = await getUserData();
    const { name, quantity, unit } = groceryItem;

    let currentStartDate;
    currentMealPlanStartDate.subscribe(value=>{
        currentStartDate = value;
    })();

    const date = new Date(currentStartDate.toISOString());
    await supabase.from('grocery_item').insert({
        created_by: userData.id,
        name,
        quantity : quantity ? quantity : null,
        unit : unit ? unit : null,
        date,
        grocery_list_id: groceryListId
    });
}

export const updateGroceryItem = async function(groceryItem) {
    const { id, date, name, quantity, unit, checked } = groceryItem;
    await supabase.from('grocery_item').update({
        date,
        name,
        quantity : quantity ? quantity : null,
        unit : unit ? unit : null,
        checked
    })
    .eq('id', id);
}

export const removeGroceryItem = async function(groceryItemID){
    await supabase
        .from('grocery_item')
        .delete()
        .eq('id', groceryItemID)
}


export default groceryListStore;