<Sheet 
    swipeToClose 
    class="add-grocery-item" 
    on:sheetOpened={triggerKeyboardFocus} 
    style="
        height: auto;
        padding-bottom: 20px;
        border-radius: 16px 16px 0px 0px;
    "
>
    <PageContent>
            <div class="sheet-close-button">
                <Link on:click={handleCloseAddGroceryItem}>
                    <i class="fa fa-xmark navbar-icon-1"></i>
                </Link>
            </div>
            <List strong outline dividers inset style="margin-top: 60px;">
                <ListInput 
                    resizable type="text" 
                    placeholder="Name" 
                    clearButton 
                    bind:value="{newGroceryItem.name}"
                    class="grocery-item-name-input-container"
                ></ListInput>
                <ListInput
                    type="text"
                    placeholder="Quantity"
                    errorMessage="Only numbers please!"
                    validate
                    onValidate={(isValid) => quantityValidation(isValid)}
                    pattern="[0-9]*"
                    clearButton
                    bind:value="{newGroceryItem.quantity}"
                ></ListInput>
                <ListInput type="select" bind:value="{newGroceryItem.unit}">
                    <option value="null">Unit/Measurement</option>
                    <option value="Items">Items</option>
                    <option value="Grams">Grams</option>
                    <option value="Milligrams">Milligrams</option>
                    <option value="Kilograms">Kilograms</option>
                    <option value="Liters">Liters</option>
                    <option value="Milliliters">Milliliters</option>
                    <option value="Cups">Cups</option>
                    <option value="Tablespoons">Tablespoons</option>
                    <option value="Teaspoons">Teaspoons</option>
                    <option value="FluidOunces">Fluid Ounces</option>
                    <option value="Pounds">Pounds</option>
                    <option value="Ounces">Ounces</option>
                    <option value="Pints">Pints</option>
                    <option value="Quarts">Quarts</option>
                    <option value="Gallons">Gallons</option>
                </ListInput>
                {#if $groceryLists && $groceryLists.length > 1}
                    <!-- Choose which grocery list to add this item to -->
                    <ListInput type="select" bind:value="{selectedGroceryListId}">
                        {#each $groceryLists as userGroceryList}
                            <option value="{userGroceryList.id}">{userGroceryList.plan.name}</option>
                        {/each}
                    </ListInput>
                {/if}
            </List>
            <Block>
                <Button 
                    large 
                    fill 
                    on:click={handleAddGroceryItem}
                    id="add-grocery-item-button"
                    class="{(newGroceryItem.name.length > 0 && isQuantityValid && selectedGroceryListId ) ? '' : 'disabled' }"
                >
                    Add item
                </Button>
            </Block>
        </PageContent>
</Sheet>
<script>
    import { Link, NavLeft, NavTitle, Navbar, Page, Popup, Sheet, View, NavRight, f7, List, ListInput, ListItem, PageContent, Button, Block, BlockTitle, BlockHeader, NavTitleLarge} from "framework7-svelte";
    import { addNewGroceryItem, groceryList, groceryLists } from "../stores/groceries";
    import { autoExpand, showToast } from "../js/generalHelpers";
    import '../css/addGroceryItem.css';
    import { tick } from 'svelte';
    import { plan } from "../stores/mealPlan"
    import { onMount } from 'svelte';

    export let onSaveGroceryItemCallback;

    let newGroceryItem = {
        name: '',
        quantity: '',
        unit: 'null'
    }


    onMount(async () => {
        document.getElementById('add-grocery-item-button').addEventListener('mousedown', function(event) {
            event.preventDefault();
        });
    })

    let selectedGroceryListId;
    $:onPlanChange($plan)
    function onPlanChange(plan){

        if($groceryLists && $groceryLists.length > 0 && plan && plan.id){
            // Find the grocery list who's plan ID matches the current selected plan
            for(const groceryList of $groceryLists){
                if(groceryList.plan.id == plan.id){
                    selectedGroceryListId = groceryList.id
                }
            }
        }
    }

    function handleCloseAddGroceryItem(){
        if(newGroceryItem.name || newGroceryItem.quantity || newGroceryItem.unit != 'null'){
            // Confirm if the user actually wants to close, all progress will be lost
            f7.dialog.confirm('Are you sure you want to cancel adding a new grocery item? All unsaved progress will be lost.', () => {
                clearAllFields()

                // Close recipe creation page
                f7.sheet.close('.add-grocery-item')
            });
        } else {
            // Immediately close the add item popup without asking
            f7.sheet.close('.add-grocery-item')
        }
    }

    async function handleAddGroceryItem(){
        if (newGroceryItem.name === '') {
            f7.dialog.alert('Grocery name is required');
            return;
        }

        if(newGroceryItem.quantity > 0 && newGroceryItem.unit == 'null'){
            f7.dialog.alert('You must select a unit/measurement if you are adding an ingredient quantity');
            return;
        }

        // Transform unit null to empty string before sending it off
        if(newGroceryItem.unit == 'null'){
            newGroceryItem.unit = ''
        }
        
        await addNewGroceryItem({
            groceryItem : newGroceryItem,
            groceryListId : selectedGroceryListId
        });

        showToast(`Item added to grocery list`);

        clearAllFields();

        onSaveGroceryItemCallback();

        /* Does not close sheet by default after adding an item
        f7.sheet.close('.add-grocery-item')
        */
    }

    function clearAllFields(){
        newGroceryItem.name = '';
        newGroceryItem.quantity = '';
        newGroceryItem.unit = 'null';
    }

    function triggerKeyboardFocus(){
        f7.$('.grocery-item-name-input-container').find('input').focus()
    }

    let isQuantityValid = true; // Default is true because quantity isn't required
    async function quantityValidation(isValid){
        await tick(); // Wait for the DOM update
        let quantityValue = newGroceryItem.quantity;

        // If quantity has a value, it must be valid.
        // Because quantity is not required, if quantity.length == 0, then it is valid
        if(
            (quantityValue.length > 0 && isValid) 
            || 
            quantityValue.length == 0
        ){
            isQuantityValid = true
        } else {
            isQuantityValid = false
        }
    }

    
    

</script>