import { tick } from 'svelte';
import { f7 } from 'framework7-svelte';
import { getUserData } from '../stores/userInfo'
import { handleDeepLink } from '../js/generalHelpers'

const ONE_SIGNAL_APP_ID = "7310fe9e-ef85-40b1-955a-2b76cae21256";
const DIALOG_TITLE = "Zestyplan";
const PROMPT_TEXT = "Get reminders, updates, and personalized recipe suggestions - Enable notifications to get the most out of your Zestyplan experience.";
const SETTINGS_TEXT = "Enable notifications in your settings to get the most out of your Zestyplan experience.";

let pushNotificationsInitialized = false;

export async function initializePushNotifications() {
    await tick();

    if (pushNotificationsInitialized) {
        console.log('Push notifications already initialized');
        return;
    }

    if (window.cordova) {
        initializeOneSignal();

        try {
            /*
            permissionNative returns the current notification permission status.
            0 = NotDetermined
            1 = Denied
            2 = Authorized
            3 = Provisional (only available in iOS 12+)
            4 = Ephemeral (only available in iOS 14+)
            */

            const permissionStatus = await window.plugins.OneSignal.Notifications.permissionNative(); 
            const shouldPrompt = await checkIfShouldPromptNotificationPermission();

            if (shouldPrompt && permissionStatus !== 2) {
                handlePermissionPrompt(permissionStatus);
            } else if(permissionStatus === 2){
                // Authorized, setup click listener
                addNotificationClickListener();

                // Refresh external user id if permission was previously granted
                refreshOneSignalExternalUserIdAndEmail();
            }
        } catch (error) {
            console.error("Error initializing push notifications:", error);
        }
    }

    pushNotificationsInitialized = true;
}

function initializeOneSignal() {
    // window.plugins.OneSignal.Debug.setLogLevel(6); // Uncomment for debugging
    // window.plugins.OneSignal.Debug.setAlertLevel(6); // Uncomment for verbose logging
    window.plugins.OneSignal.initialize(ONE_SIGNAL_APP_ID);
}

function createDialog(text, callback) {
    return f7.dialog.create({
        title: DIALOG_TITLE,
        text: text,
        buttons: [
            { text: 'Not now' },
            { text: 'Sounds great!', onClick: callback }
        ]
    });
}

async function handlePermissionPrompt(permissionStatus) {
    let text, callback;

    if (permissionStatus === 0) {
        text = PROMPT_TEXT;
        callback = promptForPermission;
    } else if (permissionStatus === 1) {
        text = SETTINGS_TEXT;
        callback = promptForPermission;
    }

    const dialog = createDialog(text, callback);
    dialog.open();
}

async function promptForPermission() {
    try {
        const accepted = await window.plugins.OneSignal.Notifications.requestPermission(true);
        console.log("User accepted notifications:", accepted);

        let completeUserData = await getUserData();

        window.plugins.OneSignal.login(completeUserData.id);
        window.plugins.OneSignal.User.addEmail(completeUserData.email);

        addNotificationClickListener();
    } catch (error) {
        console.error("Error requesting notification permission:", error);
        throw new Error(error)
    }
}

async function refreshOneSignalExternalUserIdAndEmail() {
    let completeUserData = await getUserData();

    window.plugins.OneSignal.login(completeUserData.id);
    window.plugins.OneSignal.User.addEmail(completeUserData.email);
}

function addNotificationClickListener() {
    const listener = (event) => {
        const notificationPayload = JSON.stringify(event);
        // Check if the notification has a target URL to invoke
        if(event && event.notification && event.notification.additionalData){
            let eventData = event.notification.additionalData;
            if(eventData.deepLinkURL){
                // Invoke handle deep-links function
                handleDeepLink(eventData.deepLinkURL);
            } else if(eventData.openURL){
                if(window.cordova) {
                    cordova.InAppBrowser.open(target_url, '_system'); // Open app in System's default browser rather than in app
                } else {
                    window.open(target_url , '_blank');
                }
            }
        }
        console.log("Notification payload:", notificationPayload);
    };
    window.plugins.OneSignal.Notifications.addEventListener("click", listener);
}

async function checkIfShouldPromptNotificationPermission() {
    // We don't want to overwhelm the user with too many permission prompts in a short period of time
    // This function will return true if the user has never been prompted before or if they have been prompted 3 times already
    let tracker = localStorage.getItem('notificationPermissionPromptTracker');

    if (tracker === null || tracker >= 3) {
        localStorage.setItem('notificationPermissionPromptTracker', 1);
        return true;
    } else {
        localStorage.setItem('notificationPermissionPromptTracker', parseInt(tracker) + 1);
        return false;
    }
}
