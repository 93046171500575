<Page name="groceries" on:pageTabShow={onPageTabShow} on:pageInit={onPageTabShow} ptr onPtrRefresh={ptrRefreshGroceries}>
  
  <!-- 
  <Navbar transparent sliding={true} class="no-outline">
    <NavTitle sliding>Groceries</NavTitle>
  </Navbar>
  <div class="page-title">
    Groceries
  </div>
  <WeekSelector
    currentStartDate={$currentMealPlanStartDate}
    onClickPreviousWeek={onClickPreviousWeek}
    onClickNextWeek={onClickNextWeek}/>
-->

<Navbar large transparent sliding={false} mdCenterTitle>
  <!-- <NavLeft>
    <Link panelOpen="#panel-plans" iconIos="f7:chevron_left" iconMd="material:menu" text="Lists"/>
  </NavLeft>
  -->
  <NavTitle sliding>Groceries</NavTitle>
  <NavRight style="margin-left: 0;">
    <NavBarButtons placeholder={true}/>
  </NavRight>
  
  <div slot="title-large" style="
    display: flex;
    align-items: flex-end;
  ">
    <div style="
    font-size: 32px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  ">
      Groceries
    </div>
    
    <NavBarButtons placeholder={true}/>
  </div>
  <Subnavbar>
    <NavBarButtons page='groceries'/>
    <WeekSelector
      currentStartDate={$currentMealPlanStartDate}
      onClickPreviousWeek={onClickPreviousWeek}
      onClickNextWeek={onClickNextWeek}
    />
  </Subnavbar>
</Navbar>
  <Popover class="groceries-popover-menu">
    <List>
        <ListItem class="popover-close" on:click={() => {
          const TARGET = process.env.TARGET || "web"; // Default to Web
          if(TARGET.toLocaleLowerCase() === 'cordova') {
            window.cordova.InAppBrowser.open('https://forms.gle/KvmSMi3TbiabWfG99', '_system'); // Open app in System's default browser rather than in app
          } else {
            window.open('https://forms.gle/KvmSMi3TbiabWfG99' , '_blank');
          }
        }} title="Leave feedback" />
    </List>
  </Popover>
  {#if $groceryList}
    
      {#each $groceryLists as userGroceryList}
        {@const planGroceryItems = $groceryList.groceryItems.filter(item => item.grocery_list.plan.id === userGroceryList.plan.id)}
        {#if planGroceryItems.length > 0}
        <List>
          <ListItem accordionItem title="{userGroceryList.plan && userGroceryList.plan.name ? userGroceryList.plan.name : 'Grocery list'}" class="grocery-list-accordion-container accordion-item-opened" after="{planGroceryItems.length}">
            <AccordionContent>
              <List inset mediaList>
                {#each planGroceryItems as planItem}
                  {#key planItem.id}
                    <ListItem  
                      checkbox 
                      swipeout
                      id={planItem.id}
                      class={planItem.checked ? 'checked-item' : ''}
                      title={
                        `${planItem.name.charAt().toUpperCase()}${planItem.name.slice(1)}` +
                        ((planItem.quantity && planItem.unit && planItem.quantity > 0) ? ` | ${planItem.quantity} ${planItem.unit}` : '')
                      }
                      subtitle={
                        planItem.plan_recipe && planItem.plan_recipe.recipe_name ? `Recipe: ${planItem.plan_recipe.recipe_name}` : ''
                      }
                      text={
                        planItem.notes ? `Notes: ${planItem.notes}` : ''
                      }
                      on:change={async () => await onChangeGroceryItemCheck(planItem)} 
                      checked={planItem.checked}
                      onSwipeoutDeleted={handleRemoveGroceryItem}
                    >
                      <SwipeoutActions right>
                        <SwipeoutButton delete>
                          Remove
                        </SwipeoutButton>
                      </SwipeoutActions>
                    </ListItem>
                  {/key}
                {/each}
              </List>
            </AccordionContent>
          </ListItem>
        </List>
        {/if}
      {/each}
  {/if}
  <AddGroceryItem onSaveGroceryItemCallback={fetchGroceryList}/>
</Page>
<div class="fab fab-right-bottom" style="text-align: -webkit-right; pointer-events: none;">
  <!-- svelte-ignore a11y-missing-attribute -->
  <!-- svelte-ignore a11y-click-events-have-key-events -->
  <a on:click={onClickAddButton} class="fab-plus">
    <i class="icon f7-icons">plus</i>
    <i class="icon f7-icons">xmark</i>
  </a>
</div>
<script>
  import { 
    Page, 
    Navbar, 
    List, 
    ListItem, 
    NavTitle,
    Link,
    f7,
    SwipeoutButton,
    SwipeoutActions,
    Subnavbar,
    NavRight,
    NavLeft,
    Popover,
    AccordionContent,
    Block
  } from 'framework7-svelte';
  import {getGroceryListForUser, groceryList, groceryLists, updateGroceryItem, removeGroceryItem} from "../stores/groceries"
  import { getNextWeek, getPreviousWeek } from '../js/generalHelpers';
  import AddGroceryItem from '../components/AddGroceryItem.svelte';
  import WeekSelector from '../components/WeekSelector.svelte';
  import '../css/groceries.css';
  import { currentMealPlanStartDate, plan } from '../stores/mealPlan';
  import { showToast } from "../js/generalHelpers"
  import NavBarButtons from '../components/NavBarButtons.svelte';
  import { onMount } from 'svelte';
  import { userData, getUserData } from "../stores/userInfo";

  onMount(async () => {
    document.addEventListener('groceriesUpdate', function(event){
      // Access groceries payload with event.detail.payload
      /*
      const payload = event.detail.payload;
      if(payload.eventType === "UPDATE" || payload.eventType === "INSERT"){
        debugger
      } else if(payload.eventType === "DELETE"){
        debugger
      }
      */

      fetchGroceryList();
    });
  })

  async function onPageTabShow(){
    if(window.TapticEngine){
      window.TapticEngine.impact({
        style: "light" // light | medium | heavy | rigid (iOS 13+) | soft (iOS 13+)
      });
    }

    await fetchGroceryList();
  }

  async function ptrRefreshGroceries(done){
    await fetchGroceryList();
    done();
  }

  async function fetchGroceryList(startDate){
    let currentUser = await getUserData();
    if(!currentUser){
      return
    }
    
    const data = await getGroceryListForUser(startDate)
    groceryList.set(data);
  }

  async function onClickPreviousWeek(){
    const { startDate } = getPreviousWeek(currentMealPlanStartDate);

    await fetchGroceryList(startDate);
  }

  async function onClickNextWeek(){
    const { startDate } = getNextWeek(currentMealPlanStartDate);

    await fetchGroceryList(startDate);
  }

  function onClickAddButton(){
    f7.sheet.open('.add-grocery-item');
  }

  async function onChangeGroceryItemCheck(groceryItem) {
    const checked = groceryItem.checked;
    groceryItem.checked = !checked;
    await updateGroceryItem(groceryItem);
    await fetchGroceryList()
  }

  async function handleRemoveGroceryItem(){
    await removeGroceryItem(this.id);
    await fetchGroceryList();
    showToast(`Grocery item removed`)
  }
</script>