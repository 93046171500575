<script>
  import {
    f7,
    Page,
    Navbar,
    Block,
    NavLeft,
    NavTitle,
    Link,
    Button,
    Popup,
    View,
    Sheet,
    Toolbar,
    PageContent,
    BlockTitle,
  } from "framework7-svelte";
  import { addRecipeToMealPlan } from "../stores/mealPlan";
  import { showToast } from "../js/generalHelpers";
  import DateSelectSheetModal from "../components/DateSelectSheetModal.svelte";
  import { onMount } from "svelte";
  import { createPicker } from "picmo";

  let quickAddText;

  let quickAddEmojis = ["🍳", "🍔", "🍝", "🧁", "🥡", "📦", "🍹"];
  let selectedEmoji = null;

  async function handleQuickAddItem(mealPlanDaysSelected) {
    if (quickAddText === "") {
      f7.dialog.alert("Enter a description of your item first.");
      return;
    }

    f7.dialog.preloader("Saving item...");

    try {
      for (const mealPlanDay of mealPlanDaysSelected) {
        await addRecipeToMealPlan({
          day: mealPlanDay,
          recipe: {
            label: quickAddText,
            quickAdd: true,
            emoji: selectedEmoji,
          },
        });

        showToast(`Added item to ${mealPlanDay}`);
      }

      quickAddText = "";
      f7.dialog.close();
      handleClose();
    } catch (error) {
      f7.dialog.close();
      f7.dialog.alert("There was an error saving the Quick Add item.");
    }
  }

  function handleClose() {
    f7.popup.close(".quick-add");
  }

  function selectEmoji(emoji) {
    selectedEmoji = emoji;
  }

  onMount(() => {
    const rootElement = document.querySelector("#picker");

    const picker = createPicker({
      rootElement,
      showRecents: false,
      showPreview: false,
      showVariants: false,
      pickerWidth: "100%",
    });

    picker.addEventListener("emoji:select", (event) => {
      selectedEmoji = event.emoji;
    });
  });
</script>

<Popup push class="quick-add">
  <View>
    <Page>
      <Navbar sliding={false}>
        <NavLeft>
          <Link on:click={handleClose}>Cancel</Link>
        </NavLeft>
        <NavTitle sliding>Quick Add</NavTitle>
      </Navbar>
      <div style="padding-top: 20px;">
        <Block style="margin: 0; padding-bottom: 140px">
          <div class="quick-add-input">
            <div class="list" style="margin: 0;">
              <div class="input-field outline">
                <textarea
                  id="recipe-url"
                  class="recipe-url"
                  bind:value={quickAddText}
                  placeholder="What will you be eating?"
                />
              </div>
              {#if quickAddText && quickAddText.length > 30}
                <div style="color: var(--f7-input-error-text-color); font-size: 12px; margin-bottom: 10px;">
                  Please keep your description under 30 characters.
                </div>
              {/if}
              <BlockTitle style="margin-top: 15px; margin-left: 0px;">Choose an emoji</BlockTitle>
              <div class="emoji-grid">
                {#each quickAddEmojis as emoji}
                  <!-- svelte-ignore a11y-click-events-have-key-events -->
                  <div
                    class="emoji-circle {selectedEmoji === emoji
                      ? 'selected'
                      : ''}"
                    on:click={() => selectEmoji(emoji)}
                  >
                    {emoji}
                  </div>
                {/each}
                <!-- svelte-ignore missing-declaration -->
                <!-- svelte-ignore a11y-click-events-have-key-events -->
                <div
                  class="emoji-circle {selectedEmoji &&
                  !quickAddEmojis.includes(selectedEmoji)
                    ? 'selected'
                    : ''}"
                  on:click={() => f7.sheet.open(".emoji-picker")}
                >
                  {#if selectedEmoji && !quickAddEmojis.includes(selectedEmoji)}
                    {selectedEmoji}
                  {:else}
                    ➕
                  {/if}
                </div>
              </div>
              <div style="text-align: center; font-weight: 700;">
                <Link on:click={() => f7.sheet.open(".emoji-picker")}>
                  Select your own!
                </Link>
              </div>
              <Button
                large
                fill
                on:click={() => f7.sheet.open("#quick-add-date-selector")}
                style="margin-top: 40px;"
                class={(quickAddText && quickAddText.length > 0 && quickAddText.length < 30) || selectedEmoji
                  ? ""
                  : "disabled"}
              >
                Add to meal plan
              </Button>
            </div>
          </div>
        </Block>
      </div>
      <DateSelectSheetModal
        id="quick-add-date-selector"
        callbackOnSave={handleQuickAddItem}
      />
      <Sheet class="emoji-picker" style="height: auto;">
        <Toolbar>
          <div class="left" />
          <div class="right">
            <Link sheetClose>Done</Link>
          </div>
        </Toolbar>
        <PageContent>
          <div id="picker" />
        </PageContent>
      </Sheet>
    </Page>
  </View>
</Popup>

<style>
  .quick-add-input {
    width: 100%;
    padding-bottom: 20px;
  }

  .outline {
    border-radius: 8px;
    border-style: solid;
    border-color: var(--f7-input-info-text-color);
    border-width: 1px;
    padding: 5px;
  }

  .input-field {
    margin-bottom: 10px;
    padding: 10px;
    box-sizing: border-box;
    width: 100%;
  }

  .input-field textarea {
    width: 100%;
    overflow-y: hidden;
    resize: none;
    height: 4em;
    min-height: 1.2em;
    padding: initial;
  }

  .emoji-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 10px;
    justify-items: center;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .emoji-circle {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    font-size: 24px;
    background-color: #f0f0f0;
    cursor: pointer;
    transition: border 0.3s ease;
  }

  .emoji-circle.selected {
    box-shadow: inset 0 0 0 3px var(--f7-theme-color);
  }

  :global(.picker) {
    width: 100% !important;
  }

  :global(.picmo__picker) {
    width: 100% !important;
  }
</style>
