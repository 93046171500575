<script>
    import '../css/recipe-editor.css';
    import {
        f7,
        Page,
        Sheet,
        Navbar,
        Block,
        NavLeft,
        NavTitle,
        Link,
        List,
        ListItem,
        Button,
        Popup,
        View,
        Preloader,
        Stepper,
        Range,
    } from "framework7-svelte";
    import { tick } from 'svelte';
    import { supabase, uploadRecipeImage } from "../js/supabase";
    import { autoExpand, showToast } from "../js/generalHelpers"
    import { getUserData, getUserFirstLastName, userDataStore } from "../stores/userInfo";
    import { aiRecipeGenerating, generateAIRecipe, generateAIRecipeImage } from "../js/aiHelpers";
    import { CameraIcon } from 'svelte-feather-icons';
    import { getRecipeDetails, getUserRecipes, recipeImage, selectedRecipe, userRecipes, getRecipeDetailsFromId } from '../stores/mealPlan';
    import * as Sentry from "@sentry/browser";
    import ZestyplanAssistantInput from '../components/ZestyplanAssistantInput.svelte';

    const defaultImage = 'https://images.unsplash.com/photo-1604152135912-04a022e23696?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D';

    let recipeTitle, recipeDescription;
    // Default recipe image, overwritten by user uploaded image
    recipeImage.set(defaultImage);
    let servings = 0;
    let prepTime, cookTime = 5;

    let prepTimeComputed, cookTimeComputed

    let overallRating = 1;
    let difficultyRating = 1;

    let recipeGenerating = false;
    let imageGenerating = false;
    let isEdit = false;

    const inputClasses = ["recipe-title", "recipe-description", "ingredient-name", "ingredient-quantity", "ingredient-unit", "instruction-step"]

    // Setup the default ingredient and instruction state, along with blank templates for later steps
    const recipeIngredientBlank = [{
        name : null,
        quantity : null,
        unit : null
    }];

    const recipeInstructionBlank = [{
        description : null
    }];

    let recipeIngredients = [{
        name : null,
        quantity : null,
        unit : null
    }];

    let recipeInstructions = [{
        description : null
    }];

    // set initial servings, prep and cook time
    setServings(1)
    setPrepTime(5)
    setCookTime(5)

    function initRecipeCreation() {
        let aiRecipeGeneratingData;
        aiRecipeGenerating.subscribe(value=>{
            aiRecipeGeneratingData = value;
        })();

        if (aiRecipeGeneratingData) {
            generateAIRecipeProcess(aiRecipeGeneratingData);
            generateAIRecipeImageProcess(aiRecipeGeneratingData);
        }

        // Set Edit fields
        if (!!$selectedRecipe) {
            if ($selectedRecipe.id) {
                // Only set if has an id as you can edit an edamam recipe before it's saved
                isEdit = true;
            } 
            
            recipeTitle = $selectedRecipe.label;
            recipeDescription = $selectedRecipe.description;
            recipeIngredients = $selectedRecipe.ingredients ? $selectedRecipe.ingredients : JSON.parse(JSON.stringify(recipeIngredientBlank));
            recipeInstructions = $selectedRecipe.instructions ? $selectedRecipe.instructions : JSON.parse(JSON.stringify(recipeInstructionBlank));
            overallRating = $selectedRecipe['personalRating'] ? $selectedRecipe['personalRating'].split('/')[0] : 1;
            difficultyRating = $selectedRecipe['difficultyRating'] ? $selectedRecipe['difficultyRating'].split('/')[0] : 1;
            servings = $selectedRecipe['yield'] ? $selectedRecipe['yield'] : 1;
            prepTime = $selectedRecipe['prepTime'] ? $selectedRecipe['prepTime'] : 5;
            cookTime = $selectedRecipe['cookTime'] ? $selectedRecipe['cookTime'] : 5;
            if ($selectedRecipe['backgroundImage']) {
                recipeImage.set($selectedRecipe['backgroundImage']);
            }
        }

        // If recipe generation is being triggered by an image upload, then add that image to the recipe
        if(recipeImage && recipeImage !== defaultImage && !imageGenerating){
            document.getElementsByClassName('recipe-image')[0].style.backgroundImage = `url(${$recipeImage})`;
        }
    }

    async function generateAIRecipeProcess(input){
        recipeGenerating = true;
        
        f7.$('.recipe-editor').find('.view').css('pointer-events', 'none')

        fillRecipeWithPlaceholderBlocks(input); // Start filling the recipe UI with placeholder blocks

        let returned;
        try {
            returned = await generateAIRecipe(input);
        } catch(error){
            console.log(error)
            f7.dialog.alert(`There was an error generating your recipe. Please try again.`);
            recipeGenerating = false;
            f7.$('.recipe-editor').find('.view').css('pointer-events', 'all')
            f7.$('.recipe-editor').find('textarea').css('color', 'inherit') // reset the text areas
            // close the recipe creation page
            f7.popup.close('.recipe-editor')
            return
        }

        recipeGenerating = false;

        // clear the filled in fields and allow for user interaction again
        clearInputFields();
        f7.$('.recipe-editor').find('.view').css('pointer-events', 'all')
        f7.$('.recipe-editor').find('textarea').css('color', 'inherit') // reset the text areas

        // remove the blank ingredient and instruction items as we are going to make new ones:
        recipeIngredients = recipeIngredients.filter((_, index) => index !== 0); // Removing default index 0
        recipeInstructions = recipeInstructions.filter((_, index) => index !== 0); // Removing default index 0

        await tick(); // Wait for the DOM update

        // Get current user's name
        const userData = await getUserData()

        // Add the finalized recipe into the UI
        recipeTitle = `${getUserFirstLastName($userDataStore).firstName ? getUserFirstLastName($userDataStore).firstName + "'s " : ""}${returned.title} recipe`;
        recipeDescription = `${returned.recipe_description}${returned.how_dietary_preferences_were_used ? '\n\n' + returned.how_dietary_preferences_were_used : ''}`;

        // Add ingredients
        for(let ingredient of returned.ingredients){
            addIngredientLine({
                name : ingredient.title,
                quantity : ingredient.quantity,
                unit : ingredient.unit
            })
            await tick();
        }

        // Add instructions
        for(let instruction of returned.instructions){
            addInstructionLine({
                description : instruction.instruction
            })
            await tick();
        }

        // Trigger resize for all textareas to ensure boxes are the right size
        f7.$('.recipe-editor').find('textarea').each(function(){
            autoExpand({target : this})
        })

        // Add Serving size
        if(returned.serving_size){
            setServings(returned.serving_size)
        }

        // Add Prep time
        if(returned.prep_time){
            setPrepTime(returned.prep_time)
        } else {
            setPrepTime(0)
        }

        // Add Cook time
        if(returned.cook_time){
            setCookTime(returned.cook_time)
        } else {
            setCookTime(0)
        }

        // Show the completion onboarding modal
        // TODO: Make this only appear up for the user the first time they do this
        f7.sheet.open('.ai-generated-recipe-modal')
    }

    async function generateAIRecipeImageProcess(input) {
        try {
            imageGenerating = true;
            document.getElementsByClassName('recipe-image')[0].style.backgroundImage = null;
            const generatedImagePath = await generateAIRecipeImage(input);
            
            imageGenerating = false;
            document.getElementsByClassName('recipe-image')[0].style.backgroundImage = `url(${generatedImagePath})`;
            recipeImage.set(generatedImagePath);
        } catch (error) {
            // If fails show the default image and take it out of image generating state
            imageGenerating = false;
            document.getElementsByClassName('recipe-image')[0].style.backgroundImage = `url(${defaultImage})`;
        }
    }

    function clearAllFields(){
        // Reset aiRecipeGenerating store
        aiRecipeGenerating.set(null)
            
        // Clear all inputs 
        clearInputFields()

        // reset ratings
        const difficultyRange = f7.range.get('#difficulty-rating-range');
        const overallRange = f7.range.get('#overall-rating-range')
        difficultyRange.setValue(0)
        overallRange.setValue(0)

        // reset servings, prep time and cook time
        setServings(1)
        setPrepTime(5)
        setCookTime(5)
        document.getElementsByClassName('recipe-image')[0].files = null;
        
        // Remove all extra ingredients and instruction steps
        recipeIngredients = []
        recipeInstructions = []
        addInstructionLine({
            description : ''
        })
        addIngredientLine({
            name : '',
            quantity : '',
            unit : ''
        });

        recipeImage.set(null);
    }

    function handleCloseRecipeCreation(){
        // Confirm if the user actually wants to close, all progress will be lost
        f7.dialog.confirm('Are you sure you want to cancel recipe creation? All unsaved progress will be lost.', () => {
            // Close recipe creation page
            f7.popup.close('.recipe-editor')
        });
    }

    async function fillRecipeWithPlaceholderBlocks(input){
        let template = {
            "recipe-title" : "██ ███████ ███",
            "recipe-description" : "█████ ███ █ ████████ ██ █ █████ ████ ███ ███████ ███ ████████ ████ ███ █ ███ ████ █████ ███████",
            "ingredient-name" : "██ ██ █████ █ ███████",
            "ingredient-quantity" : "███",
            "ingredient-unit" : "███████",
            "instruction-step" : "██ ████ ███ █ ███████"
        }

        // Get current user's name
        const userData = await getUserData()

        /* Disabling writing out the placeholder title because in a lot of situations, what you type into the description field
        // doesn't always make sense to be seen as a title
        if(input && input.searchInput){
            template['recipe-title'] = `${getUserFirstLastName($userDataStore).firstName}'s ${input.searchInput} recipe`;
        }
        */

        function delay(ms) {
            return new Promise(resolve => setTimeout(resolve, ms));
        }

        for(let key in template){
            if(template.hasOwnProperty(key)) { // this check is to make sure we're only accessing properties directly on the object
                // Set input color to light grey
                f7.$(`.${key}`).css('color', 'rgba(0,0,0,0.3)')
                // Loop through each letter within the value and send it to the appropriate text field
                for(let char of template[key]){
                    await delay(50)
                    if(!recipeGenerating){
                        return // stop running when recipe generating is complete
                    }
                    
                    let currentValue = f7.$(`.${key}`).val() ? f7.$(`.${key}`).val() : ''

                    f7.$(`.${key}`).val(currentValue + char)

                    autoExpand({target : f7.$(`.${key}`)[0]})
                }
            }
        }
    }

    function clearInputFields(){
        // Disabling this method for clearing inputs as it seems to not play nicely with
        // SVELTE input bindings
        /*
        for(const input of inputClasses){
            // f7.$(`.${input}`).val('');
            autoExpand({target : f7.$(`.${input}`)[0]})
        }
        */

        recipeTitle = '';
        recipeDescription = '';
        recipeIngredients = JSON.parse(JSON.stringify(recipeIngredientBlank));
        recipeInstructions = JSON.parse(JSON.stringify(recipeInstructionBlank));
        overallRating = 1;
        difficultyRating = 1;
        servings = 1;
        prepTime = 5;
        cookTime = 5;
        recipeImage.set(defaultImage);

        // Trigger resize for all textareas to ensure boxes are the right size
        f7.$('.recipe-editor').find('textarea').each(function(){
            autoExpand({target : this})
        })
    }

    function computeTime(value) {
        const hours = Math.floor(value / 60);
        const minutes = value - hours * 60;
        const formatted = [];
        if (hours > 0) {
            formatted.push(`${hours} ${hours > 1 ? 'hours' : 'hour'}`);
        }
        if (minutes > 0) {
            formatted.push(`${minutes} minutes`);
        }
        return formatted.join(' ');
    }

    function setServings(value) {
        servings = value;
    }

    function setPrepTime(value) {
        prepTime = value;

        prepTimeComputed = computeTime(value)
    }

    function setCookTime(value) {
        cookTime = value;

        cookTimeComputed = computeTime(value)
    }

    function addIngredientLine(dataToAdd){
        // Make a copy of array
        let newRecipeIngredientsBlank = JSON.parse(JSON.stringify(recipeIngredientBlank));

        if(dataToAdd){
            // merge the two objects
            newRecipeIngredientsBlank[0] = {...newRecipeIngredientsBlank[0], ...dataToAdd}
            delete newRecipeIngredientsBlank[0].isTrusted; // Remove field being added by + New Ingredient field which causes errors
        }

        recipeIngredients = [...recipeIngredients, ...newRecipeIngredientsBlank]
    }

    function addInstructionLine(dataToAdd){
        // Make a copy of array
        let newRecipeInstructionsBlank = JSON.parse(JSON.stringify(recipeInstructionBlank));

        if(dataToAdd){
            // merge the two objects
            newRecipeInstructionsBlank[0] = {...newRecipeInstructionsBlank[0], ...dataToAdd}
            delete newRecipeInstructionsBlank[0].isTrusted; // Remove field being added by + New Instruction field which causes errors
        }

        recipeInstructions = [...recipeInstructions, ...newRecipeInstructionsBlank]
    }

    async function handleSortIngredients(change){
        const detail = change.detail[0]

        let savedArray = [...recipeIngredients];
        recipeIngredients = [] // Reset ingredients sortable view
        await tick(); // Wait for the DOM update

        recipeIngredients = moveItemInArray(savedArray, detail.from, detail.to)

    }

    async function handleSortInstructions(change){
        const detail = change.detail[0]

        let savedArray = [...recipeInstructions];
        recipeInstructions = [] // Reset ingredients sortable view
        await tick(); // Wait for the DOM update

        recipeInstructions = moveItemInArray(savedArray, detail.from, detail.to)
    }

    function moveItemInArray(array, from, to) {
        // Make a copy of array
        let newArray = [...array];
        
        // Check if 'from' and 'to' are valid indices
        if (from < 0 || from >= newArray.length || to < 0 || to >= newArray.length) {
            return array;
        }

        // Remove item from the 'from' position
        let item = newArray.splice(from, 1)[0];
        
        // Add the item back at the 'to' position
        newArray.splice(to, 0, item);

        return newArray;
    }

    function handleRemoveIngredient(indexToRemove){
        f7.dialog.confirm('Are you sure you want to remove this ingredient item?', () => {
            recipeIngredients = recipeIngredients.filter((_, index) => index !== indexToRemove);
        });
    }

    function handleRemoveInstruction(indexToRemove){
        f7.dialog.confirm('Are you sure you want to remove this instruction item?', () => {
            recipeInstructions = recipeInstructions.filter((_, index) => index !== indexToRemove);
        });
    }

    function parseToDecimal(str) {
        // Check if the string represents a fraction
        if (str.includes('/')) {
            const [numerator, denominator] = str.split('/');
            return parseFloat(numerator) / parseFloat(denominator);
        }

        // Otherwise parse the string to a float
        return parseFloat(str);
    }

    function validateSaveRecipe() {

        if(!recipeTitle) {
            return "Recipe title is required";
        }

        if(!recipeDescription) {
            return "Recipe description is required";
        }

        if(recipeIngredients.length === 0) {
            return "Atleast 1 ingredient is required";
        }

        let ingredientsValid = true;
        recipeIngredients.forEach( async (ingredient) => {
            if(ingredient.name == null){
                ingredientsValid = false;
                return;
            }
        });

        if(!ingredientsValid) {
            return "All ingredients require a name";
        }

        if(recipeInstructions.length === 0) {
            return "Atleast 1 instruction is required";
        }

        let instructionsValid = true;
        recipeInstructions.forEach( async (step) => {
            if(!step.description) {
                instructionsValid = false;
                return;
            }
        });

        if(!instructionsValid) {
            return "All instructions need to be filled out";
        }

        return null;
    }

    async function handleSaveRecipe(){
        
        const validationErrors = validateSaveRecipe();
        if (validationErrors) {
            f7.dialog.alert(validationErrors);
            return;
        }

        const preloaderMessage = isEdit ? 'Updating recipe...' : 'Creating recipe...';
        f7.dialog.preloader(preloaderMessage);

        const userData = await getUserData();

        // TODO: Run validation checks here to ensure that everything at least has 1 value before proceeding
        // Check to make sure ingredient quantity is of type integer
        let recipeData, recipeId
        if (!isEdit) {
            try {
                // Insert initial recipe object
                recipeData = await supabase
                .from('recipe')
                .insert([
                    {
                        name : recipeTitle,
                        description : recipeDescription,
                        image : $recipeImage,
                        serving_size : servings,
                        prep_time : prepTime,
                        cook_time : cookTime
                    }
                ])
                .select();

                if(recipeData.error){
                    f7.dialog.close();
                    throw new Error(recipeData.error)
                }

                recipeId = recipeData.data[0].id;
                f7.dialog.close();

            } catch(error){
                f7.dialog.close();
                f7.dialog.alert('There was an error creating the recipe.');
            }

        } else {
            recipeId = $selectedRecipe.id;
        }

        f7.dialog.preloader('Saving recipe details');
        try {
            // Handle insert of the Ingredients
            // Add in the recipe ID and the ordering of the ingredient
            const ingredients = recipeIngredients.map((obj, index) => ({ ...obj, recipe_id: recipeId, order: index}));


            // Convert any quantities into float integers
            for (let thisIngredient of ingredients) {
                // Remove extra fields not in the DB
                delete thisIngredient.added; 
                delete thisIngredient.foodId;
                delete thisIngredient.image;
                if (!thisIngredient.id) {
                    // Remove id when null for editting an Edamam recipe before saved
                    delete thisIngredient.id
                }
                if (thisIngredient.quantity !== null) {
                    thisIngredient.quantity = parseToDecimal(thisIngredient.quantity.toString());
                }
            }

            if (isEdit) {
                const originalIngredients = await supabase
                .from('recipe_ingredients')
                .select()
                .eq('recipe_id', recipeId);
            
            
                let updatedIngredientIds = recipeIngredients.map(a => a.id);

                // remove any ingredients no longer present
                for (const ingredient of originalIngredients.data) {
                    if(!updatedIngredientIds.includes(ingredient.id)) {
                        await supabase
                        .from('recipe_ingredients')
                        .delete()
                        .eq('id', ingredient.id);
                    }
                }

                const updateRecipeRequest = await supabase
                .from('recipe')
                .update(
                    {
                        name : recipeTitle,
                        description : recipeDescription,
                        image : $recipeImage,
                        serving_size : servings,
                        prep_time : prepTime,   
                        cook_time : cookTime
                    }
                )
                .eq('id', recipeId);
                 
                recipeIngredients.forEach( async (ingredient, index) => {
                    ingredient.order = index;
                    ingredient.recipe_id = recipeId;
                    if (ingredient.id) {
                        delete ingredient.added; // Remove this field, not sure where it comes from but it causes errors
                        const { error } = await supabase
                        .from('recipe_ingredients')
                        .update(ingredient)
                        .eq('id', ingredient.id);

                        if(error) {
                            throw new Error(error);
                        }
                    } else {
                        const { error } = await supabase
                        .from('recipe_ingredients')
                        .insert(ingredient); 

                        if(error) {
                            throw new Error(JSON.stringify(error));
                        }
                    }
                });
               
                const originalInstructions = await supabase
                    .from('recipe_steps')
                    .select()
                    .eq('recipe_id', recipeId);

                // If any steps have been removed then we need to delete them from the DB
                const deletedInstructions = originalInstructions.data.filter(step => {
                    return recipeInstructions.filter(newInstruction => {
                        return step.id === newInstruction.id;
                    }).length > 0 ? null : step; 
                });
                recipeInstructions.forEach( async (step, index) => {
                    step.order = index;
                    step.recipe_id = recipeId;
                    delete step.added; // Remove this field, not sure where it comes from but it causes errors
                    if (step.id) {
                        const { error } = await supabase
                        .from('recipe_steps')
                        .update(step)
                        .eq('id', step.id);

                        if(error) {
                            throw new Error(error);
                        }
                    } else {
                        const { error } = await supabase
                        .from('recipe_steps')
                        .insert(step);

                        if(error) {
                            throw new Error(JSON.stringify(error));
                        }
                    }
                });

                
                deletedInstructions.forEach( async (step) => {
                    const { error } = await supabase
                        .from('recipe_steps')
                        .delete()
                        .eq('id', step.id);

                        if(error) {
                            throw new Error(JSON.stringify(error));
                        }
                });

                const updateRecipeRatingsRequest = await supabase
                .from('recipe_ratings')
                .update([
                    {
                        personal_rating : overallRating,
                        difficulty_rating : difficultyRating
                    }
                ])
                .eq('recipe_id', recipeId);

                if (updateRecipeRequest.error || updateRecipeRatingsRequest.error) {
                    throw new Error(updateRecipeRequest.error || updateRecipeRatingsRequest.error);
                }

                f7.dialog.close(); // For some reason it needs it here to close for edit
            } else {
                const addRecipeIngredientsRequest = await supabase
                .from('recipe_ingredients')
                .insert(ingredients);

                // Handle insert of the Steps
                // Add the Recipe ID and the ordering of the step
                const instructions = recipeInstructions.map((obj, index) => ({ ...obj, recipe_id: recipeId, order: index}));
                const addRecipeInstructionsRequest = await supabase
                .from('recipe_steps')
                .insert(instructions);

                // Handle insert of the Rating
                const addRecipeRatingsRequest = await supabase
                .from('recipe_ratings')
                .insert([
                    {
                        recipe_id : recipeId,
                        personal_rating : overallRating,
                        difficulty_rating : difficultyRating
                    }
                ]);

                f7.dialog.close();

                if(addRecipeIngredientsRequest.error || addRecipeInstructionsRequest.error || addRecipeRatingsRequest.error){
                    throw new Error(addRecipeIngredientsRequest.error || addRecipeInstructionsRequest.error || addRecipeRatingsRequest.error)
                }
                
            }

            const newRecipes = await getUserRecipes();
            userRecipes.set(newRecipes);
            if (isEdit) {
                let updatedRecipe = await getRecipeDetails($selectedRecipe.id, {});
                selectedRecipe.set(updatedRecipe)
            }
            f7.dialog.close();
            const toastMessage = isEdit ? `Recipe updated!` : `Recipe successfully created!`
            showToast(toastMessage)

            clearAllFields()
            

            f7.popup.close('.recipe-editor')

            if(!isEdit){
                // User has just created a new recipe, so open the recipe details popup
                let recipeData = await getRecipeDetailsFromId({
                    recipeId,
                    isExternal : false
                })

                selectedRecipe.set(recipeData);

                f7.views.current.router.navigate(`/recipe/`, {
                    props: {
                        addToMealPlan: true
                    },
                });
            }

        } catch(error){
            f7.dialog.close();
            f7.dialog.alert(`There was an error saving recipe details. ${JSON.stringify(error)}`);

            Sentry.captureException(error);

            // TODO: Delete the originally linked recipe object if there was an error creating it for any reason
            // Deletion of the root recipe object will cascade deletions to the other linked items
        }
    }

    function handleImageChange(e) {
        // Trigger hidden input click for image upload
        // note will only trigger for new images, to avoid duplicates
        document.getElementById('image-upload').click(e);
    }

    async function uploadImage(e) {
        const file = e.target.files[0];
        if (file) {
           const path = await uploadRecipeImage(file);
           recipeImage.set(path);
           if(document.getElementsByClassName('recipe-image')[0]) {
                document.getElementsByClassName('recipe-image')[0].style.backgroundImage = `url(${path})`;
            }
        } else {
            f7.dialog.alert('There was an error getting the image.');
        }
    }

    function openZestyplanAssistantWithEditRecipeIntent(text){
        let currentRecipe;
        selectedRecipe.subscribe(value=>{
            currentRecipe = value;
        })();

        f7.views.current.router.navigate(`/zestyplan-assistant-chat-window/`, {
          props: {
            initialMessage : text,
            recipeContext : currentRecipe
          },
      });
    }
</script>

<Popup push class="recipe-editor" on:popupOpen={initRecipeCreation} on:popupClose={clearAllFields}>
    <View>
        <Page>
            <Navbar sliding={false}>
                <NavLeft>
                    <Link on:click={handleCloseRecipeCreation}>
                        Cancel
                    </Link>
                </NavLeft>
                <NavTitle sliding>{isEdit ? 'Edit recipe' : 'Create recipe'}</NavTitle>
            </Navbar>
            <div style="padding-top: 20px;">
                <Block style="margin: 0; padding-bottom: 140px">
                    <div class="recipe-editor-title-row">
                        <!-- svelte-ignore a11y-click-events-have-key-events -->
                            <div class="recipe-image" on:click={handleImageChange}>
                                {#if !imageGenerating}
                                    <CameraIcon size="28"/>
                                {:else}
                                    <p class="loading-text">Loading...</p>
                                {/if}
                            </div>
                        <input class="hidden" id="image-upload" on:change={uploadImage} type="file" accept=".png, .jpg"/>
                        <div class="recipe-editor-input no-padding">
                            <div class="input-header">Recipe Title</div>
                            <div class="list" style="margin: 0;">
                                <div class="input-field no-padding">
                                    <textarea id="recipe-title" class="recipe-title" on:input={autoExpand} bind:value={recipeTitle} placeholder="Your recipe title here" style="font-size: 18px;"></textarea>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="recipe-editor-input">
                        <div class="input-header">Description</div>
                        <div class="list" style="margin: 0;">
                            <div class="input-field outline">
                                <textarea id="recipe-description" class="recipe-description" on:input={autoExpand} bind:value={recipeDescription} placeholder="Describe your amazing creation."></textarea>
                            </div>
                        </div>
                    </div>
                    <div class="recipe-editor-input">
                        <div class="input-header">Ingredients</div>
                        <List dividersIos sortable sortableEnabled sortableOpposite on:sortableSort={handleSortIngredients} style="margin: 0px;">
                            {#each recipeIngredients as ingredient, index (index)}
                                    <li class="ingredient-item">
                                        <!-- svelte-ignore a11y-click-events-have-key-events -->
                                        <div style="opacity: 0.4; text-align: right; padding-right: 15px; margin-bottom: -10px;" on:click={handleRemoveIngredient(index)}>Remove</div>
                                        <div class="item-content">
                                            <div class="item-inner">
                                                <div style="display: flex; flex-direction: column; width: 100%;">
                                                    <div class="input-field outline">
                                                        <textarea class="ingredient-name" on:input={autoExpand} placeholder="Ingredient title" bind:value="{recipeIngredients[index].name}"></textarea>
                                                    </div>
                                                    <div style="display: flex; gap: 10px;">
                                                        <div class="input-field outline">
                                                            <textarea class="ingredient-quantity" on:input={autoExpand} placeholder="Amount" bind:value="{recipeIngredients[index].quantity}"></textarea>
                                                        </div>
                                                        <div class="input-field outline">
                                                            <textarea class="ingredient-unit" on:input={autoExpand} placeholder="Unit" bind:value="{recipeIngredients[index].unit}"></textarea>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="sortable-handler"></div>
                                    </li>
                                {/each}
                              <!-- svelte-ignore a11y-click-events-have-key-events -->
                              <li on:click={() => addIngredientLine()}>
                                <div class="item-content">
                                  <div class="item-inner">
                                    <div class="item-title">+ add ingredient</div>
                                  </div>
                                </div>
                                <div class="sortable-handler" style="display: none;"></div>
                              </li>
                        </List>
                    </div>
                    <div class="recipe-editor-input">
                        <div class="input-header">Instructions</div>
                        <List dividersIos sortable sortableEnabled sortableOpposite on:sortableSort={handleSortInstructions} style="margin: 0px;">
                            {#each recipeInstructions as instruction, index (index)}
                                <li class="instruction-item">
                                    <div style="position: absolute; display: flex; width: 100%; justify-content: space-between; box-sizing: border-box; padding-right: 15px;">
                                        <div style="font-weight: 700;">Step {index + 1}</div>
                                        <!-- svelte-ignore a11y-click-events-have-key-events -->
                                        <div style="opacity: 0.4;" on:click={handleRemoveInstruction(index)}>Remove</div>
                                    </div>
                                    <div class="item-content" style="padding-top: 15px;">
                                        <div class="item-inner">
                                            <div style="display: flex; flex-direction: column; width: 100%;">
                                                <div class="input-field outline">
                                                    <textarea class="instruction-step" on:input={autoExpand} placeholder="Instruction text" bind:value="{recipeInstructions[index].description}"></textarea>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="sortable-handler"></div>
                                </li>
                            {/each}
                            <!-- svelte-ignore a11y-click-events-have-key-events -->
                            <li on:click={() => addInstructionLine()}>
                                <div class="item-content">
                                    <div class="item-media"><i class="icon icon-f7"></i></div>
                                    <div class="item-inner">
                                        <div class="item-title">+ add instruction</div>
                                    </div>
                                </div>
                                <div class="sortable-handler" style="display: none;"></div>
                            </li>
                        </List>
                    </div>
                    <div class="recipe-editor-input">
                        <div class="input-header">Overall Rating</div>
                        <Block>
                            <Range
                              min={1}
                              max={5}
                              label={true}
                              step={1}
                              bind:value={overallRating}
                              scale={true}
                              scaleSteps={4}
                              id="overall-rating-range"
                            />
                        </Block>
                    </div>
                    <div class="recipe-editor-input">
                        <div class="input-header">Difficulty Rating</div>
                        <Block>
                            <Range
                              min={1}
                              max={5}
                              label={true}
                              step={1}
                              bind:value={difficultyRating}
                              scale={true}
                              scaleSteps={4}
                              id="difficulty-rating-range"
                            />
                        </Block>
                    </div>
                    <div class="recipe-editor-input">
                        <div class="input-header">Servings</div>
                        How many servings will this recipe yield?
                        <List style="margin: 0;">
                            <ListItem title={servings}>
                              <span slot="after">
                                <Stepper buttonsOnly={true} min={1} value={servings} large fill raised onStepperChange={setServings} />
                              </span>
                            </ListItem>
                          </List>
                    </div>
                    <div class="recipe-editor-input">
                        <div class="input-header">Prep time</div>
                        How long does it take to prepare for this recipe?
                        <List style="margin: 0;">
                            <ListItem title={prepTimeComputed}>
                              <span slot="after">
                                <Stepper
                                  min={5}
                                  max={240}
                                  step={5}
                                  value={prepTime}
                                  buttonsOnly={true}
                                  large
                                  fill
                                  raised
                                  onStepperChange={setPrepTime}
                                />
                              </span>
                            </ListItem>
                          </List>
                    </div>
                    <div class="recipe-editor-input">
                        <div class="input-header">Cook time</div>
                        How long does it take to cook this recipe?
                        <List style="margin: 0;">
                            <ListItem title={cookTimeComputed}>
                              <span slot="after">
                                <Stepper
                                  min={5}
                                  max={240}
                                  step={5}
                                  value={cookTime}
                                  buttonsOnly={true}
                                  large
                                  fill
                                  raised
                                  onStepperChange={setCookTime}
                                />
                              </span>
                            </ListItem>
                          </List>
                    </div>
                </Block>
            </div>
            <div class="static-floating-footer">
                {#if !recipeGenerating}
                    <ZestyplanAssistantInput 
                        onSendMessage = {openZestyplanAssistantWithEditRecipeIntent}
                        messageBarPlaceholder = "Ask Zestyplan for help"
                        blurInputOnSend = {true}
                    />
                    <Button large fill on:click={handleSaveRecipe}>Save recipe</Button>
                    <div style="text-align: center; opacity: 0.4; font-size: 12px;">
                        Recipe will be saved to your recipe collection
                    </div>

                {:else}
                    <div style="text-align: center;">
                        <Preloader size={42} />
                        <div style="padding: 5px;">
                            Your recipe is being generated!
                        </div>
                    </div>
                {/if}
            </div>
        </Page>
        <Sheet backdrop swipeToClose class="ai-generated-recipe-modal" style="
            height: auto; 
            border-radius: 16px 16px 0 0;">
            <div class="sheet-close-button">
                <Link sheetClose style="">
                    <i class="fa fa-xmark navbar-icon"></i>
                </Link>
            </div>
            <Block style="margin-top: 40px;">
                <div class="onboarding-modal-media">
                    🪄
                </div>
                <div class="onboarding-modal-title" style="text-align: center;">
                    Here is your custom recipe!
                </div>
                <div class="onboarding-modal-text" style="text-align: center;">
                    Created by Zestyplan, just for you. You can edit and perfect it, then share it with your friends!<br><br>
                </div>
                <div style="text-align: -webkit-center;">
                    <Button large round fill sheetClose>Got it</Button>
                </div>
            </Block>
        </Sheet>
    </View>
</Popup>

<style>
    .recipe-image {
        min-width: 75px;
        min-height: 75px;
        max-width: 75px;
        max-height: 75px;
        background-size: cover;
        background-position: center;
        border-radius: 8px;
        border-style: solid;
        border-color: #e3e3e3;
        color: #e3e3e3;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .recipe-editor-title-row {
        display: flex;
        justify-content: flex-start;
    }

    .recipe-editor-title-row div {
        margin-right: 10px;
    }

    .recipe-editor-input {
        width: 100%;
        border-bottom-style: solid;
        border-bottom-color: var(--f7-input-info-text-color);
        border-width: 1px;
        padding-top: 20px;
        padding-bottom: 20px;
    }

    .no-padding {
        padding: 0px !important;
    }

    .outline {
        border-radius: 8px;
        border-style: solid;
        border-color: var(--f7-input-info-text-color);
        border-width: 1px;
        padding: 5px;
    }

    .input-header {
        /* color: var(--f7-input-info-text-color); */
        font-weight: 700;
        font-size: 18px;
    }

    .input-field {
        margin-top: 5px;
        padding: 10px;
        box-sizing: border-box;
        width: 100%;
    }

    .input-field textarea {
        width: 100%;
        overflow-y: hidden;
        resize: none;
        height: 1.2em;
        min-height: 1.2em;
        padding: initial;
    }
</style>