<Page name="password-reset">
  <Navbar title="Password Reset" backLink="Back" />
  <Block strong inset>
    <List form>
      <ListInput
      outline
      label="Password"
      floatingLabel
      type="password"
      placeholder='New password, minimum 6 characters'
      clearButton
      name="password"
      bind:value={password}
    />
  </List>
  </Block>
  <Block>
    <Button large fill onClick={() => updatePassword(password)}>Update Password</Button>
  </Block>
</Page>
<script>
  import { Page, Navbar, Block, ListInput, Button, List } from 'framework7-svelte';
  import { updatePassword } from '../js/supabase'
  let password = '';
</script>
