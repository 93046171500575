<script>
    import { onMount, onDestroy } from "svelte";
    import {
        f7,
        Page,
        BlockTitle,
        Sheet,
        Chip,
        Messages,
        Message,
        MessagesTitle,
        Messagebar,
        Icon,
        MessagebarAttachments,
        MessagebarAttachment,
        MessagebarSheet,
        MessagebarSheetImage,
        f7ready,
    } from "framework7-svelte";

    onMount(() => {
        f7ready(() => {
            messagebarInstance = f7.messagebar.get(".messagebar");
        });
    });

    onDestroy(() => {
        // Clean up any event listeners or timeouts when the component is destroyed
    });

    export let onSendMessage;
    export let messageBarPlaceholder = "Type a message...";
    export let blurInputOnSend = false; // Blur the input after sending a message

    let messagebarInstance;

    let attachments = [];
    let sheetVisible = false;
    let typingMessage = null;
    let messageText = "";
    $: displaySendButton = messageText.length > 0 ? true : false;

    $: attachmentsVisible = attachments.length > 0;


    function deleteAttachment(image) {
        const index = attachments.indexOf(image);
        attachments.splice(index, 1);
        attachments = attachments;
    }

    function handleAttachment(e) {
        const index = f7.$(e.target).parents("label.checkbox").index();
        const image = images[index];
        if (e.target.checked) {
            // Add to attachments
            attachments.unshift(image);
        } else {
            // Remove from attachments
            attachments.splice(attachments.indexOf(image), 1);
        }
        attachments = attachments;
    }

    function sendMessage() {
        const text = messageText.replace(/\n/g, "<br>").trim();

        // Reset attachments
        attachments = [];
        // Hide sheet
        sheetVisible = false;
        // Clear
        messageText = "";
        messagebarInstance.clear();

        if (blurInputOnSend) {
            // Blur focus
            messagebarInstance.blur();
        } else {
            // Focus area
            if (text.length) messagebarInstance.focus();
        }

        // Call the callback function passed from the parent
        if (onSendMessage) {
            onSendMessage(text);
        }
    }
</script>

<!-- svelte-ignore a11y-click-events-have-key-events -->
<Messagebar
    class="zestyplan-assistant-input"
    placeholder={messageBarPlaceholder}
    {attachmentsVisible}
    {sheetVisible}
    value={messageText}
    onInput={(e) => (messageText = e.target.value)}
>
    <!-- svelte-ignore a11y-click-events-have-key-events -->
    <!-- svelte-ignore a11y-missing-attribute -->
    <!-- disabled as we aren't using attachments yet
    <a
        class="link icon-only"
        slot="inner-start"
        on:click={() => (sheetVisible = !sheetVisible)}
    >
        
        <Icon ios="f7:camera_fill" md="material:camera_alt" />
    </a>
    -->

    <!-- svelte-ignore a11y-click-events-have-key-events -->
    <!-- svelte-ignore a11y-missing-attribute -->
    <a class="link icon-only send-message-button {displaySendButton ? '' : 'send-hidden'}" slot="after-inner" on:click={sendMessage}>
        <!-- svelte-ignore missing-declaration -->
        <Icon ios="f7:arrow_up_circle_fill" md="material:send" />
    </a>
    <div slot="before-area" style="display: flex;">
        <!-- svelte-ignore a11y-missing-attribute -->
        <img src="images/icons/magic_sparkle.svg"/>
    </div>
    <!-- disabled for now as we aren't using attachments
    <MessagebarAttachments>
        {#each attachments as image, index (index)}
            <MessagebarAttachment
                key={index}
                {image}
                onAttachmentDelete={() => deleteAttachment(image)}
            />
        {/each}
    </MessagebarAttachments>
    <MessagebarSheet>
        {#each images as image, index (index)}
            <MessagebarSheetImage
                key={index}
                {image}
                checked={attachments.indexOf(image) >= 0}
                onChange={handleAttachment}
            />
        {/each}
    </MessagebarSheet>
    -->
</Messagebar>

<style>
    :global(.zestyplan-assistant-input) {
        margin-bottom: 10px;
        padding-bottom: unset;
    }

    :global(.zestyplan-assistant-input .messagebar-area textarea) {
        border: 0;
    }

    :global(.zestyplan-assistant-input .messagebar-area) {
        background-color: var(--f7-segmented-strong-bg-color);
        align-items: center;
        padding: 5px;
        border-radius: 8px;
    }

    :global(.zestyplan-assistant-input .messagebar-area) {
        display: flex;
    }

    :global(.zestyplan-assistant-input .toolbar-inner) {
        padding: 0;
    }

    :global(.zestyplan-assistant-input .messagebar-area .send-message-button) {
        display: block !important;
    }

    :global(.send-hidden) {
        opacity: 0;
        pointer-events: none;
    }

    :global(.send-message-button) {
        display: none !important;
    }

    :global(.zestyplan-assistant-chat-window .zestyplan-assistant-input) {
        box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.1);
        padding: 10px;
        border-radius: 8px 8px 0 0;
    
    }

    :global(.zestyplan-assistant-input) {
        background-color : unset !important;
    }

</style>
