<Page name="Update Profile Email">
    <Navbar title="Update Email" backLink="Back" />
    <Block>
      <p>Changing your email will require you to confirm this change on the new email address.</p>
      <p>You will also be logged out, you will need to verify the new email account to log back in.</p>
    </Block>
    <Block strong inset>
      <List form>
        <ListInput
        outline
        label="Email"
        floatingLabel
        type="text"
        placeholder='Email'
        clearButton
        name="email"
        bind:value={email}/>
    </List>
    </Block>
    <Block>
      <Button large fill onClick={() => updateUserEmail(email)}>Update Email</Button>
    </Block>
  </Page>
  <script>
    import { Page, Navbar, Block, ListInput, Button, List } from 'framework7-svelte';
    import { updateUserEmail } from '../js/supabase'
    let email = '';
  </script>
  