
    <!-- Phantom placeholder button item clone to allow for clipping of title properly -->
<div style="
    display: flex;
    align-items: center;
    {
        placeholder ? 
        `
            pointer-events: none; 
            opacity: 0;
        `
        : 
        `
            float: right;
            position: absolute;
            right: 0;
            top: -100%;
        `
    }
">
    <!-- svelte-ignore a11y-click-events-have-key-events -->
    <div style="display: flex; flex-direction: row-reverse;" on:click={ placeholder ? null : onClickUsers }>
        {#if planCollaboratorList}
            {#each planCollaboratorList as collaborator, index }
                {#if collaborator.profile_id !== userData.id && collaborator.profiles['first_name']}
                    <!-- Only showing up to 2 names, then it goes to elipsis -->
                    {#if index < 3}
                        <div class="collaborator-bubble" style="z-index: {index}; {index > 0 ? "margin-right: -15px;" : ""}">
                            { collaborator.profiles['first_name'][0].toUpperCase() }
                        </div>
                    {:else if index == 3}
                        <div class="collaborator-bubble" style="z-index: {index}; {index > 0 ? "margin-right: -15px;" : ""}">
                            ...
                        </div>
                    {/if}
                {/if}
            {/each}
        {/if}
    </div>
    
    <Link style="margin-right: 10px" on:click={ placeholder ? null : onClickUsers }>
        <UsersIcon size="24"/> 
    </Link>
    {#if page == 'home'}
        <Link iconIos="f7:ellipsis_circle" iconMd="material:more_vert" popoverOpen={ placeholder ? null : ".home-popover-menu" }/>    
    {:else if page == 'groceries'}
        <Link iconIos="f7:ellipsis_circle" iconMd="material:more_vert" popoverOpen={ placeholder ? null : ".groceries-popover-menu" }/>
    {:else if placeholder == true}
        <!-- Placeholder button -->
        <Link iconIos="f7:ellipsis_circle" iconMd="material:more_vert"/>
    {/if}
    
</div>
{#if !placeholder}
    <Collaborators />
{/if}
<script>
    import { f7, Link } from 'framework7-svelte';
    import { UsersIcon } from 'svelte-feather-icons'
    import Collaborators from '../components/Collaborators.svelte';
    import { plan } from "../stores/mealPlan";
    import { userData, getUserData } from "../stores/userInfo";

    export let placeholder; // Determines if the module is a phantom clone just for placeholder purposes
    export let page;

    let planCollaboratorList = [];

    function onClickUsers(){
        f7.popup.open('.collaborators');
    }

    $:onPlanChange($plan)
    async function onPlanChange(value) {
        if(!$plan || !$plan.members){
            return;
        }

        // Gather the collaborators for this plan

        const currentUserData = await getUserData()

        // Reset planCollaboratorList
        planCollaboratorList = [];

        for(const collaborator of $plan.members){
            // If collaborator is not the current user, and has a first name...
            if(collaborator.profile_id !== currentUserData.id && collaborator.profiles['first_name']){
                let currentCollaborators = planCollaboratorList;
                currentCollaborators.push(collaborator);
                planCollaboratorList = currentCollaborators;
            }
        }
    }
</script>
<style>

.collaborator-bubble {
    display: flex;
    justify-content: center;  /* Horizontally center */
    align-items: center;      /* Vertically center */
    background: var(--f7-theme-color);
    width: 30px;
    height: 28px;
    border-radius: 50%;
    padding-top: 2px;
    color: #fff;
    font-weight: 700;
    margin-right: 5px;
    border-color: var(--f7-page-bg-color);
    border-style: solid;
    border-width: 2px;
}

</style>