import { writable } from 'svelte/store';
import { getUserPlanBucketName, supabase } from "../js/supabase"
import { getUserData, getUserFirstLastName, userDataStore } from './userInfo';
import { convertDayToDateInWeek, startOfWeek, removeTimezone, getBeginningOfWeek, getEndOfWeek, showToast, offsetTimezone } from '../js/generalHelpers';
import { formatExternalRecipe, getWeekRange, formatUserRecipe } from '../js/mealPlanHelpers';

const defaultMealPlanData = {
    "Monday" : [],
    "Tuesday" : [],
    "Wednesday" : [],
    "Thursday" : [],
    "Friday" : [],
    "Saturday" : [],
    "Sunday" : [],
}

export const daysOfTheWeek = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"]

export const selectedRecipe = writable();
export const plan = writable();
export const plans = writable([]);
export const memberPlans = writable([]);
export const adminPlans = writable([]);
export let newInvitation = writable();
export let pendingInvitations = writable([]);
export const userRecipes = writable();
export const recipeImage = writable();
export const planRecipes = writable();

// Check and see if there is a local cache available for a fast start
export const mealPlanStore = writable(JSON.parse(localStorage.getItem('mealPlanData')) || defaultMealPlanData);
export default mealPlanStore;

export const jsDateDayMap = {
    "Monday": 0,
    "Tuesday": 1,
    "Wednesday": 2,
    "Thursday": 3,
    "Friday": 4,
    "Saturday": 5,
    "Sunday": 6,
    0 : "Monday",
    1 : "Tuesday",
    2 : "Wednesday",
    3 : "Thursday",
    4 : "Friday",
    5 : "Saturday",
    6 : "Sunday",
};

export const dayToDateMap = {
    1 : "Monday",
    2 : "Tuesday",
    3 : "Wednesday",
    4 : "Thursday",
    5 : "Friday",
    6 : "Saturday",
    0 : "Sunday",
}

export let currentMealPlanStartDate = writable(startOfWeek(new Date(), "Monday")) // Starting the week on Monday

document.addEventListener('planRecipeUpdate', async function(event){
    // On change to plan_recipe table get current recipes for start date
    let currentStartDate;
    currentMealPlanStartDate.subscribe(value=>{
        currentStartDate = value;
    })();

    let currentPlan;
    plan.subscribe(value => {
        currentPlan = value;
    })

    if (currentStartDate && currentPlan) {
        await getPlanRecipesForPlan(currentPlan.id, currentStartDate);
    }
});


// TESTING TO SHOW THIS DATA IN CONSOLE FOR FUTURE FORMATTING PURPOSES

// TODO: Get current meal plan from server for this day and set it to the main writable

// Data needs to be in the format of:
// const data = {
//    'Monday' : []
//}
export const updateMealPlan = async function (data = {}){
    let currentValue;
    mealPlanStore.subscribe(value=>{
        currentValue = value;
    })();

    let currentPlanData;
    plan.subscribe(value=>{
        currentPlanData = value;
    })();

    const updatedValue = {...currentValue, ...data}

    mealPlanStore.set(updatedValue)

    localStorage.setItem('mealPlanData', JSON.stringify(updatedValue));

    await supabase.from('plan')
    .update({
        ...data
    })
    .eq('id', currentPlanData.id)
}

export async function addRecipeToMealPlan({day, recipe} = {}) {
    let mealPlanData;
    mealPlanStore.subscribe(value=>{
        mealPlanData = value;
    })();

    mealPlanData[day].push(recipe)

    mealPlanStore.set(mealPlanData)

    let currentStartDate;
    currentMealPlanStartDate.subscribe(value=>{
        currentStartDate = value;
    })();

    let currentPlan;
    plan.subscribe(value => {
        currentPlan = value;
    })

    if (currentStartDate) {
        const recipeDate = convertDayToDateInWeek(day, currentStartDate);
        const newDate = removeTimezone(recipeDate);

        let recipeType = "internal";
        if (recipe.uri) { recipeType = "external" }
        else if (recipe.quickAdd) { recipeType = "quick_add" }
        const recipeId = recipeType === 'internal' ? recipe.id : null;
        const recipeUri = recipe.uri;
        const recipeName = recipe.label;
        const recipeImage = recipe.backgroundImage;
        const recipeImageUrl = recipe.backgroundImage;

        let currentPlanRecipes;
        planRecipes.subscribe(value => {
            currentPlanRecipes = value;
        });

        const recipesOnSameDay = currentPlanRecipes.filter(recipe => recipe.date === recipeDate.toISOString().split('T')[0]).length;
    
        await supabase.from('plan_recipe').insert({
            plan_id: currentPlan.id,
            date: newDate,
            recipe_id: recipeId,
            recipe_type: recipeType,
            recipe_uri: recipeUri,
            recipe_name: recipeName,
            recipe_image: recipeImage,
            recipe_order: recipesOnSameDay + 1,
            quick_add_emoji : recipe.emoji ? recipe.emoji : null
        })
        .eq('plan_id', currentPlan.id);

        if (recipeType === 'external') {
            const planRecipeId = planRecipeResponse.data[0].id;

            const localStorageItemId = supabase.storageKey
            
            const accessToken = JSON.parse(localStorage.getItem(localStorageItemId))['access_token'];

            const bucketName = await getUserPlanBucketName();

            const response = await supabase.functions.invoke(`cacheImage`, {
                body: {
                    imageUrl: recipeImageUrl,
                    planRecipeId: planRecipeId,
                    bucketName: bucketName
                },
                headers: {
                    'Authorization': `Bearer ${accessToken}`
                }
            });

            if (response.error) {
                throw new Error('Error getting recipe details');
            }
        }
    }
}

export const addPlanRecipeToMealPlan = async function ({day, planRecipe, planRecipeDate, planRecipeOrder} = {}){
    let mealPlanData;
    mealPlanStore.subscribe(value=>{
        mealPlanData = value;
    })();

    mealPlanData[day].push(planRecipe)

    mealPlanStore.set(mealPlanData)

    localStorage.setItem('mealPlanData', JSON.stringify(mealPlanData))

    const newDate = removeTimezone(planRecipeDate);

    let recipeOrder = planRecipeOrder;

    if (!recipeOrder) {
        recipeOrder = 1;

        const recipeOrderResponse = await supabase.from('plan_recipe')
        .select('recipe_order')
        .eq('date', newDate.toISOString())
        .eq('plan_id', planRecipe.plan_id)
        .order('recipe_order', {
            ascending: false
        });

        if (recipeOrderResponse.data && recipeOrderResponse.data.length > 0) {
            recipeOrder = recipeOrderResponse.data[0].recipe_order + 1;
        }
    }

    // Fix order of recipes after the selected order
    const planRecipesForDate = await supabase.from('plan_recipe')
    .select()
    .eq('date', newDate.toISOString())
    .eq('plan_id', planRecipe.plan_id)
    .gte('recipe_order', recipeOrder)
    .order('recipe_order', {
        ascending: true
    });

    if (!planRecipesForDate.error && planRecipesForDate.data && planRecipesForDate.data.length > 0) {
        for (const planRecipe of planRecipesForDate.data) {
            const planRecipeOrder = planRecipe.recipe_order;
            await supabase.from('plan_recipe')
            .update({
                recipe_order: planRecipeOrder + 1
            })
            .eq('id', planRecipe.id);
        }
    }

    const originalRecipe = await supabase.from('plan_recipe')
    .select()
    .eq('id', planRecipe.id);

    const planRecipeInsertResponse = await supabase.from('plan_recipe').insert({
        plan_id: planRecipe.plan_id,
        date: newDate,
        recipe_id: planRecipe.recipe_id,
        recipe_type: planRecipe.recipe_type,
        recipe_uri: planRecipe.recipe_uri,
        recipe_image: originalRecipe.data[0].recipe_image,
        recipe_name: planRecipe.recipe_name,
        recipe_order: recipeOrder,
        quick_add_emoji: planRecipe.quick_add_emoji,
    })
    .select();    

    if (planRecipeInsertResponse.data && planRecipeInsertResponse.data.length > 0) {
        const planRecipeId = planRecipeInsertResponse.data[0].id;
        const groceryItemsResponse = await supabase.from('grocery_item').select()
        .eq('plan_recipe_id', planRecipe.id);
    
        for (const groceryItem of groceryItemsResponse.data) {
            await supabase.from('grocery_item').insert({
                name: groceryItem.name,
                quantity: groceryItem.quantity,
                unit: groceryItem.unit,
                notes: groceryItem.notes,
                date: newDate,
                created_by: groceryItem.created_by,
                grocery_list_id: groceryItem.grocery_list_id,
                plan_recipe_id: planRecipeId,
            });
        }
    }
}

export const removePlanRecipeFromPlan = async function (item){
    let mealPlanData;
    mealPlanStore.subscribe(value=>{
        mealPlanData = value;
    })();

    const recipeDate = jsDateDayMap[new Date(item.date).getDay()];

    mealPlanData[recipeDate] = mealPlanData[recipeDate].filter(recipeForDay => recipeForDay.id !== item.id);

    mealPlanStore.set(mealPlanData)

    localStorage.setItem('mealPlanData', JSON.stringify(mealPlanData));

    await supabase.from('plan_recipe').delete().eq("id", item.id);

    // Fix order of recipes in the day of the removed recipe
    const planRecipesForDate = await supabase.from('plan_recipe')
    .select()
    .eq('date', item.date)
    .eq('plan_id', item.plan_id)
    .gt('recipe_order', item.recipe_order)
    .order('recipe_order', {
        ascending: true
    });

    if (!planRecipesForDate.error && planRecipesForDate.data && planRecipesForDate.data.length > 0) {
        for (const planRecipe of planRecipesForDate.data) {
            const planRecipeOrder = planRecipe.recipe_order;
            await supabase.from('plan_recipe')
            .update({
                recipe_order: planRecipeOrder - 1
            })
            .eq('id', planRecipe.id);
        }
    }
}

export async function updateRecipeDateAndOrder(planRecipe, newDate, newOrder) {
    const planRecipeDate = new Date(planRecipe.date);
    let newDateObj = new Date(newDate);
    
    // Remove timezone offset
    newDateObj = offsetTimezone(newDateObj);
    
    // Convert dates to a string that represents the date without time
    const newDateString = newDateObj.toISOString().split('T')[0];
    const planRecipeDateString = planRecipeDate.toISOString().split('T')[0];

    // Determine if the update is within the same day
    const isSameDay = planRecipeDateString === newDateString;
    const condition = newOrder < planRecipe.recipe_order ? 'gte' : 'lte';
    // const orderAdjustment = newOrder < planRecipe.recipe_order ? 1 : -1;

    let recipesToUpdate = [];

    if (isSameDay) {
        // Adjust orders within the same day
        recipesToUpdate = await supabase.from('plan_recipe')
            .select()
            .eq('date', newDateString)
            .eq('plan_id', planRecipe.plan_id)
            .neq('id', planRecipe.id)
            .order('recipe_order', { ascending: true });
    } else {
        // Adjust orders when moving to a different day
        // Note: This simplified logic may need adjustments based on your app's specific logic for handling order changes across different days.
        recipesToUpdate = await supabase.from('plan_recipe')
            .select()
            .eq('date', newDateString)
            .eq('plan_id', planRecipe.plan_id)
            .neq('id', planRecipe.id)
            .order('recipe_order', { ascending: true });
    }

    // Process the order updates
    let updatedRecipes = [];

    for (const item of recipesToUpdate.data || []) {
        updatedRecipes.push({ 
            date: item.date,
            recipe_order: item.recipe_order,
            id: item.id })
    }

    // Sort recipes by order
    updatedRecipes.sort((a,b) => {
        return (a.recipe_order > b.recipe_order) ? 1 : ((b.recipe_order > a.recipe_order) ? -1 : 0);} 
    ); 
    
    // Remove any spaces inside the list of orders by assigning by index
    let index = 1;
    for (const item of updatedRecipes) {
        item.recipe_order = index;
        index++;
    }

    // Modify all recipe orders to move around where the moved one
    for (const item of updatedRecipes) {
        const updatedRecipeOrder = item.recipe_order >= newOrder ? item.recipe_order + 1 : item.recipe_order - 1;
        item.recipe_order = updatedRecipeOrder;
    }

    // Add the new item
    updatedRecipes.push({
        date: newDateString, // Use the date string without time
        recipe_order: newOrder,
        id: planRecipe.id });

    for (const recipe of updatedRecipes) {
        await supabase.from('plan_recipe').update(
            { recipe_order: recipe.recipe_order, date: recipe.date })
            .eq('id', recipe.id);
    }
}


export async function initPlanForUser() {
    const userData = await getUserData();

    let userPlans = await supabase.from('plan').select('*, plan_member(*)');

    let isUserAdminOfAPlan = false;

    for (const userPlan of userPlans.data) {
        const isAdmin = userPlan.plan_member.find(planMember => planMember.profile_id === userData.id && planMember.role === 'ADMIN');

        if (isAdmin !== undefined) {
            isUserAdminOfAPlan = true;
            break;
        }
    }

    if (!isUserAdminOfAPlan) {
        const planName = userData['first_name'] && userData['last_name'] ?  `${userData['first_name']} ${userData['last_name']}'s Plan` : 'Plan';
        // User does not have any plans, let's make one for them
        const planCreateResponse = await supabase.from('plan').insert({
            created_by: userData.id,
            name: planName
        }).select();

        if (planCreateResponse.error) {
            throw new Error('Error creating new plan for user');
        }

        await supabase.from('plan_member').insert({
            plan_id: planCreateResponse.data[0].id,
            role: 'ADMIN',
            status: 'ACTIVE',
            profile_id: userData.id,
        });
    }
}

export async function getPlanRecipesForPlan(planId, weekStart, weekEnd) {

    const test = getWeekRange();
    if (!weekStart) {
        weekStart = test.weekStart;
    }

    if (!weekEnd) {
        weekEnd = test.weekEnd;
    }

    let planRecipeResponse = await supabase.from('plan_recipe')
    .select()
    .eq('plan_id', planId)
    .gte('date', getBeginningOfWeek(weekStart))
    .lte('date', getEndOfWeek(weekEnd))


    if (!planRecipeResponse.error) {
        // Disabling because this code probably didn't even work for shared meal plans because it's requesting a plan ID from supabase and
        // If the user is a member of multiple plans, or looking at a shared plan, it's going to just return a single ID which might/probably won't be the right one
        // const bucketName = await getUserPlanBucketName();
        let planRecipesData = planRecipeResponse.data;
    
        // Generate Supabase storage image URLs for recipe items
        for (const index in planRecipesData) {
            if(!planRecipesData[index].recipe_image){
                continue; // Skip this iteration
            }

            const recipe_image = planRecipesData[index].recipe_image;
            /* Disabling this as it's causing a number of console errors. Is it necessary?
            const { data, error } = await supabase
            .storage
            .from(bucketName)
            .createSignedUrl(recipe_image, 60)
            

            if (data) {
                planRecipesData[index].recipe_image = data.signedUrl;
            }
            */
        }

        planRecipesData.sort(function (a, b) {
            return a.date - b.date || a.recipe_order - b.recipe_order;
        });

        planRecipes.set(planRecipesData);
    }
}

export async function getPlansForUser(weekStart, weekEnd){
    const userData = await getUserData();

    if(!userData || !userData.id){
        return
    }

    if (!weekStart) {
        
        weekStart = offsetTimezone(getWeekRange().weekStart);
    }

    if (!weekEnd) {
        weekEnd = offsetTimezone(getWeekRange(weekStart).weekEnd);
    }

    let planResponse = await supabase.from('plan')
    .select('*, plan_recipe(*)')
    .gte('plan_recipe.date', weekStart.toISOString().split('T')[0])
    .lte('plan_recipe.date', weekEnd.toISOString().split('T')[0])

    if(!planResponse.data || planResponse.data.length == 0){
        // No plan data present
        return
    }
    
    for (let userPlan of planResponse.data) {
        const members = await getMembersOfPlan(userPlan.id);
        const isAdmin = members.find(member => member.profile_id === userData.id && member.role === 'ADMIN') !== undefined;

        const invitations = await getInvitationsForPlan(userPlan.id);

        userPlan.isAdmin = isAdmin;
        userPlan.members = members;
        userPlan.pendingInvitations = invitations.filter(invitation => invitation.status === 'PENDING');
        userPlan.declinedInvitations = invitations.filter(invitation => invitation.status === 'DECLINED');
    }

    const userAdminPlans = planResponse.data.filter(userPlan => userPlan.isAdmin);
    adminPlans.set(adminPlans)

    const selectedPlanId = localStorage.getItem('selected_plan_id');

    const selectedPlan = planResponse.data.find(userPlan => userPlan.id === selectedPlanId);

    const defaultPlan = selectedPlan || userAdminPlans[0];

    const plansAlreadyMember = planResponse.data.filter(userPlan => userPlan.members.find(member => member.profile_id === userData.id) !== undefined);

    // Get the current plan if set, else fall back to default
    let currentPlan;
    plan.subscribe(value => {
        currentPlan = value;
    })

    if (currentPlan) {
        await getPlanRecipesForPlan(currentPlan.id, weekStart, weekEnd);
    } else {
        await getPlanRecipesForPlan(defaultPlan.id, weekStart, weekEnd);    
    }

    return {
        plans: planResponse.data,
        plansAlreadyMember,
        plan: defaultPlan,
        adminPlans : userAdminPlans
    };
}

export async function fetchUserPlan(weekStart, weekEnd){
    // Runs once at the beginning of app init and if you switch plans
    getPlansForUser(weekStart, weekEnd)
    .then(response => {
        if(!response){
            // No plans for user yet
            return
        }

        const { plans: userPlans, plan: userPlan, plansAlreadyMember} = response;
        
        plans.set(userPlans);

        memberPlans.set(plansAlreadyMember);

        let currentUserPlan;
        plan.subscribe(value => {
            currentUserPlan = value;
        });

        if (currentUserPlan) {
            const updatedPlan = userPlans.find(userPlan => userPlan.id === currentUserPlan.id);

            if (updatedPlan !== undefined) {
            plan.set(updatedPlan);
            }
        }
        else {
            plan.set(userPlan);
        }
    })
}  

export async function getRecipeDetailsFromId({
    recipeId,
    isExternal,
    planRecipeDate, 
    planRecipeId
} = {}){
    const response = await supabase.functions.invoke(`recipe?recipe_id=${recipeId}&external=${isExternal}`, {
        method: "GET",
        headers: {
            'Content-Type': 'application/json',
        }
    });

    if (response.error) {
        if(response.error.context == 404){
            // Recipe not found
            showToast('Recipe not found.')
        } else {
            showToast('There was an error loading the recipe')
        }

        throw new Error(response)
    }

    let formattedRecipe;
    if (isExternal == 'true' || isExternal === true) {
        formattedRecipe = formatExternalRecipe(
            response.data,
            planRecipeDate ? planRecipeDate : null,
            planRecipeId ? planRecipeId : null
        );
    } else {
        formattedRecipe = await formatUserRecipe(
            response.data,
            planRecipeDate ? planRecipeDate : null,
            planRecipeId ? planRecipeId : null
        );
    }

    return formattedRecipe;
}

export async function getRecipeDetails(recipeId, item) {    
    if (item && item.recipe_type === "external") {
        const response = await supabase.functions.invoke(`recipe?recipe_id=${recipeId}`, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
            }
        });

        if (response.error) {
            throw new Error('Error getting recipe details');
        }

        const formattedRecipe = formatExternalRecipe(response.data, item.date, item.id);
        
        formattedRecipe.backgroundImage = item.recipe_image;

        return formattedRecipe;
    }

    const recipeResponse = await supabase.from('recipe').select(`*,
        recipe_ingredients(
            id,
            name,
            quantity,
            unit,
            order
        ),
        recipe_steps(
            id,
            description,
            order
        ),
        recipe_ratings(
            id,
            personal_rating,
            difficulty_rating
        )
    `).eq('id', recipeId);

    const recipe = recipeResponse.data[0];

    return await formatUserRecipe(
        recipe, 
        item?.date ?? null, 
        item?.id ?? null
    );
}

// Gets all recipes created by the current user
export async function getUserRecipes(){
    let userData = await getUserData();
    let thisData = await supabase
    .from('recipe')
    .select(`*,
      recipe_ingredients(
        id,
        name,
        quantity,
        unit,
        order
      ),
      recipe_steps(
        id,
        description,
        order
      ),
      recipe_ratings(
        id,
        personal_rating,
        difficulty_rating
      )
    `)
    .eq(
        'created_by', userData.id
    )
    .order(
        'updated_at',
        {
            ascending: false
        }
    );

    return thisData.data
}

export async function clearRecipesForWeek() {
    try {
        let currentPlanRecipes;
        planRecipes.subscribe(value => {
            currentPlanRecipes = value;
        })
        for (const planRecipe of currentPlanRecipes) {
            await supabase.from('plan_recipe').delete().eq("id", planRecipe.id);
        }

        planRecipes.set([])
        return true;
    } catch(e) {
        return false;
    }
}

export async function duplicateRecipesForWeek(copyToWeek) {
    try {
        let currentPlanRecipes;
        planRecipes.subscribe(value => {
            currentPlanRecipes = value;
        })

        let currentPlanData;
        plan.subscribe(value => {
            currentPlanData = value;
        })

        for (const planRecipe of currentPlanRecipes) {
            // Parse the date from the planRecipe
            const originalDate = new Date(planRecipe.date);
            
            // Get the difference in days between the original date and the start of that week (assuming the week starts on Monday)
            const dayDifference = (originalDate.getUTCDay() + 6) % 7;  // 0 is Monday, 6 is Sunday
            
            // Calculate the new date by adding the dayDifference to the copyToWeek
            const dateToInsert = new Date(copyToWeek);
            dateToInsert.setDate(copyToWeek.getDate() + dayDifference);
            
            await supabase.from('plan_recipe').insert({
                plan_id: planRecipe.plan_id,
                date: dateToInsert.toISOString().split('T')[0], // Format back to 'YYYY-MM-DD'
                recipe_id: planRecipe.recipe_id,
                recipe_type: planRecipe.recipe_type,
                recipe_uri: planRecipe.recipe_uri,
                recipe_name: planRecipe.recipe_name,
                recipe_image: planRecipe.recipe_image,
                recipe_order: planRecipe.recipe_order
            })
            .eq('plan_id', currentPlanData.id);
        }
        return true;
    } catch {
        return false;
    }
}

export async function inviteUserToPlan(email){
    const userData = await getUserData();
    const planResponse = await supabase.from('plan').select('id');
    const planId = planResponse.data[0].id;
    const response = await supabase.from('plan_invitation').insert({
        plan_id: planId,
        invited_by: userData.id,
        status: 'PENDING',
        email: email
    });
}

export async function getUserInvitations(){
    // TODO: Make realtime
    const usersData = await getUserData();
    const response = await supabase.from('plan_invitation')
    .select(`
        *, 
        plan(*), 
        sender:profiles!plan_invitation_invited_by_fkey(*)`)
    .eq('status', 'PENDING')
    .eq('email', usersData.email);

    if(response.data.length > 0){
        // There are invitations to process
        // TODO: Update functionality to handle multiple invitations at once using "pendingInvitations" writable above
        newInvitation.set(response.data[0])
    }
}

export async function getInvitationsForPlan(planId){
    const responseInvitations = await supabase.from('plan_invitation')
    .select(`
        *,
        sender:profiles!plan_invitation_invited_by_fkey(*)
    `)
    .neq('status', 'ACCEPTED')
    .eq('plan_id', planId)
    return responseInvitations.data;
}

export async function getMembersOfPlan(planId){
    const responseMembers = await supabase.from('plan_member').select('*, profiles(*)').eq('plan_id', planId);
    return responseMembers.data;
}

export async function addMemberToPlan(userId, planId, role) {
    const response = await supabase.from('plan_member').insert({
        profile_id: userId,
        plan_id: planId,
        role,
        status: 'ACTIVE',
    });
}

export async function removeMemberFromPlan(planMemberId) {
    const response = await supabase.from('plan_member').delete().eq('id', planMemberId);
}

export async function removeInvitation(invitationId) {
    const response = await supabase.from('plan_invitation').delete().eq('id', invitationId);
}

export async function updateInvitation(invitationId, status){
    const response = await supabase.from('plan_invitation').update({
        status
    }).eq('id', invitationId);
}

export async function isUserAdminOfPlan(planId, profileId) {
    const response = await supabase.from('plan_member').select('*').eq('plan_id', planId).eq('profile_id', profileId);
    if (response.error) {
        throw new Error();
    }

    return response.data.length > 0 && response.data[0].role === 'ADMIN';
}

export async function inviteToPlan(toEmail, planId) {
    const userData = await getUserData();
    const localStorageItemId = supabase.storageKey
            
    const accessToken = JSON.parse(localStorage.getItem(localStorageItemId))['access_token'];

    const inviteeName = `${userData['first_name']} ${userData['last_name']}`;

    const response = await supabase.functions.invoke(`inviteToPlan`, {
        body: {
            inviteeName,
            toEmail : toEmail.toLowerCase(),
            planId
        },
        headers: {
            'Authorization': `Bearer ${accessToken}`
        },
        method: "POST"
    });

    return response;
}

export async function changePlan({planId} = {}){
    if(!planId){
        throw new Error('planId required for changePlan')
    }

    // Get the plan associated with the passed planId
    
    let currentPlans;
    plans.subscribe(value=>{
        currentPlans = value;
    })();
    const updatedPlan = currentPlans.find(userPlan => userPlan.id === planId)

    // Update the relevant plan stores and local storage
    let currentStartDate;
    currentMealPlanStartDate.subscribe(value=>{
        currentStartDate = value;
    })();

    plan.set(updatedPlan);
    localStorage.setItem('selected_plan_id', updatedPlan.id);
    await fetchUserPlan(currentStartDate);
}